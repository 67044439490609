.column-config-button {
  display: flex;
  height: 22px;
  align-items: center;
  .bar {
    background: $darkestGrey;
    width: 5px;
    height: 15px;
    margin-right: 2px;
  }
  .text {
    margin-left: 5px;
  }
  .btn-text {
    display: flex;
    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;

    .col-text {
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.column-config-option-text {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.column-config-modal {
  .modal {
    // height: 80vh;
    .modal-content {
      height: calc(80vh - 56px);
      .muiBox-root-media {
        height: calc(80vh - 72px - 56px);
        .scrollable-media {
          height: calc(80vh - 72px - 56px - 58px) !important;
          min-height: unset !important;
          max-height: unset !important;
        }
        .sortable-container {
          height: 100%;
          .scrollable {
            height: 100%;
            min-height: unset !important;
            max-height: unset !important;
          }
        }
      }
    }
  }
  .add-custom-metric {
    width: 300px;
    font-size: 14px;
  }
}

.column {
  position: relative;
  .fixed {
    position: fixed;
    &.bottom {
      bottom: 60px;
    }
  }
  .scrollable.flex-start {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    height: 50vh;
  }
}

.campaigns {
  .box {
    margin: 0;
    border: 0;
    padding-bottom: 0;
    .conditional-full-width {
      width: 100% !important;
    }
  }

  .dropdown {
    .dropdown-content {
      overflow: hidden;
      z-index: 10;
    }
  }

  .custom-form-control {
    width: unset;
    min-width: unset;
  }

  .select-account {
    .dropdown-button {
      overflow-wrap: break-word;
      word-break: break-word;
    }
    .dropdown-content {
      max-height: 250px;
      overflow-y: auto;
    }
  }

  .sub-change-msg {
    text-align: center;
    padding: 10px 10%;
  }

  .muted-text {
    color: #7F7F7F;
    font-size: .8em;
  }

  .limit-msg {
    display: flex;
    gap: 20px;
    flex-direction: column;
    text-align: center;
    max-width: 525px;
    margin-top: 40px;
    font-size: 14px;
  }
}

.campaigns-date-range {
  height: 40px;
  min-width: 260px;
  width: fit-content;
  line-height: 40px;
  cursor: pointer;
  font-weight: bold;
}
.column-views {
  label {
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
    input[type="radio"] {
      margin-right: 10px;
    }
  }
  button.remove {
    padding: 0 10px !important;
  }
}
.campaign-toolbar {
  margin: 10px 20px;
  display: flex;
  a, button {
    height: 36px;
    line-height: 36px;
    // padding: 0 28px 0 15px !important;
    font-size: 14px !important;
  }

  .left {
    order: 1;
    a, button {
      margin-right: 7px;
    }
  }
  .right {
    margin-left: auto;
    order: 2;
    a, button {
      margin-left: 7px;
    }
    .dropdown {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .dropdown {
    button, a {
      margin-left: 0;
      justify-content: left;
      .MuiButton-label {
        font-size: 14px;
        white-space: nowrap;
        text-align: left;
      }
    }
    .column-views {
      max-height: 300px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      // justify-content: center;
    }
    .dropdown-button::after {
      top: 16px;
    }
  }

  .create-button {
    padding: 0 40px;
    width: 160px;
  }

  .refresh-button {
    background: url($assetUrl + 'refresh-button.svg') center no-repeat;
    width: 36px;
    height: 36px;
    border: 0;
    background-size: contain;
    cursor: pointer;
    vertical-align: middle;
    &:hover {
      filter: brightness(0.7);
    }
    &:active {
      filter: brightness(0.5);
    }
  }

  .duplicate-icon {
    background-image: url($assetUrl + 'duplicate-black.svg') !important;
    background-repeat: no-repeat !important;
    padding: 0 20px 0 44px;
    background-position: 15px center !important;
    background-size: 20px auto !important;
    &:disabled {
      background-image: url($assetUrl + 'duplicate-grey.svg') !important;
    }
  }

  .duplicate {
    img {
      height: 15px;
      margin-right: 10px;
    }

    img.disabled {
      opacity: 0.3;
    }
  }

  .delete-btn {
    width: 95px;
    padding: 0 20px 0 15px;
    img {
      opacity: 1;
      height: 30px;
      transition: all 0.5s;
    }
    img.disabled {
      opacity: 0.3;
    }
  }

  .performance-icon {
    padding: 0 25px 0 50px;
    background-repeat: no-repeat !important;
    background-position: 15px center !important;
    background-size: 25px auto !important;
    background-image: url($assetUrl + 'performance-icon-black.png') !important;
    &:disabled {
      background-image: url($assetUrl + 'performance-icon.png') !important;
    }
  }

  .performance {
    img {
      height: 15px;
      margin-right: 10px;
    }
    img.disabled {
      opacity: 0.3;
    }
  }
}

.delete-campaign-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }
}

.title-banner {
  position: relative;
  &.campaigns {
    min-height: 150px;
  }
  h1.campaigns-header {
    // position: absolute;
    // left: 50%;
    // margin-left: -140px;
  }
  .dropdown {
    .dropdown-content {
      z-index: +100;
    }
  }
}

.ad-account-select {
  width: fit-content;
  white-space: nowrap;
  .dropdown-button, .dropdown-button.active {
    background: #fff;
    &:hover {
      background: #fff;
    }
  }
  ul.select-list {
    overflow: auto;
    max-height: 380px;
  }
}

.ad-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    border-radius: 3px;
    width: 39px;
    height: 39px;
    object-fit: cover;
  }
  video {
    width: 39px;
    height: 39px;
    object-fit: cover;
    border-radius: 3px;
  }
}
#creatives-data-container_grid {
  .ad-preview {
    video {
      width: 39px;
      height: 39px;
    }
  }
}

.campaigns-loader {
  height: calc(100vh - 205px);
  background: rgba(0, 0, 0, 0.5);
}


#campaigns-container, #adsets-container, #ads-data-container, #creatives-data-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  .data-grid-container {
    // flex: 1;
    // padding-bottom: 72px;
    // height: unset !important;
    // TODO: BLIT-2379 fix the height when there is no data message
    // span, .data-grid-cell {
    //   font-size: 16px !important;
    //   &.tooltip-title {
    //     white-space: normal;
    //     max-width: 400px;
    //     height: auto;
    //     line-height: 1.35;
    //     padding: 5px 7px;
    //     font-size: 12px !important;
    //   }
    // }
    .data-grid-cell {
      height: 46px;
    }
  }
  /*
  .data-grid-head-container {
    span, .data-grid-cell {
      font-size: 16px !important;
      color: #333;
      &.tooltip-title {
        font-size: 12px !important;
      }
    }
  }
  */
}

.creative-selection-custom-select {
  .dropdown-content{
    ul.select-list li span {
      word-break: normal;
    }
  }
}  

ul.audience-menu {
  li.MuiListItem-button {
    font-size: 14px !important;
    span {
      padding-left: 5px;
    }
  }
}

.select-campaign.modal {
  height: 80vh;
  padding: 46px 0px;
  max-width: 700px;
  .modal-container {
    // padding: 0 46px;
    height: 100%;
    overflow: auto;
  }
}

.ad-type-radio {
  label {
    display: flex;
    align-items: flex-start;
  }
}

// steps
.fixed-stepper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(5px);
  z-index: 9;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .stepper {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    .step {
      flex-grow: 1;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      background: $iceBlue;
      height: 54px;
      width: 25%;
      .step-number {
        background: $iceBlueText;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-size: 12px;
        color: $white;
      }
      .step-label {
        color: $iceBlueText;
        font-size: 1rem;
        font-weight: bold;
      }
      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
      &.active {
        background: $iceBlueActive;
        .step-label {
          color: $iceBlueActiveText;
        }
        .step-number {
          background: $iceBlueActiveText;
        }
      }
      &.completed {
        background: $iceBlueText;
        .step-label {
          color: $white;
        }
        .step-number {
          color: transparent;
          background: url($assetUrl + 'icons/round-white-check.svg') no-repeat;
        }
      }
    }
  }
}


//overrides
.campaign-creation-container {
  width: 912px;
  margin: auto;
  .save-campaign-btn {
    margin-top: 10px;
  }
}

.root-class {
  .form-root {
    background-color: white;
    padding: 3.667rem 5.667rem;

    .choose-platform-label {
      margin-bottom: 1.875rem;
    }

    .form-control {
      margin-bottom: 35px !important;
      .normal-text {
        label {
          font-weight: normal;
          margin: 10px 0;

          input[type="radio"] {
            accent-color: $black;
            padding: 9px;
            border: 2px solid transparent;
            margin: 0 10px;
            height: 15px;
            width: 15px;
          }
        }
      }

      .form-platforms {
        display: flex;

        label {
          font-weight: 400;
          margin-bottom: 1.1875rem;

          input[type="radio"] {
            accent-color: $black;
            padding: 9px;
            border: 2px solid transparent;
            margin: 0 10px;
            height: 15px;
            width: 15px;
          }
        }
        &.setup-ad-type {
          display: block;
          p {
            margin-top: 5px;
          }
        }
      }

      .dollar {
        color: $primaryColor;
      }

      .custom-select .dropdown-button {
        border: 2px solid transparent;
        padding: 16px;
        font-weight: 400;
      }

      .custom-select .dropdown-button:hover {
        background-color: rgba(181, 203, 215, 0.3);
      }

      .custom-select .dropdown-button:focus {
        border: 2px solid $primaryColor;
      }
      .campaign-select-error {
        background-color: rgba(181, 203, 215, 0.3);

        .dropdown-button {
          background-color: rgba(255, 0, 0, .15);
          border: 2px solid transparent;
          border-bottom: 2px solid $primaryColor;

          &:hover {
            background-color: rgba(255, 0, 0, .15);
          }
          
          &:focus {
            outline: none;
            border: 2px solid $primaryColor;
            background-color: $white;
          }
        }
      }
      .error {
        background-color: rgba(255, 0, 0, .15) !important;
      }
      .campaign-select-value {
        .dropdown-button {
          background-color: rgba(181, 203, 215, 0.3);
          border: 2px solid transparent;

          &:hover {
            border-bottom: 2px solid $black;
          }
          
          &:focus {
            outline: none;
            border: 2px solid $primaryColor;
            background-color: $white;
          }
        }
      }
      .select-wrap-content {
        .dropdown-button {
          display: flex;
          flex-wrap: wrap;
        }
      }
       
      
      .adsets-input {
        padding: 16px;
        transition: border 0.2s;
        border-radius: 2px;
        background-color: rgba(181, 203, 215, 0.3);
        border: 2px solid transparent;

        &:hover {
          border-bottom: 2px solid $black;
        }

        &:focus {
          outline: none;
          border: 2px solid $primaryColor;
          background-color: $white;
        }
      }
    }
    .ios-app-id-cont, .ad-creation-status {
        input[type="checkbox"] {
          accent-color: $primaryColor;
          margin-left: 10px;
          height: 16px;
          width: 16px;
        }
    }
    .setup-ads-btn {
      margin-bottom: 20px;
    }
    .summary {
      display: flex;
      position: sticky;
      margin: 20px 0;
      .typography {
        &.heading {
          flex-basis: 33.33%;
          font-weight: bold;
        }
      }
    }
    .accordion .details .def-list {
      font-size: 17px;
      dd {
        font-weight: bold;
        margin-bottom: 2rem;
      }
      dt {
        margin-bottom: 0.75rem;
      }
    }
  }
}


.creation-form {
  width: 100%;
  .chip {
    margin-top: 5px;
    justify-content: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 25px;
  }
}
.queue-list {
  .queue-container {
    position: sticky;
    top: 75px;
    width: 200px;
    background: $white;
    padding: 10px;
    h3:first-child {
      margin-top: 0;
    }
    .queue-element {
      padding: 20px 0;
      border-bottom: 1px solid $iceBlue;
      &:last-child {
        border-bottom: 0;
      }
      p.description {
        margin: 10px 0;
        font-size: 12px;
        color: $bluishGrey;
        line-height: 1.4;
      }
    }
    .ad-queue-element {
      border-bottom: 1px solid $iceBlue;
      padding: 10px 0;
      div {
        word-break: break-all;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
}

.campaign-input {
  padding: 16px;
  transition: border 0.2s;
  border-radius: 2px;
  background-color: rgba(181, 203, 215, 0.3);
  border: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid $black;
  }
  &.dynamic-text {
    &:hover {
    border-bottom: 2px solid $primaryColor;
    }
  }

  &:focus {
    background-color: $white;
    outline: none;
    border: 2px solid $primaryColor;
  }
}

.campaign-input-error {
  padding: 16px;
  transition: border 0.2s;
  border-radius: 2px;
  background-color: rgba(255, 0, 0, .15);
  border: 2px solid transparent;
  border-bottom: 2px solid $primaryColor;

  &:focus {
    outline: none;
    border: 2px solid $primaryColor;
    background-color: $white;
  }
}

.campaign-input-value {
  padding: 16px;
  transition: border 0.2s;
  border-radius: 2px;
  background-color: rgba(181, 203, 215, 0.3);
  border: 2px solid transparent;
  
  &:hover {
    border-bottom: 2px solid $black;
  }
  &:focus {
    outline: none;
    border: 2px solid $primaryColor;
    background-color: $white;
  }
}

.box {
  .secret-key {
    display: flex;
    button {
      margin-left: 16px;
      margin-top: 2px;
      height: 31px;
    }
  }
}

.campaigns-setup-ads .small {
  margin-bottom: 10px;
}

.select-campaign-modal, .select-adset-modal {
  .dropdown-button {
    word-break: break-all;
  }
  .dropdown-content {
    max-height: 250px;
    overflow: auto !important;
  }
}

// fixes creative grid cell height to 53
#creatives-data-container_grid {
  .data-grid-cell {
    height: 53px;
  }
}

.ad-title {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.campaign-creation .story-form {
  padding: 0;
  .story-errors p {
    line-height: 3px !important;
    font-size: 15px;
  }
}

.story-discover-tile,
.story-snaps {
  .tooltip {
    .tooltip-title {
      height: 50px;
      white-space: initial;
      width: 400px;
      top: 35px;
      line-height: 20px;
    }

    .info-icon {
      color: $white  !important;
      background-color: #bcb6b6 !important;
      font-weight: bold;
      border-radius: 50% !important;
      cursor: pointer !important;
      padding: 1px 7px !important;
      font-size: 12px !important;
    }
  }

  .app-tooltip {
    .tooltip .tooltip-title {
      height: 65px;
    }

    .info-icon {
      padding: 8px !important;
    }
  }
}


.dropdown {
  .dropdown-content {
    label .abs-link {
      top: 8px;
    }
    .filter-list {
    .abs-layer {
      .sub-options {
        // margin-right: 10px;
        box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
      }
    }
  }
  .adset-filter .opt-class .dropdown-content{
   left: 407px !important;
  }
  }

  &.campaign-creation-select,
  &.campaign-select-error,
  &.campaign-select-value {
    .dropdown-button::after {
      top: 23px;
    }
  }
}

//css for campaigns -> performance optimization -> preview 
.tree-view {
  padding: 10px;
  ul,li {
    list-style: none;
  }

  .drop-up, .drop-down span{
    cursor: pointer;
    color: $darkGrey;
    margin-right: 10px;
  }

  .filtered-ads {
    display: flex;
    .custom-checkbox-container {
      margin-right: 10px;
    }
  }
  .budget-details {
    font-weight: bold;
  }
}

.no-adset-msg {
  font-weight: bold;
  font-size: 18px;
  color: darkgray;
  margin: 20px;
  text-align: center;
}

.ai-result-row {
  padding: 7px 0 7px 10px;

  &:hover {
    background: #E9F0F3;
  }

  .custom-checkbox-container {
    .label {
      word-break: break-word;
    }
  }
}

.disabled-overlay-checkbox {
  pointer-events: none;
  opacity: 0.5;
}

.ai-generate-btn {
  background: none;
  background-color: transparent !important;
  border: none;
  cursor: pointer;
}

.ai-generate-icon {
  transition: color 0.5s ease;
}

.character-counter {
  display: flex;
  margin-top: -20px;
  // padding-top: 10px;
  padding-right: 60px;
  justify-content: flex-end;
  font-size: 0.7em;
}

.ai-recommendations-container {
  position: absolute; 
  padding-bottom: 10px;
  width: 100%;
  left: 0;
  justify-content: center;
  z-index: 1;
  
  .ai-recommendations-window {
    height: 100%;
    width: 95%;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.23);
    border-radius: 2px;
    padding: 5px;
    max-height: 20vh;
    overflow-y: auto;

    .warning-text {
      color: #7F7F7F;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .ai-recommendations-window-collapse {
    height: 100%;
    width: 89%;
    margin-left: 5.5%;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.23);
    border-radius: 2px;
    padding: 5px;
    max-height: 20vh;
    overflow-y: auto;

    .warning-text {
      color: #7F7F7F;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.rpos {
  .ai-recommendations-container {
    left: -55px;
  }
}

.loader-in-collapsible {
  width: 30px;
  height: 35px;
}

.column-edit-btn {
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-repeat: no-repeat;
  background-image: url("https://media.useblitz.com/assets/images/icons/edit-icon-new.svg");
}

.column-remove-btn {
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-repeat: no-repeat;
  background-image: url("https://media.useblitz.com/assets/images/icons/delete-icon-new.svg");
}

.connect-campaign-modal {
  max-width: 600px !important;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal-content {
    overflow: unset;
  }

  .dropdown-content {
    max-height: 115px !important;
  }
}

.custom-tooltip {
  .tooltip-title {
    font-size: 14px;
    left: 50px !important;
    color: black;
    background-color: rgb(255, 255, 255);

    &::after {
      border-bottom-color: rgb(255, 255, 255) !important;
    }
  }
}

.campaign-btn-tooltip {
  .tooltip-title {
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.7));
    font-size: 14px;
    top: 40px !important;
    // left: 50px !important;
    color: black;
    background-color: rgb(255, 255, 255);

    &::after {
      border-bottom-color: rgb(255, 255, 255) !important;
    }
  }
}

.need-help-tooltip {
  position: fixed;
  z-index: 10001;
  right: 335px;
  bottom: 75px;
  .tooltip-title {
    line-height: 27px;
    white-space: normal;
    width: 320px;
    height: unset;
    text-align: left;
    font-size: 14px;
    // left: 50px !important;
    color: black;
    background-color: rgb(255, 255, 255);

    &::after {
      // border-top-color: rgba(0, 0, 0, 0) !important;
      content: "";
      left: unset !important;
      position: absolute;
      bottom: 0;
      right: 10px;
      border-width: 5px;
      width: 1px;
      border-top-color: white !important;
      // background-color: red;
    }
  }
}

.analytic-btn-tooltip {
  .tooltip-title {
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.7));
    line-height: 25px;
    white-space: normal;
    width: 220px;
    bottom: -60px !important;
    height: unset;
    text-align: left;
    font-size: 14px;
    // left: 50px !important;
    color: black;
    background-color: rgb(255, 255, 255);

    &::after {
      border-bottom-color: rgb(255, 255, 255) !important;
    }
  }
}

.tutorial-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #00000088;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: flex-end;

  .bottom-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    position: fixed;
    bottom: 10;
    width: 100%;

    div {
      display: flex;
      justify-content: center;
    }

    .end-container {
      button {
        border: none;
        padding: 10px;
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
      }
    }
  }
}

.tutorial-table {
  z-index: 10001;
  pointer-events: none;
}

.tutorial-btn {
  z-index: 10001;
  position: inherit;
}

.pe-none {
  pointer-events: none;
}

.welcome-back-modal {
  .modal-head {
    padding: 10px !important;
  }

  .custom-footer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
  }
}

.event-select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding: 0 20px;

  .refresh-btn {
    background: url($assetUrl + 'refresh-button.svg') center no-repeat;
    width: 36px;
    height: 36px;
    border: 0;
    background-size: contain;
    cursor: pointer;
    vertical-align: middle;
    &:hover {
      filter: brightness(0.7);
    }
    &:active {
      filter: brightness(0.5);
    }
  }
}

.exhausted-credit-modal {
  display: flex;
  flex-direction: column;

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80px;
    }
  }

  .body-text-container {
    margin-top: 20px;
    font-size: 16px;

    .price-text {
      color: #EF4834;
    }
  }

  .body-plan-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px 0;

    .plan-card {
      cursor: pointer;
      width: 100px;
      border-radius: 15px;
      padding: 15px 7px;
      background-color: #E9F0F3;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .price {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
      }

      .credits {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 16px;

        span {
          display: flex;
          justify-content: center;
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 12px;
        }

        .muted-text {
          font-size: 12px;
          color: #7F7F7F;
        }
      }
    }

    .active-plan-card {
      background-color: #EF4834 !important;
      color: white !important;

      .muted-text {
        color: white !important;
      }
    }
  }

  .validation-msg {
    font-size: 14px;
    color: #7F7F7F;
  }

  .footer-container {
    margin-top: 20px;

    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.ad-page-campaign-container {
  .input-field-group {
    gap: 0px !important;
  }

  .add-btn {
    margin-top: -55px;
  }
}

.sub-upgrade-congrats-modal {
  max-width: 625px !important;
}