.support-form-container {
  position: fixed;
  width: 300px;
  height: 150px;
  transition: all 0.5s ease 0s;
  background-color: white;
  box-shadow: 0.1px 0.1px 0.7px rgb(0 0 0 / 3%), 0.3px 0.3px 1.6px rgb(0 0 0 / 5%), 0.5px 0.5px 2.8px rgb(0 0 0 / 6%), 0.8px 0.8px 4.7px rgb(0 0 0 / 7%), 1.3px 1.3px 7.7px rgb(0 0 0 / 8%), 2.3px 2.3px 13.4px rgb(0 0 0 / 9%), 5px 5px 29px rgb(0 0 0 / 12%);
  border-radius: 12px;
  transform-origin: right bottom;
  font-size: 14px;
  z-index: 99999999;

  .form-title {
    display: flex;
    justify-content: space-between;
    // background-color: #EF4834;
    background: #EF4834;
    padding: 12px;
    color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    font-weight: 400;
    font-size: 13px;

    .close-button {
      font-size: 15px;
      cursor: pointer;
    }
  }

  textarea {
    height: 55%;
    width: 100%;
    padding: 15px 10px;
    font-size: 12px;

    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
  }

  button {
    background-color: white;
    color: #EF4834;
    font-weight: 600;
    font-size: 12px;
    border-radius: 12px;
    border: 1px solid #EF4834;
    cursor: pointer;
    margin-right: 10px;
  }

  .success-msg {
    font-size: 14px;
    padding: 20px;
    text-align: center;

    .back-btn {
      margin-top: 15px;
      font-size: 12px;
      color: #797979;
      cursor: pointer;
    }
  }
}

.support-btn {
  position: fixed;
  z-index: 99999999;
  background-color: #EF4834;
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #bf3324;
  }
}

.vertical {
  .support-btn {
    writing-mode: vertical-rl;
    right: 0px !important;
    border-radius: 8px 0 0 8px;
    padding: 15px 10px;
    bottom: 150px !important;
  }
}