@mixin closeChip {
  background: transparent;
  border-radius: 20px;
  border: 0;
  margin-left: 5px;
  margin-right: -5px;
  padding: 1px 5px;
  color: $white;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  &:focus {
    outline-style: none;
  }
}

$tabHeight: 39px;
.title-banner-tabs {
  // margin-top: -$tabHeight;
  // border-bottom: 2px solid $iceBlue;
  margin-left: 45px;
  margin-right: 45px;
  .tabs {
    display: flex;
    justify-content: flex-start;
  }

  .MuiTabs-root {
    min-height: $tabHeight;
  }
  .tab {
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 12px 12px 0 0;
    background: #b5cbd7;
    // background: $darkBlue;
    opacity: 1;
    margin-left: 4px;
    color: #fff;
    min-height: $tabHeight;
    max-width: unset;
    width: 215px;
    border: 0;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    // &:first-child {
    //   margin-left: 0;
    //   width: 25%;
    // }

    &.right {
      order: 2;
      margin-left: auto;
      margin-right: 3rem;
    }
  }
  .tab[aria-selected="true"], .tab.active {
    background: $white;
    color: #ef4834;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .flex-chips {
    display: flex;
    width: 100%;
    span {
      order: 1;
    }
    span.MuiChip-label {    
        font-size: 10px;
        padding: 0 8px;
    }
    .MuiChip-root {
      order: 2;
      height: 20px;
      margin: auto -10px auto auto;
    }
  }
  .close-chip {
    @include closeChip;
    margin-left: 0;
  }
}

.white-tabs {
  margin-bottom: -2px;
  .tab {
    margin-left: 1px;
    border: 2px solid $iceBlue;
    border-bottom: 0;
    border-radius: 19px 19px 0 0;
    background: $iceBlue;
    font-size: 16px;
    opacity: 1;
    &.Mui-selected {
      background: $white;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
}

.title-banner-tabs {
  // &.analytics-banner-tabs {
  //   .MuiTabs-root {
  //     padding: 0 3rem;
  //   }
  //   .tab {
  //     width: 50%;
  //     &:first-child {
  //       margin: 0;
  //     }
  //     &:last-child {
  //       width: calc(50% - 4px);
  //     }
  //   }
  // }
}

.tabs {
  @include flex-aic-jcc;
  .tab {
    &.outlined {
      margin-left: -1px;
      &:first-child {
        border-radius: 4px 0px 0px 4px;
        margin-left: 0;
      }
      &:last-child {
        border-radius: 0px 4px 4px 0px;
      }
      &.active {
        background: $primaryColor !important;
        color: $white;
      }
      &:hover.active {
        color: $white;
      }
    }
  }
}
// used inm AI Ads tabs
.grey-underlined-tabs {
  display: flex;
  padding: 0;
  list-style: none;
  .tab {
    cursor: pointer;
    border-bottom: 2px solid $aiAdColumnBorder;
    color: $black;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    height: 46px;
    line-height: 46px;
    width: 100%;
    text-align: center;
    &.active {
      border-bottom: 2px solid $uploadTextColor;
    }
  }
}
