ul.stacked-nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    a, button {
      color: $black;
      text-decoration: none;
      cursor: pointer;
      display: block;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 15px;
      text-align: left;
      line-height: 26px;
      background: $white;
      border: 0;
      border-bottom: 2px solid $listItemBorder;
      &.active {
        border: 0;
        color: $white;
        background: $listItemActive;
      }
      &:focus {
        outline: none;
      }
    }
    &:last-child {
      a, button {
        border-bottom: 0;
      }
    }
  }
}
ul.strong-list {
  list-style: none;
  padding: 0;
  li {
    color: $graphite;
    font-size: 16px;
    font-weight: bold;
  }
}

@mixin selectList {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 5px 0;
  li {
    color: $black;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    padding: 5px 15px;
    align-items: center;
    &:hover {
      background: $lightWhite;
    }
    span {
      word-break: break-word;
    }
    .custom-checkbox-container {
      & + span {
        margin-left: 10px;
      }
    }
  }
}

.item-list {
  position: relative;
  width: 144px;
  margin: 1rem;
  padding-bottom: 2rem;
  .delete-item {
    position: absolute;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    z-index: +10;
    top: -9px;
    right: -9px;
    width: 20px;
    height: 20px;
    background: $lightBlue;
    color: $white;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    &:hover {
      background: $lightBlueHover;
    }
    &:active {
      background: $lightBlue;
    }
  }
  .select-item {
    position: absolute;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    z-index: +10;
    background-color: white;
    border-radius: 50%;
    top: -12px;
    left: -12px;
    width: 30px;
    height: 30px;
    font-weight: bold;
  }
  .inline-edit {
    width: 100%;
    margin-top: 15px;
    border: 2px solid $lightBlue;
    padding: 5px;
    border-radius: 7px;
    resize: none;
    height: 120px;
    font-size: 14px;
    &:focus {
      outline-style: none;
    }
  }
}

@mixin featuresList {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 5px 0;
  li {
    border-radius: 2px;
    background: url($assetUrl + 'tick.svg') left;
    background-repeat: no-repeat;
    padding: 5px 0;
    padding-left: 25px;
    font-size: 16px;
    line-height: 26px;
  }
  &.bullet-align-top {
    li {
      background-position: left 10px;
    }
  }
}

@mixin gridItem {
  padding: 3px;
  border-radius: 6px;
  .video-box {
    border-radius: 6px;
    .video-with-bg video {
      filter: none;
    }
  }
  .image {
    position: relative;
    border-radius: 6px;
    width: 216px;
    height: 0;
    padding: 0 0 100% 0;
    max-width: 100%;
    background-size: contain;
    .hover-content {
      display: none;
      position: absolute;
      left: 0px;
      bottom: 0px;
      z-index: +10;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 0 0 6px 6px;
      width: 100%;
      color: $white;
      padding:  10px;
      p {
        margin: auto;
        &.title {
          font-size: 18px;
          font-weight: 700;
        }
        &.description {
          font-size: 12px;
          font-weight: normal;
        }
      }
      .separator {
        margin: 7px 0;
        border-bottom: 1px solid $white;
      }
    }
  }
  &:hover, &.active {
    cursor: pointer;
    background: $primaryColor;
    .hover-content {
      display: block;
    }
  }
}

.grid-selection-items {
  margin: 10px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  .item {
    margin: 5px;
    @include gridItem;
  }
}

.grid-automation{
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 3px;
  background-color: $lightWhite;
}

.grid-automation-sampling{
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 10px;
  height: 100%;
  background-color: $white;
  .left-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .right-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .sampling-form {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .sampling-form-inputs {
        display: flex;
        flex-direction: column;
        label {
          margin-bottom: 3px;
        }
      }
    }
    .creatives-queue {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 2;
      gap: 10px;
    }
  }

  .MuiPaper-elevation1 {
    height: 100%;
    .makeStyles-root-101 {
      height: 100%;
    }
  }

  .h-125 .select-list {
    height: 125px;
    overflow: auto;
  }
}

.lookalike-audience-modal {
  .modal__field {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    .h-125 .select-list {
      max-height: 125px;
      overflow: auto;
    }
  }
  .modal-foot {
    gap: 0.5rem;
  }
}

.individual-grid-item {
  width: 215px;
  margin: 10px 0;
  display: grid;
  grid-template-columns: auto;
  .item {
    @include gridItem;
  }
}

//overrides
.grid-automation-sampling {
  display: initial;
  .right-container .sampling-form .sampling-form-inputs label {
    margin-top: 12px;
  }
  .sampled-ads {
    .root-list {
      li {
        list-style: none;
        display: flex;

        .custom-checkbox {
          margin-right: 10px;
        }
      }
      &.creative-sampling {
        border: 1px solid $veryLightGrey;
        border-radius: 10px;
        max-height: 200px;
        overflow-y: scroll;
        padding: 10px;
        box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.05);
        border-bottom: none;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgb(183, 180, 180); 
        }
        &::-webkit-scrollbar-track {
          margin: 4px 0;
        }
      }
    }
  }
  .drop-up,
  .drop-down {
    cursor: pointer;
    color: $darkGrey;
    margin-right: 10px;
  }
}

@mixin circledNumber {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: $linkGrey;
  min-width: 60px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 30px;
  background: rgb(183, 192, 204, 0.4);
}

ul.numbered-bullet-points {
  padding-left: 0;
  li {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    .pic {
      @include circledNumber;
    }
    .text {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: $mediumGrey;
    }
  }
}

ul.orange-key-points {
  padding-left: 0;
  display: flex;
  justify-content: center;
  list-style: none;
  // max-width: 1170px;
  margin: auto;
  li {
    max-width: 232px;
    padding: 40px 20px;
    border-right: 1px solid $white;
    &:nth-child(2n - 1) {
      background: $primaryColor;
    }
    &:nth-child(2n) {
      background: $primaryColorWithAlpha;
    }
    &:first-child {
      border-radius: 25px 0px 0px 25px;
    }
    &:last-child {
      border-radius: 0px 25px 25px 0px;
      border-right: 0;
    }
    .number {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 15px;
      color: rgba(255, 255, 255, 0.4);
    }
    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: $white;
    }
  }
}

ul.numbered-grid-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    // max-width: 349px;
    max-width: 33%;
    padding: 40px;
    border-right: 1px solid $greyAlphaBorder;
    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      padding-top: 0;
      border-bottom: 1px solid $greyAlphaBorder;
    }
    &:nth-child(3n + 1) {
      padding-left: 0;
    }
    &:nth-child(3n) {
      padding-right: 0;
      border-right: 0;
    }
    &:nth-child(4), &:nth-child(5), &:nth-child(6) {
      padding-bottom: 0;
    }
    .number {
      @include circledNumber;
    }
    .text {
      margin-top: 20px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: $mediumGrey
    }
  }
  &.five-columns {
    li {
      &:nth-child(5) {
        border-right: 0;
      }
    }
  }
}
.steps-list {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  li {
    line-height: 22px;
  }
}
