.creative-page {
  .section-Hero {
    &:before {
      margin-bottom: 30px;
    }
  }

  .slope-hill-bg {
    margin-top: -50px;
    .slope-hill  {
      .rectangle {
        width: 355px;
      }
    }
  }

  .steps {
    &.two-steps {
      margin: 92px 0 0 200px;
      .step {
        p {
          font-size: 20px;
          max-width: 545px;
          line-height: 30px;
        }
      }
    }
  }

  .h1 {
    height: 50px;
    font-size: 26px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.74px;
  }

  .creative-item {
    position: relative;
    height: 310px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    // padding: 3rem 5rem 3rem 8rem;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 7px 12px;
    span {
      vertical-align: bottom;
    }
    p {
      font-size: 20px;
      line-height: 26px;
    }
    .get-creative-hero {
      position: absolute;
      background: url($assetUrl + 'creative/get-creative-hero@2x.png') no-repeat center;
      background-size: contain;
      width: 726px;
      height: 464px;
      left: -29px;
      top: -76px;
      .video-box {
        width: 215px;
        height: 383px;
        left: 50%;
        margin-left: -54px;
      }
      &.right {
        left: auto;
        right: -20px;
      }
    }
    .get-footage-hero {
      position: absolute;
      background: url($assetUrl + 'creative/creators-all@2x.png') no-repeat center;
      background-size: contain;
      width: 726px;
      height: 464px;
      right: -20px;
      top: -76px;
    }
    .run-ads {
      position: absolute;
      background: url($assetUrl + 'creative/run-ads@2x.png') no-repeat center;
      background-size: contain;
      width: 621px;
      height: 444px;
      left: 25px;
      top: -43px;
    }
  }

  .full-width-white-bar {
    background: $white;
    padding: 50px 0;
    .head {
      font-size: 35px;
      line-height: 52px;
      font-weight: 900;
      letter-spacing: 1px;
      text-align: center;
    }
    .sub-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
    }
  }
}

.pricing-cards {
  margin-top: 40px;
  // display: flex;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  @include width1024 {
    display: grid;
    grid-template-columns: auto;
  }
  .card {
    width: 315px;
    border-radius: 20px;
    border: 2px solid $iceBlue;
    margin-right: 40px;
    position: relative;
    @include width1024 {
      margin: 0 0 20px 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .light-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: $darkGrey;
    }
    .title {
      text-align: center;
      font-size: 20px;
      line-height: 26px;
      padding: 17px;
      font-weight: bold;
      line-height: 1.3rem;
      background: $iceBlue;
      border-radius: 18px 18px 0 0;
    }
    .info {
      text-align: center;
      padding: 30px 0;
      min-height: 114px;
      border-bottom: 2px solid $iceBlue;
      .info-head {
        font-size: 24px;
        font-weight: 900;
      }
      .medium-text {
        font-size: 24px;
        font-weight: bold;
        margin-top: 15px;
      }
    }
    .features {
      margin-bottom: 50px;
      padding: 15px;
      .light-text {
        text-align: center;
      }
      ul.features-list {
        @include featuresList;
      }
    }
    button {
      margin-top: 50px;
    }
    .abs {
      bottom: 0;
      padding: 15px;
      width: 100%;
      position: absolute;
    }
  }

}
.mobile-only {
  display: none;
}

/** mobile view starts here **/
@include width900 {
  .creative-page {
    .section-Hero {
      text-align: left;
      h1 {
        font-size: 32px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0.91px;
      }
      .sub-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.63rem;
      }
      .hero-container {
        order: 1;
      }
      .section-Hero-text {
        order: 2;
      }

      .creative-hero {
        background-size: 100%;
        width: 100%;
        height: 730px;
        @include width800 {
          height: 660px;
        }
        @include width700 {
          height: 570px;
        }
        @include width600 {
          height: 480px;
        }
        @include width500 {
          height: 440px;
        }
        @include width400 {
          height: 320px;
        }
        @include width335 {
          height: 250px;
        }
        .video-box {
          // top: 77%;
          // right: 24%;
          // width: 29%;
          // margin-top: -42.5%;
          // transform: none;
          top: 50%;
          right: 11%;
          width: 45%;
          margin-top: -42.5%;
          transform: none;
          @include width600 {
          }
          .video-with-bg {
            width: 100% !important;
            height: auto;
          }
        }
      }
    }
    .desktop-only {
      display: none;
    }
  }
  .mobile-show {
    display: flex !important;
  }

  .mobile-only {
    display: block !important;
  }
  .slope-hill-bg {
    @include width900 {
      display: none;
    }
  }
  .slope-hill-bg-mobile {
    background: url($assetUrl + 'creative/ice-blue-hill-medium.png') no-repeat left;
    width: 100%;
    background-size: contain;
    height: 300px;
    margin-top: -50px;
    align-items: center;
    h3 {
      width: 70%;
      padding: 20px;
      font-size: 28px;
      line-height: 35px;
      font-weight: 900;
      letter-spacing: 0.8px;
      color: $white;
    }
    @include width900 {
      display: flex;
    }
    @include width600 {
      height: 247px;
    }
    @include width500 {
      height: 205px;
      h3 {
        font-size: 25px;
      }
    }
    @include width400 {
      height: 165px;
    }
    @include width360 {
      h3 {
        width: 75%;
        font-size: 25px;
      }
    }
    @include width335 {
      height: 138px;
      h3 {
        font-size: 22px;
      }
    }

  }

  .mobile-blitz {
    margin-top: 140px;
    .blitz-works {
      flex-direction: row;
      img {
        height: 38px;
      }
      h2 {
        font-size: 28px;
        font-weight: 900;
        letter-spacing: 0.8px;
      }
      @include width400 {
        img {
          height: 28px;
        }
        h2 {
          font-size: 22px;
        }
      }
    }
  }

  .steps.mobile-only {
    margin: auto !important;
    .step {
      p {
        font-size: 16px !important;
        line-height: 26px !important;
      }
      &.one {
        margin-left: 20px;
      }
      &.two {
        margin-left: 120px;
      }
    }
  }

  .creative-page {
    .mobile-flex-column {
      flex-direction: column;
    }
    .mobile-order-1 {
      order: 1;
    }
    .mobile-order-2 {
      order: 2;
    }
    .creative-item-section {
      margin: 20px;
    }
    .creative-item {
      // margin: 20px;
      margin-top: 150px;
      @include width400 {
        margin-top: 50px;
      }
      height: auto;
      padding: 0;
      .get-creative-hero {
        width: 100%;
        left: 0%;
        top: -50%;
        background-size: 75%;
        @include width600 {
          left: 0%;
          top: -55%;
          background-size: 100%;
        }
        @include width400 {
          left: 0%;
          top: -40%;
          background-size: 115%;
        }
        .video-box {
          transform: none;
          top: 11%;
          right: 11%;
          width: 22%;
          margin-left: -11%;
          @include width800 {
            top: 16%;
          }
          @include width700 {
            top: 22%;
          }
          @include width600 {
            top: 17%;
            right: 11%;
            width: 30%;
            margin-left: -15%;
          }
          @include width500 {
            top: 22%;
            right: 11%;
            width: 30%;
            margin-left: -15%;
          }
          @include width400 {
            top: 26%;
            right: 11%;
            width: 33%;
            margin-left: -16%;
          }
          @include width360 {
            top: 28%;
            right: 11%;
            width: 33%;
            margin-left: -16%;
          }
          // margin-top: -42.5%;
          .video-with-bg {
            width: 100% !important;
            height: auto;
          }
        }
      }
      .creative-item-text {
        padding-top: 135px;
        margin: 70px 30px 30px 30px !important;
        @include width400 {
          padding-top: 115px;
          margin: 60px 20px 20px 20px !important;
        }
        h2.h1 {
          font-size: 22px;
          font-weight: 900;
          letter-spacing: 0.63px;
          @include width400 {
            letter-spacing: 0.1px;
            font-size: 20px;
          }
        }
        p {
          font-size: 16px;
          width: auto;
          margin: 0;
        }
      }
    }

    .footage-item-section {
      margin-top: 250px !important;
      @include width400 {
        margin-top: 140px !important;
      }

      .get-footage-hero {
        width: 100%;
        right: 0;
        top: -60%;
        height: 450px;
        @include width400 {
          top: -40%;
          height: 300px;
        }
      }
      .footage-item-text {
        padding-top: 125px;
        @include width400 {
          padding-top: 85px;
        }
      }
    }

    .run-ads-section {
      margin-top: 250px !important;
      @include width400 {
        margin-top: 140px !important;
      }
      .run-ads {
        width: 100%;
        top: -65%;
        left: 0;
        @include width400 {
          top: -50%;
          left: 0;
        }
      }
      .run-ads-text {
        padding-top: 95px;
        @include width400 {
          padding-top: 75px;
        }
      }
    }
  }

  .pricing-cards {
    flex-direction: column;
    .card {
      margin: 30px auto auto;
      &:last-child  {
        margin: 30px auto auto;
      }
      @include width360 {
        width: 100%;
      }
    }
  }

}



//overrides
.creative-page .section-Hero {
  section {
    display: flex;
    width: 100%;
  }

  main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  button {
    width: 200px;
    height: 48px;
    color: $white;
    background-color: $primaryColor;
    font-size: 1.125rem;
    text-decoration: none;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  }

  .section-Hero-text {
    max-width: 100%;
  }
}

.creative-page .creative-item .get-creative-hero .video-box {
  .video-with-bg {
    width: 100%;
    left: -54px;
    video {
      height: 85%;
    }
  }
}

.creative-item-section main {
  margin-left: auto;
  margin-right: auto;

  .creative-item-container {
    display: flex;

    .item-image {
      flex: 7;
      height: 280px;
    }

    .creative-item-text {
      display: flex;
      flex: 5;

      div {
        align-self: center;
      }
    }
  }
}

.run-ads-section main>div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.section-Hero .creative-hero .video-box {
  top: 10px;
  border-radius: 10px;
  right: 80px;
}

.creative-page .how-it-works {
  position: relative;
  box-shadow: inset 0 5px 7px 1px rgb(0 0 0 / 10%);
  padding-bottom: 4rem;
  background-color: #e9f0f3;

  .footage-item-section main>div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
}

.mobile-blitz::before {
  top: 0;
  left: 50%;
  width: 525px;
  height: 100%;
  content: " ";
  display: block;
  position: absolute;
  max-width: 100%;
  transform: translateX(-50%);
  background-size: 218px;
  background-image: url(https://media.useblitz.com/static/blitz/img/bg-dot-tile-home.svg);
}

.creative-hero .video-with-bg {
  width: 100%;
}

@media (min-width: 400px) {
  .creative-page .creative-item .get-creative-hero .video-box {
    .video-with-bg {
      left: 0;
      background-color: #a73224;
    }
  }
}


@media (max-width: 600px) {
  .creative-page .section-Hero .creative-hero .video-box {
    top: 60%;
    left: 15%;
    width: 100%;
  }
}

@media (min-width: 900px) {
  .creative-page .section-Hero main {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 959px) {
  .creative-page .section-Hero section {
    flex-wrap: wrap;
  }

  .creative-page .creative-item {
    .get-creative-hero .video-box {
      position: absolute;

      .video-with-bg {
        left: 0;
      }
    }

    .creative-item-text {
      padding-top: 75px;
      display: block !important;
      flex: auto !important;
      margin: 60px 20px 20px !important;

      p {
        font-size: 16px;
        width: auto;
        margin: 0;
      }

      &.great-creatives-text {
        padding-top: 115px;
      }
    }

    .item-image {
      height: 0 !important;
    }
  }
}

@media (min-width: 1280px) {
  .creative-page .section-Hero main {
    max-width: 1280px;
  }

  .slope-hill-bg main {
    max-width: 1280px;
  }

  .creative-item-section main {
    background-color: #a73224;
    max-width: 1280px;
  }
}

.create-listing-grid .remove {
  cursor: pointer;
}

.grid-selection-items .video-with-bg {
  width: 100%;
  position: absolute !important;
}

.creator-page-cont .modal .modal-content {
  padding: 0;
}

.s3imageupload {
.image-upload-preview-cont {
  .image-upload-preview {
    width: 120px;
    height: 40px;
    background-color: $primaryColor;
    background-repeat: round;
    border-radius: 5px;
    text-align: center;
    color: $white;
    font-weight: bold;
    cursor: pointer;
    padding-top: 12px;
    margin: 5px;
   &::before {
    content: 'Upload File';
   }
  }
}
.img-upload {
  height: 150px;
  width: 150px;
  object-fit: contain;
  margin: 10px;
}
}

.selected-image-info {
  margin-top: 12px;
  margin-left: 5px;
  font-size: 12px;
  color: #9a9a9a;

  .close {
    font-weight: bold;
    color: #5a5a5a;
    cursor: pointer;
  }
}
