.sheet-parent-container {
  .sheet-row {
    .sheet-cell {
      font-family: 'Libre Franklin';
    }
  }
}

// used in analytics v1 reports page
.sheets {

  .data-grid-container, .data-grid-head-container {
    display: grid;
    overflow: hidden auto;
    font-size: 12px;
    // font-family: Roboto, Arial, sans-serif;
    position: relative;
    height: calc(100vh - 355px);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
    
    .data-grid {
      .data-grid-cell {
        font-size: 13px;
        display: table-cell;
        padding: 6px 15px 4px 15px;
        min-height: 50px;
        line-height: normal;
        white-space: normal;
        word-break: break-word;
        vertical-align: middle;
        text-overflow: ellipsis;
        white-space: nowrap !important;
        overflow: hidden;
        @include cellBorder;
        &:last-child {
          @include noBorder;
        }

        &.fixed-column {
          position: sticky;
          left: 0;
        }

        &.checked {
          background: $lightOrange !important;
          a.link {
            font-weight: bold;
          }
        }
        .show-hover-items {
          visibility: hidden;
        }
        &:hover .show-hover-items {
          visibility: visible;
        }
        .shadow-bg {
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==');
          background-repeat: repeat-y;
          width: 5px;
          height: 100%;
          position: absolute;
          right: 5px;
          top: 0;
        }
      }
      
      // data grid head
      .data-grid-head {
        // position: sticky;
        top: 0;
        z-index: 1;
        position: sticky;

        .data-grid-row {
          width: auto !important;
          border-top: 1px solid $iceBlue;
          border-bottom: 2px solid $tableBorderColor;
          .data-grid-cell {
            text-align: center;
            font-size: 14px;
            padding: 10px 15px;
            background: $white;
            font-weight: bold;
            @include cellBorder;
            position: relative;
            &.fixed-column {
              position: sticky;
              z-index: +1;
            }
            &:last-child {
              @include noBorder;
            }
            .resizer {
              display: none;
              position: absolute;
              border-right: 5px #aaaaaa double;
              height: 100vh;
              right: 0px;
              top: 0;
              z-index: +1;
            }
            &:hover {
              .resizer {
                display: block;
                cursor: ew-resize;
              }
            }
          }
        }
      }

      // data grid body
      .data-grid-body {
        // height: 70vh;
        position: relative;
        height: calc(100vh - 356px);
        .data-grid-row {
          width: auto !important;
          background: $iceBlue;
          &:nth-child(2n) {
            .data-grid-cell {
              background: $white;
            }
          }
          &:nth-child(2n + 1) {
            .data-grid-cell {
              background: $white;
            }
          }
          .data-grid-cell {
            // @include noBorder;
            // @include cellBorder;
            font-weight: 500;
            border-bottom: 1px solid $tableBorderColor;
            height: 34px;
            &[data-column="id"] {
              font-weight: bold;
              text-align: center !important;
            }
          }
          .data-grid-cell:last-child {
            // @include noBorder;
            border-right: 1px solid $tableBorderColor;
          }
          .data-grid-row-inner {
            width: 100%;
            position: relative;
          }
          &:first-child {
            background: $iceBlue;
            .data-grid-cell {
              background: $iceBlue;
            }
          }
        }
        .abs-skeleton-loader {
          position: absolute;
          top: 0px;
          &.top {
            top: 0px;
          }
          &.bottom {
            top: unset;
            bottom: 0px;
            // border-top: 1px solid $tableBorderColor;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.1)
          }
        }
      }
    }
    /** anchors inside table grid **/
    a.link {
      color: $black;
      cursor: pointer;
      // font-weight: bold;
    }
  }

  .data-grid-head-container {
    height: auto !important;
    overflow: hidden;
  }

  .data-grid-footer-container {
    .data-grid-row, .data-grid-cell {
      height: 72px;
    }
  }

}


