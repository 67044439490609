@mixin width360 {
  @media only screen and (max-width: 360px) {
    @content;
  }
}
@mixin width335 {
  @media only screen and (max-width: 335px) {
    @content;
  }
}
@mixin width505 {
  @media only screen and (max-width: 505px) {
    @content;
  }
}
@mixin width1100 {
  @media only screen and (max-width: 1100px) {
    @content;
  }
}
@mixin width1160 {
  @media only screen and (max-width: 1160px) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (max-width: 660px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 900px) {
    @content;
  }
}
@mixin width2300 {
  @media only screen and (max-width: 2300px) {
    @content;
  }
}
@mixin width2500 {
  @media only screen and (max-width: 2500px) {
    @content;
  }
}
@mixin width2700 {
  @media only screen and (max-width: 2700px) {
    @content;
  }
}
@mixin width1600 {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

@mixin width1440 {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin width1330 {
  @media only screen and (max-width: 1330px) {
    @content;
  }
}

@mixin width1220 {
  @media only screen and (max-width: 1220px) {
    @content;
  }
}

@mixin width1100 {
  @media only screen and (max-width: 1100px) {
    @content;
  }
}

@mixin width1000 {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}

@mixin width1024 {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin width968 {
  @media only screen and (max-width: 968px) {
    @content;
  }
}

@mixin width900 {
  @media only screen and (max-width: 900px) {
    @content;
  }
}

@mixin width800 {
  @media only screen and (max-width: 800px) {
    @content;
  }
}

@mixin width700 {
  @media only screen and (max-width: 700px) {
    @content;
  }
}

@mixin width600 {
  @media only screen and (max-width: 600px) {
    @content;
  }
}

@mixin width500 {
  @media only screen and (max-width: 500px) {
    @content;
  }
}

@mixin width460 {
  @media only screen and (max-width: 460px) {
    @content;
  }
}

@mixin width400 {
  @media only screen and (max-width: 400px) {
    @content;
  }
}

@mixin w-($width) {
  width: $width;
}

@mixin flex-aic-jcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-aic {
  display: flex;
  align-items: center;
}

@mixin flex-jcc {
  display: flex;
  justify-content: center;
}

