$success-color: #24A346;
$warning-color: #FFB118;
$fail-color: #EF4834;

.queue-container {
  position: fixed;
  top: unset !important;
  bottom: 50px;
  right: 0;
  width: 100vw;
  z-index: +1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  ul.dismissable-queue {
    position: absolute;
    padding: 0;
    margin: 0;
    width: 50vw;
    // top: 255px;
    // right: 20px;
    top: unset !important;
    right: unset !important;
    list-style: none;
    border-radius: 13px;
    box-shadow: unset !important;
    &.duplicate-cont {
      margin-right: 204px;
    }
    li {
      position: relative;
      padding: 5px 10px;
      background: $white;
      font-size: 13px;
      cursor: pointer;
      &:first-child {
        border-radius: 4px 4px 0 0;
      }
      &:last-child {
        border-radius: 0 0 4px 4px;
      }
      &:hover {
        background: #ececec;
      }
      .title {
        font-weight: 18px;
        font-weight: bold;
        padding: 5px;
      }
      .item-title {
       overflow-wrap : break-word
      }
      border-bottom: 1px solid #ddd;
      .close {
        position: absolute;
        top: 3px;
        right: 3px;
        color: $white;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        background: red;
        width: 12px;
        height: 12px;
        line-height: 12px;
        text-align: center;
        border-radius: 6px;
      }
      .status {
        &.waiting {
          color: rgba(204, 131, 20, 0.966);
        }
        &.in_progress {
          color: rgb(10, 82, 150);
        }
        &.failed {
          color: rgb(168, 12, 12);
        }
        &.completed {
          color: rgb(26, 78, 26);
        }
      }
    }
  }

  .list-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50vw;

    .generic-list {
      transition: all 0.3s ease;
      box-shadow: 0px 14px 40px rgba(19, 19, 20, 0.14);
      border-radius: 13px;
      display: flex;
      flex-direction: row;
      padding: 10px 20px;

      .icon-area {
        flex: 1;

        .icon {
          border-radius: 50%;
          height: 36px;
          width: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .content-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .refresh-btn-container {
          // padding-top: 10px;

          button {
            cursor: pointer;
            background-color: #24A346;
            border: none;
            border-radius: 4px;
            color: white;
            font-weight: 600;
            font-size: 12px;
            padding: 5px 8px;
            border-radius: 4px;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
      
      .content-area {
        flex: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
        }

        .content {
          color: #68717A;
          font-size: 14px;
          padding-left: 0px !important;
          width: 100%;

          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          .expand-icon {
            margin-left: 20px;
            cursor: pointer;
            height: 15px;
            width: 15px;
            background: center / contain no-repeat url(https://media.useblitz.com/assets/expand.svg);
          }

          .show-err-msg {
            cursor: pointer;
            margin-left: 20px;
            background-color: $fail-color;
            border: none;
            padding: 5px 8px;
            border-radius: 4px;
            color: white;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }

      .end-area {
        flex: 1;
      }
    }

    .inProgress-list {
      background: #FDF8EB;
      border: 1.5px solid $warning-color;

      .icon {
        background-color: $warning-color;
      }
    }

    .failed-list {
      background: #FBF1F2;
      border: 1.5px solid $fail-color;

      .icon {
        background-color: $fail-color;
      }
    }

    .success-list {
      background: #F5FFFC;
      border: 1.5px solid $success-color;

      .icon {
        background-color: $success-color;
      }
    }

    .right-corner-btns {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 5px;

      div {
        cursor: pointer;
        height: 15px;
        width: 15px;
      }

      .close {
        background: center / contain no-repeat url(https://media.useblitz.com/assets/close.svg);
      }

      .collapse {
        background: center / contain no-repeat url(https://media.useblitz.com/assets/collapse.svg);
      }

      .expand {
        background: center / contain no-repeat url(https://media.useblitz.com/assets/expand.svg);
      }
    }

  }
}
