.daterangepicker {
  margin-top: 1px !important;
  border-radius: 4px !important;
  &::before, &::after {
    border: 0 !important;
    position: absolute !important;
  }
  border: 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  .ranges {
    margin-top: 4px !important;
    float: right !important;
    border: none;
    border-left: 2px solid #eeeeee;
    ul {
      li {
        // height: 30px;
        // line-height: 30px;
        padding: 2px 15px;
        &.active {
          background: $lightOrange;
          font-weight: bold;
          color: $black;
        }
      }
    }
  }

  .drp-calendar {
    max-width: none !important;
    &.left {
      border-left: 0 !important;
    }
    &.right {
      padding: 8px 8px 8px 15px !important;
    }
    thead {
      tr:nth-child(2) {
        th {
          font-size: 10px;
          color: #606770;
          padding: 2px 10px;
          border-bottom: 2px solid $iceBlue;
        }
      }
    }
    tbody {
      tr:nth-child(1) {
        padding-top: 5px;
      }
    }
  }
  
  .calendar-table {
    table {
      border-spacing: 0 5px !important;
      border-collapse: separate !important;
    }
    td {
      font-size: 12px !important;
      line-height: 18px !important;
      padding: 2px 4px;
      &.in-range {
        background: $lightOrange;
      }
      &.active, &.active:hover {
        background: $primaryColor;
      }
      &.today {
        border: 1px solid #cccccc;
      }
      &.off {
        text-decoration: solid;
        line-height: 18px !important;
        visibility: visible;
        height: 0px;
        padding: 0;
        margin: 0;
      }
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      &.start-date {
        border-radius: 4px 0 0 4px;
      }
    }
    .off.ends:not(.disabled) {
      visibility: hidden !important;
    }
    .off.ends.off.disabled {
      visibility: hidden !important;
    }
  }

  .drp-buttons {
    border-top: 2px solid $iceBlue !important;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    .btn {
      padding: 8px 20px !important;
      line-height: 18px;
    }
    .btn-default {
      @include lineBorderButton;
    }
    .btn-primary {
      @include lineBorderButton;
      background: $primaryColor;
      color: $white;
      &:hover {
        color: $white;
      }
      &:active {
        color: $white;
        background: $darkRed;
      }

    }
    .drp-selected {
      font-size: 12px;
    }
  }
}
