.graphs {
  display: grid;
  grid-template-columns: 50% 50%;
  .graph {
    margin: 10px;
    padding: 20px;
    background: $white;
    border-radius: 20px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    h2 {
      font-size: 20px;
      margin: 0;
      text-align: center;
    }
  }
}

.graph-border {
  border: 2px solid #b5cbd7;
  border-radius: 10px;
  overflow: hidden;
}