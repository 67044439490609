.icon {
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  &.trash {
    background-image: url($assetUrl + 'bin.svg');
    width: 100%;
    height: 31px;
    &.white {
      background-image: url($assetUrl + 'bin-white.svg');
    }
  }
  &.download {
    background-image: url($assetUrl + 'download.svg');
    width: 100%;
    height: 31px;
    background-size: contain;
  }
  &.duplicate {
    background-image: url($assetUrl + 'duplicate.svg');
    width: 30px;
    height: 30px;
  }
  &.duplicate.disabled {
    opacity: 0.1;
    cursor: default;
  }
  &.small {
    width: 15px;
    height: 15px;
    display: inline-block;
    padding: 0px;
    background-size: contain;
    &.add {
      background-image: url($assetUrl + 'icons/add.svg');
    }
    &.edit {
      background-image: url($assetUrl + 'icons/edit.svg');
    }
    &.duplicate {
      background-image: url($assetUrl + 'icons/duplicate.svg');
    }
  }
}



.icons-disabled{
  opacity: 0.5;
  pointer-events: none !important;
}
