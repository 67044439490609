.custom-form-control {
  vertical-align: unset !important;
  width: 100%;
  position: relative;
  .custom-form-input {
    background: $white;
    font-size: 14px;
    padding: 10px;
    border-radius: 4px;
    border: 0;
    &:focus {
      outline-style: none;
    }
  }
  .campaigns-date-range.custom-form-input{
    font-size: 16px;
  }
  .arrow {
    position: absolute;
    top: 25px;
    right: 10px;
    &:after {
      content: '';
      border-top: 5px solid $darkestGrey;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0;
    }
    &.up {
      top: 0px;
      &:after {
        border-top: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $darkestGrey;
      }
    }
  }
}

.ice-blue-input {
  background: $iceBlue;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  border: 0;
  &:focus {
    outline-style: none;
  }
  &.full-width {
    width: 100%;
  }
}

.ice-blue-input-with-border {
  background: $iceBlue;
  border-radius: 4px;
  border: 1px solid $iceBlueBorder !important;
  padding: 10px;
  font-size: 14px;
  border: 0;
  &:focus {
    outline-style: none;
  }
  &.full-width {
    width: 100%;
  }
}

// no border class for forms
.no-border {
  border: 0 !important;
  &:before {
    border: 0 !important;
  }
  &:hover {
    border: 0 !important;
    &:before {
      border: 0 !important;
    }
  }
  &.Mui-focused {
    border: 0 !important;
  }
  &.MuiInput-underline:after {
    border: 0 !important;
  }
}

.box {
  margin: 0 38px;
  padding: 25px 0;
  border-bottom: 2px solid $iceBlue;
  &:last-child {
    border-bottom: 0;
  }
  &.no-pad {
    padding: 0px;
  }
  .head {
    font-size: 20px;
    font-weight: bold;
    img {
      vertical-align: middle;
    }
  }
  .cards {
    margin-top: 10px;
  }
  .flex {
    .form-control {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.form-control {
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  label {
    font-weight: normal;
    display: block;
    margin-bottom: 5px;
  }
  .form-input {
    width: 100%;
    background: $iceBlue;
    border: 0;
    font-weight: bold;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 2px;
    &:focus {
      outline: 0;
      opacity: 0.7;
    }
    &.error {
      background: $formErrorColor;
    }
    &.MuiInput-root {
      padding: 0;
    }
    .MuiInput-input {
      border-radius: 2px;
      padding: 10px 15px !important;
      background: transparent !important;
    }
  }
  .read-only {
    font-weight: bold;
    margin: 15px 0;
    color: $graphite;
  }
}


.form-control-horizontal {
  display: flex;
  margin: 7px 25px 7px 0;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
  label {
    margin-right: 10px;
  }
  button {
    height: 40px;
    line-height: 40px;
  }
}

.secret-input{
  outline: none;
  background: $iceBlue;
  border: 0;
  font-weight: bold;
  padding: 10px 15px;
  font-size: 13px;
  border-radius: 2px;
  overflow: visible;
}

.search-bar {
  display: flex;
  background: $white;
  align-items: center;
  border-radius: 5px;
  input {
    font-size: 16px;
    height: 39px;
    width: 335px;
    border: 0;
    text-indent: 10px;
    outline: none;
  }
  button.searchBarIcon {
    border: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
  }
}
.form-control {
  label {
    cursor: pointer;
  }

  &.rpos {
    background-color: rgba(181, 203, 215, 0.3);
    position: relative;

    .dollar {
      position: absolute;
      top: 20px;
      left: 10px;
    }

    input {
      text-indent: 10px;
    }
  }
}


//overrides
.create-listing-page {
  &.main-container {
    .new-listing-form {
      background-color: $white;
      padding: 10px 45px;
      border-radius: 20px;
      margin: 0 auto 30px auto;
      h2 {
        font-size: 20px;
      }
      .new-listing-form-control {
        input {
          margin-top: 10px;
          padding: 9px 16px;
        }
      }
    }
  }
}

.tab-btn {
  display: block;
  color: $primaryColor;
  max-width: 180px;
  padding: 5px 35px;
  background-color: $white;
  font-weight: bold;
  line-height: 1.75;
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  margin-left: 50px;
  font-size: 1.15rem;
  text-align: center;
}

.helper-text {
  color: #f44336;
  padding: 5px;
  font-size: 0.75rem;
}

input[type="radio"] {
  cursor: pointer;
}

.landing-form {
  .landing-form-control {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    input[type="text"], input[type="email"], textarea {
      font-family: Inter;
      font-weight: 500;
      font-size: 14px;
      color: $h1GreyColor;
      border: 1px solid $landingFormBorderColor;
      &:focus {
        outline: 0;
      }
    }
    input[type="text"], input[type="email"] {
      border-radius: 25px;
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      flex: 1;
      width: 50px;
    }
    textarea {
      border-radius: 20px;
      padding: 15px;
      height: 120px;
      line-height: 24px;
      resize: none;
    }
  }
  .btn.submit {
    height: 50px;
    line-height: 50px;
    border: 0;
    width: 100%;
  }
  .note {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $carouselTextGrey;
  }
}
.form-radio-group {
  display: flex;
  gap: 20px;
  &.stacked {
    display: block;
    margin-bottom: 10px;
  }
  .form-radio {
    display: flex;
    align-items: center;
    label {
      font-weight: normal;
      margin-bottom: 0 !important;
    }
    text-indent: 8px;
    margin-bottom: 15px;
  }
}

label.form-radio-control {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}
.input-info-text {
  color: $greyText;
  text-align: right;
  font-size: 13px;
  margin: 5px auto;
  &.error {
    color: $primaryColor;
  }
}