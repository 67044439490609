.choose-creatives-modal {
  min-height: 55vh;
  width: 60vw;
  min-width: 50vw;
  max-width: 70vw !important;
  transition: all 0.3s ease;

  .right-panel {
    .dropdown-button {
      line-height: 1.15 !important;
      height: unset !important;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
  }
  
  .choose-method {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 55vh;
    width: 100%;
    justify-content: center;
    align-items: center;

    .title {
      color: #000000;
      font-weight: 600;
      font-size: 1.3em;
    }

    .subtitle {
      color: #7F7F7F;
      font-weight: 500;
      font-size: 0.8em;
    }

    .methods-container {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .method-object {
        background-color: #E9F0F3;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        min-width: 30vw;
        justify-content: space-between;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 0.9em;
      }

      .disabled {
        opacity: 0.7;
        pointer-events: none;
      }
    }

    .choose-method-footer {
      display: flex;
      justify-content: flex-end;
      min-width: 30vw;
    }

    .loader-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      width: 30vw;
    }
  }

  .upload-from-library {
    display: flex;
    flex-direction: column;
    height: 70vh;

    .title-section {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 3px solid #E9F0F3;
      font-weight: 700;
      font-size: 1.3em;
    }

    .search-section {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search-input-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
        padding: 7px;
        width: 250px;
        background: url("https://media.useblitz.com/assets/images/search.svg") no-repeat center right;
        
        input {
          font-size: 0.9em;
          border: none;

          &:focus {
            outline: none;
          }
        }
      }

      .filter-select {
        .dropdown-button {
          font-size: 0.9em;
          min-width: 225px;
        }

        .label {
          font-size: 0.9em;
        }
      }

    }

    .list-section {
      flex: 10;
      padding: 10px;
      overflow: auto;
      
      .title {
        color: #000000;
        font-weight: 500;
        font-size: 1.1em;

        .back-btn {
          font-size: 0.7em;
          cursor: pointer;
        }
      }

      .disabled {
        pointer-events: none;
        opacity: 0.8;
      }

      .list-table {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 40px;
        height: 90%;

        

        .loader-container {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .inline-loader {
            width: 40%;
          }
        }

        .list-item {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: fit-content;

          img {
            width: 200px;
            height: 200px;
            object-fit: contain;
            background-color: #e9f0f3;
            border-radius: 6px;
          }

          video {
            border-radius: 6px;
            background-color: #e9f0f3;
            width: 200px;
            height: 200px;
          }

          label {
            margin-top: 7px;
            font-weight: 600;
            font-size: 0.9em;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .platform-select {
            .dropdown-content {
              box-shadow: unset !important;
              padding: 0 0 10px 0 !important;
            }

            ul {
              padding: unset !important;
              box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
            }

            .dropdown-button {
              font-size: 0.9em;
              margin-top: 10px;
              width: 100%;
            }

            .label {
              font-size: 0.9em;
            }
          }

          .selected-platforms {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            // flex-wrap: wrap;
            max-width: 180px;
            overflow: auto;
            gap: 5px;
            
            .chip {
              min-width: 40px;
              border: 1px solid #B5CBD7;
              color: #B5CBD7;
              border-radius: 4px;
              padding: 5px;
              font-size: 0.8em;
              background-color: white;

              .delete-btn {
                font-size: 0.6em;
              }
            }

            .no-msg {
              color: #7F7F7F;
              font-size: 0.6em;
            }
          }
        }

        .selected {
          img {
            border: 3px solid #EF4834;
            margin-top: -35px;
          }

          video {
            border: 3px solid #EF4834;
            margin-top: -35px;
          }

          .selected-icon {
            width: 25px;
            height: 25px;
            background-color: white;
            border-radius: 50%;
            border: 2px solid #EF4834;
            margin-left: 165px;
            margin-top: 10px;
            z-index: 2;
            position: relative;
            border-radius: 50%;

            &::before {
              content: "\2713";
              position: absolute;
              top: 53%;
              left: 49%;
              transform: translate(-50%, -50%);
              color: #EF4834;
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }

      .err-msg {
        margin-top: 20px;
        margin-left: 20px;
        color: #7F7F7F;
      }
    }

    .footer-section {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
    }
  }
}

.w-50vh {
  width: 50vh !important;
}