.chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  // font-size: 0.8125rem;
  background: $chipColor;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  &.black {
    background: $black;
    color: $white;
  }
  .close-chip {
    margin-left: 1px !important;
    margin-right: -10px;
  }
}

.title-banner-tabs {
  .flex-chips {
    position: relative;
    justify-content: space-between;
    .chip {
      padding-left: 8px;
      font-size: 10px;
      height: 20px;
      margin: 0;
      position: absolute;
      right: -10px;
    }
  }
}
