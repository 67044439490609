// custom switch CSS
.switch-container {
  width: 48px;
}

//overrides
.content-container {
  .switch-ads, .switch-adset, .switch-campaign {
    .switch-container {
      margin: auto;
    }
  }
}
