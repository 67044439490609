.input-with-tags {
  .container {
    border: 1px solid $veryLightGrey;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    padding: 3px 5px;
    .tag {
      background: #e9f0f3;
      padding: 3px 6px;
      display: flex;
      gap: 6px;
      align-items: center;
      border-radius: 3px;
      .tag__remove {
        cursor: pointer;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    margin-top: 5px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid $veryLightGrey;
    li {
      border-radius: 5px;
      background: #e9f0f3;
    }
  }
  input.input-search {
    border: none !important;
    max-width: 100% !important;
    min-width: 200px;
    padding: 0 !important;
    &:focus {
      outline: none;
    }
  }
}
