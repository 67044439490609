.ads-mockup-container {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;

  .middle-panel {
    width: 500px;
  }
  .right-panel {
    width: 260px;
  }
  .name {
    padding: 0 20px;
    margin-bottom: 20px;
    background: $white;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 700;
    height: 48px;
    line-height: 48px;
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .canvas-container {
    display: flex;
    flex-direction: column;
    position: relative;
    canvas {
      height: 500px;
      border-radius: 5px;
      &.preview-enabled {
        border-radius: 0;
        height: auto;
        &.FB {
          width: 400px;
        }
        &.IG {
          position: absolute;
          width: 286px;
          margin-left: -143px;
          left: 50%;
          top: 110px;
        }
        &.IS {
          position: absolute;
          width: 284px;
          margin-left: -142px;
          left: 50%;
          top: 35px;
          border-radius: 12px;
        }
        &.TT {
          position: absolute;
          width: 283px;
          margin-left: -141px;
          left: 50%;
          top: 1px;
        }
        &.SC {
          position: absolute;
          width: 284px;
          margin-left: -142px;
          left: 50%;
          top: 80px;
          border-radius: 12px;
        }
      }
    }
    canvas.invisible {
      position: absolute;
      left: -50000px;
      visibility: hidden;
    }

    // facebook preview
    .facebook-preview-top {
      width: 400px;
      background: $white;
      border-radius: 7px 7px 0 0;
      padding: 10px;
      font-family: 'Lucida Grande', Verdana, sans-serif;
      font-size: 11px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      .profile-details {
        display: flex;
        gap: 8px;
        // align-items: flex-start;
        .blitz-icon {
          background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/blitzCircleIcon.svg') no-repeat;
          background-size: 99%;
          width: 30px;
          height: 30px;
        }
        .details {
          width: 100%;
          .user-name {
            color: $fbBlue;
            font-size: 11px;
            font-family: 'Lucida Grande';
            line-height: 15px;
          }
          .sponsored {
            background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/sponsored.png') no-repeat;
            background-size: contain;
            width: 100%;
            height: 18px;
          }
        }
      }
      .preview-text {
        line-height: 16px;
        padding-top: 10px;
      }
    }
    .facebook-preview-bottom {
      width: 400px;
      background: $white;
      padding-bottom: 10px;
      border-radius: 0 0 7px 7px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      .fb-ad {
        background: #eef0f3;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ad-details {
          .title {
            font-size: 15px;
            font-weight: bold;
            color: $black;
          }
          .description {
            color: $greyText;
            font-size: 11px;
            line-height: 13px;
          }
        }
        .cta {
          background: #e0e2e7;
          padding: 0px 10px;
          line-height: 30px;
          font-size: 11px;
          font-weight: bold;
          color: $black;
          border-radius: 4px;
        }
      }
      .fb-footer {
        background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/Botton_View.png') no-repeat center;
        width: 100%;
        height: 58px;
        background-size: contain;
      }
    }
    .instagram-feed {
      background: url($assetBaseUrl + 'icon/PlatformPreview/IGFeed/IG_Feed.png') center no-repeat;
      background-size: contain;
      width: 286px;
      height: 620px;
      position: relative;
      .profile-details {
        position: absolute;
        top: 75px;
        left: 15px;
        display: flex;
        gap: 5px;
        align-items: center;
        .blitz-icon {
          background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/blitzCircleIcon.svg') no-repeat;
          background-size: contain;
          width: 24px;
          height: 24px;
        }
        .user-name {
          font-size: 10px;
          font-weight: bold;
          color: $white;
        }
      }
      .cta {
        position: absolute;
        z-index: +1;
        bottom: 140px;
        left: 0;
        background: rgb(248, 14, 42);
        color: $white;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        line-height: 26px;
        width: 286px;
      }
      .content {
        position: absolute;
        z-index: +1000;
        bottom: 42px;
        left: 0;
        padding: 12px;
        width: 286px;
        color: $white;
        font-size: 9px;
        line-height: 14px;
        .views {
          font-weight: bold;
        }
      }
    }
    .instagram-story {
      background: url($assetBaseUrl + 'icon/PlatformPreview/IGStory/IGStoryBG.png') no-repeat center;
      background-size: contain;
      width: 286px;
      height: 620px;
      position: relative;
      .top-details {
        position: absolute;
        top: 25px;
        z-index: +1;
        width: 286px;
        left: 0;
        padding: 10px;
        .progress-bar {
          width: 100%;
          height: 3px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 2px;
          .fill {
            width: 30%;
            height: 3px;
            border-radius: 2px;
            background: $white;
          }
        }
        .profile-details {
          margin-top: 5px;
          display: flex;
          gap: 5px;
          width: 284px;
          // align-items: flex-start;
          .blitz-icon {
            background: url($assetBaseUrl + 'icon/PlatformPreview/Facebook/blitzCircleIcon.svg') no-repeat;
            background-size: 99%;
            width: 30px;
            height: 30px;
          }
          .details {
            width: 100%;
            color: $white;
            .user-name {
              font-size: 10px;
              line-height: 14px;
            }
            .caption {
              font-size: 9px;
              line-height: 14px;
            }
          }
        }
      }
      .options {
        position: absolute;
        z-index: +1;
        right: 10px;
        top: 45px;
        display: flex;
        align-items: center;
        gap: 15px;
        .more {
          background: url($assetBaseUrl + 'icon/PlatformPreview/SnapChat/ThreeDots.png') center no-repeat;
          background-size: contain;
          width: 5px;
          height: 15px;
          transform: rotate(90deg);
        }
        .insta-close {
          background: url($assetBaseUrl + 'icon/PlatformPreview/IGStory/cross.svg') center no-repeat;
          background-size: contain;
          width: 15px;
          height: 15px;
        }
      }
      .cta {
        color: $white;
        font-size: 9px;
        line-height: 30px;
        border-radius: 20px;
        padding: 0 10px;
        text-align: center;
        position: absolute;
        bottom: 35px;
        left: 50%;
        margin-left: -30px;
        background: rgba(255, 255, 255, 0.2);
        font-weight: bold;
      }
    }
    .tiktok {
      background-size: contain;
      width: 283px;
      height: 567px;
      position: relative;
      .top-shadow {
        position: absolute;
        z-index: +1;
        top: 0;
        width: 283px;
        height: 110px;
        background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/ShadowTop.png') no-repeat center;
        background-size: contain;
      }
      .top-overlay {
        position: absolute;
        z-index: +2;
        top: 0;
        width: 283px;
        height: 65px;
        background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/TopOverlay.png') no-repeat center;
        background-size: contain;
      }
      .bottom-shadow {
        position: absolute;
        z-index: +1;
        bottom: 63px;
        width: 283px;
        height: 110px;
        background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/ShadowBottom.png') no-repeat center;
        background-size: contain;
      }
      .bottom-overlay {
        position: absolute;
        z-index: +2;
        bottom: 0;
        width: 283px;
        height: 65px;
        background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/BottomOverlay.png') no-repeat center;
        background-size: contain;
      }
      .options-overlay {
        position: absolute;
        z-index: +2;
        bottom: 65px;
        right: 5px;
        width: 60px;
        height: 280px;
        background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/OptionsView.png') no-repeat center;
        background-size: contain;
      }
      .blitz-image {
        position: absolute;
        z-index: +2;
        bottom: 180px;
        left: 10px;
        background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/UseBlitz.png') no-repeat center;
        background-size: contain;
        width: 70px;
        height: 15px;
      }
      .promoted-music {
        position: absolute;
        z-index: +2;
        bottom: 100px;
        left: 10px;
        background: url($assetBaseUrl + 'icon/PlatformPreview/Tiktok/PromotedMusic.png') no-repeat center;
        background-size: contain;
        width: 100px;
        height: 13px;
      }
      .caption {
        position: absolute;
        bottom: 135px;
        left: 15px;
        z-index: +2;
        font-size: 11px;
        color: $white;
      }
      .cta {
        background: rgb(0, 141, 234);
        position: absolute;
        z-index: +2;
        bottom: 70px;
        left: 15px;
        width: 200px;
        line-height: 25px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: bold;
        color: $white;
        text-align: center;
      }
    }
    .snapchat {
      background: url($assetBaseUrl + 'icon/PlatformPreview/SnapChat/Overlay.png') center no-repeat;
      background-size: contain;
      width: 286px;
      height: 620px;
      position: relative;

      .profile-details {
        position: absolute;
        top: 20px;
        z-index: +1;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        width: 286px;
        padding: 10px;
        color: $white;
        .details {
          color: $white;
          .user-name {
            font-size: 10px;
            line-height: 14px;
          }
          .caption {
            font-size: 8px;
            margin-top: 5px;
            color: $greyText;
            line-height: 14px;
          }
        }
        .options {
          background: url($assetBaseUrl + 'icon/PlatformPreview/SnapChat/ThreeDots.png') center no-repeat;
          background-size: contain;
          width: 15px;
          height: 15px;
        }
      }

      .cta {
        color: $black;
        font-size: 10px;
        line-height: 22px;
        border-radius: 5px;
        padding: 0 10px;
        text-align: center;
        position: absolute;
        z-index: +1;
        bottom: 50px;
        left: 50%;
        margin-left: -35px;
        background: $white;
        font-weight: bold;
      }
    }
    .FB {
      width: 400px;
    }
    .IS {
      width: 285px;
      position: absolute;
      left: 0;
      top: 35px;
      border-radius: 12px;
    }
    .IG {
      width: 285px;
      position: absolute;
      left: 0;
      top: 110px;
    }
  }
  .title-container {
    width: 400px;

    .title-column {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-right: 20px;
    }

    .title {
      margin: 0px !important;
      font-weight: bold;
    }

    .variant-count {
      font-size: 12px;
      color: gray;
    }
  }

}

.ads-mockup-container.hide {
  display: none;
}