.ad-creative {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  p {
    font-size: 14px;
    line-height: 1rem;
  }
  input {
    width: 100%;
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  button {
    all: unset;
    width: fit-content;
    font-size: 14px;
    padding: 10px;
    background-color: $lightWhite;
    gap: 5px;
    cursor: pointer;
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  button,
  .img-placeholder,
  .info-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sub-title,
  .collapse__header,
  .header__left {
    display: flex;
    align-items: center;
  }
  section,
  .cards-list,
  .collapse,
  .collapse__footer {
    border-radius: inherit;
  }
  button,
  input,
  textarea,
  .img-placeholder {
    border-radius: 5px;
  }
  .img-placeholder {
    color: $darkGrey;
    background-color: $lightWhite;
    font-size: 22px;
    height: 40px;
    width: 40px;
  }
  .darkest-grey {
    color: $darkestGrey;
  }
  .add-media-btn {
    span {
      font-size: 22px;
    }
  }
  .sub-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    gap: 5px;
    .info-icon {
      color: $white;
      background-color: $black;
      font-size: 9px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      cursor: pointer;
      .tooltip {
        display: none;
        position: absolute;
        left: 255px;
        color: $black;
        background-color: $darkGrey;
        color: $white;
        padding: 5px 6px;
        border-radius: 3px;
      }
    }
    .info-icon:hover {
      .tooltip {
        display: block;
      }
    }
  }
  .cards-list {
    padding: 5px 0px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .collapse {
      padding: 10px;
      .collapse__header {
        justify-content: space-between;
        cursor: pointer;
        .header__left {
          gap: 8px;
          .header__title {
            font-size: 14px;
          }
        }
      }
      .collapse__footer {
        display: none;
        background-color: $white;
        padding: 10px;
        margin-top: 5px;
        .collapse__footer__content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          input {
            border: 1px solid $veryLightGrey;
            height: 35px;
            padding: 0 10px;
            font-size: 14px;
          }
          textarea {
            border: 1px solid $veryLightGrey;
            height: 35px;
            padding: 9px;
            font-size: 14px;
            width: 100%;
          }
          .remove-btn {
            margin-left: auto;
            order: 2;
            display: flex;
            .icon.trash {
              background-size: 27px;
              height: 10px;
            }
          }
        }
      }
    }
    .collapse.active {
      background-color: $veryPaleBlue;
      .collapse__footer {
        display: block;
      }
    }
  }
}
.ad-type-radio {
  p {
    font-size: 13px;
    line-height: 1rem;
  }
}

//overrides
.ad-creative {
  &.creator-tooltip {
    height: 12px;
    box-shadow: none;
    padding: 0;
    margin: 0px 5px;
    .info-icon {
      padding: 9px;
      font-size: 11px;
    }
  }
  .img-placeholder {
    color: $black;
  }
}

.collapse__footer {
  .collapse__footer__content {
    .story-snaps .campaign-input, .campaign-input-error{
      height: 45px !important;
      background-color: $white;
      border: 1px solid #a8a2a2;
    }
    .call-to-action {
      .dropdown-button {
        width: 150px;
      }
    }
    .media-img {
      max-height: 150px !important;
      width: 150px !important;
    }
    .appicon-story {
      width: 210px;
      height: 22px;
      border: none !important;
      // width: 100px;
      height: 23px;
    }
  }
}

