.landing-v2 {
  .pad-40 {
    padding: 40px 0;
  }
  .pad-50 {
    padding: 50px 0;
  }
  .pad-60 {
    padding: 60px 0;
  }
  .pad-80 {
    padding: 80px 0;
  }
  .peach-container {
    background: $peach;
  }
  .white-container {
    .pad {
      padding: 80px 0;
    }
    background: $white;
  }

  .faq-container {
    margin-top: 100px;
  }

  .layout-container {
    .flex-container {
      gap: 140px;
      .left {
        padding-left: 70px;
      }
      .right {
        padding-right: 70px;
      }
    }
    .left, .right {
      display: flex;
      align-items: center;
      justify-content: center !important;
      width: 50%;
      .sub-hero-image {
        border: 1px solid rgba(183, 192, 204, 0.5);
        border-radius: 25px;
        padding: 10px;
        background: $white;
        img {
          width: 100%;
          border-radius: 25px;
        }
      }
      .content {
        // max-width: 500px;
        h3 {
          margin-bottom: 20px;
        }
        h4 {
          margin-bottom: 20px;
        }
        p {
          color: $mediumGrey;
          margin-bottom: 40px;
        }
      }
    }
  }
  .hero-container-top {
    padding-bottom: 350px;
    h5 {
      margin-top: 5px;
      margin-bottom: 40px;
      max-width: 600px;
      text-align: center;
    }
  }
  .hero-container-bottom {
    padding-top: 350px;
    .hero-image {
      border-radius: 25px;
      border: 1px solid rgba(183, 192, 204, 0.5);
      margin-top: -800px;
    }
  }
  .editor-container-top {
    padding-bottom: 350px;
  }
  .editor-container-bottom {
    padding-top: 350px;
  }

  .blog-head-container-top {
    padding-bottom: 350px;
  }
  .blog-head-container-bottom {
    padding-top: 50px;
  }

  h2 {
    font-size: 52px;
    line-height: 70px;
    font-weight: 700;
    color: $h1GreyColor;
    margin: 0;
    &.orange, .orange {
      color: $primaryColor;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;
  }

  h4 {
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    color: $primaryColor;
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    font-family: Ubuntu;
    color: $mediumGrey;
    &.dark-blue {
      color: $h1GreyColor;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .hero-image {
    width: 945px;
    max-width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    img {
      width: 1098px;
      max-width: 100%;
      border-radius: 27px;
    }
  }

  .faqs {
    width: 800px;
    max-width: 100%;
    margin: auto;
    .faq {
      position: relative;
      margin-bottom: 20px;
      cursor: pointer;
      h4 {
        color: $faqColor;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
      }
      padding: 25px 20px;
      border-radius: 25px;
      border: 1px solid $greyBorder;
      transition: border-color 0.5s linear;
      .answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s linear;
        p {
          margin: 15px 0 auto;
          color: $carouselTextGrey;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          max-width: calc(100% - 60px);
        }
      }
      .toggle {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
        background: url($assetUrl + 'icons/down-arrow.svg') center no-repeat;
        transition: transform 0.5s linear;
      }
      &.active {
        border: 1px solid $primaryColor;
        .answer {
          max-height: 100px;
        }
        .toggle {
          transform: rotate(180deg);
          background: $primaryColor;
          // -webkit-mask: url(logo.svg) no-repeat center;
          mask: url($assetUrl + 'icons/down-arrow.svg') no-repeat center;
        }
      }
    }
  } /* .faqs */

  .editor-container, .blog-container {
    display: flex;
    justify-content: center;
    margin-top: -850px;
    .editor {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      width: 1120px;
      border-radius: 32px;
      background: $white;
      padding: 100px 160px;
      box-shadow: 0px 20px 60px 0px #0000001A;
      border: 1px solid $landingFormBorderColor;
      textarea {
        width: 100%;
        resize: none;
        padding: 30px;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        border-radius: 25px;
        border: 1px solid $greyBorder;
        &::placeholder {
          color: $landingFormBorderColor;
        }
        &:focus {
          outline: none;
        }
      }
      .or-text {
        color: $landingFormBorderColor;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        align-self: flex-start;
        padding-right: 20px;
        background: url($assetUrl + 'icons/long-arrow-new.svg') center right no-repeat;
      }
      ul.pre-defined-options {
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        li {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          padding: 20px;
          width: 190px;
          height: 190px;
          border-radius: 25px;
          color: $carouselTextGrey;
          background-color: $landingFormBorderColor;
        }
        &.images {
          li {
            padding: 0;
            width: 160px;
            height: 160px;
            background-color: transparent;
            &.prompt-image {
              .image {
                width: 160px;
                height: 160px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 25px;
              }
            }
          }
        }
      }
      .upload-area {
        width: 100%;
        color: #B7C0CC;
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: center;
        align-items: center;
        min-height: 20vh;
        gap: 10px;
        cursor: pointer;

        img {
          border-radius: 25px;
        }
        
        div:nth-of-type(1) { 
          border-radius: 25px;
        }

      }

      .upload-area-default-border {
        border-radius: 25px;
        border: 1px solid #B7C0CC;
      }
    }
  }
  .blog-container {
    margin-top: -500px;
    .blog-head {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      gap: 60px;
      width: 1170px;
      padding: 60px;
      .blog-hero {
        width: 430px !important;
        height: 430px;
        border-radius: 25px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .blog-details {
        max-width: 500px;
        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          margin-bottom: 25px;
          color: $primaryColor;
        }
        .sub-head {
          color: $black;
          font-size: 28px;
          font-weight: 700;
          line-height: 40px;
          margin-bottom: 20px;
        }
        .description {
          color: $carouselTextGrey;
        }
        .date-text {
          margin-top: 40px;
          color: $mediumGrey;
        }
      }
    }
  }
  .key-points-container {
    margin: auto;
    max-width: 1160px;
    gap: 100px !important;
    .left {
      max-width: 470px;
    }
  }
  .inner-container {
    width: 1170px;
    margin: auto;
  }
  .optimize-container-top {
    padding-bottom: 300px;
  }
  .optimize-container-bottom {

  }
  .optimize-container {
    width: 100%;
    margin-top: -380px;
    border-radius: 32px;
    background: $white;
    padding: 40px;
    border: 1px solid $landingFormBorderColor;
  }

  .gallery-container {
    .layout-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 40px 0 80px 0;
      img.collage {
        width: 100%;
      }
    }
  }

  .landing-footer {
    .blue-container {
      background: $topFooterBg;
    }
    .top {
      display: flex;
      gap: 140px;
      padding-bottom: 50px;
      .footer-left {
        max-width: 400px;
      }
      .footer-right {
        display: flex;
        width: 100%;
      }
    }
    .social-links {
      margin-top: 36px;
      display: flex;
      gap: 27px;
      align-items: center;
      .icon {
        width: 27px;
        height: 27px;
        &.facebook {
          background: url($assetUrl + 'icons/social/facebook.svg') no-repeat center;
        }
        &.twitter {
          background: url($assetUrl + 'icons/social/twitter.svg') no-repeat center;
        }
        &.linkedin {
          background: url($assetUrl + 'icons/social/linkedin.svg') no-repeat center;
        }
        &.youtube {
          background: url($assetUrl + 'icons/social/youtube.svg') no-repeat center;
        }
        &.instagram {
          background: url($assetUrl + 'icons/social/instagram.svg') no-repeat center;
        }
      }
      
    }

    ul.footer-stacked-links {
      width: 25%;
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 16px; 

      li {
        color: $greyBorder;
        a {
          color: $greyBorder;
          text-decoration: none;
          &:hover {
            color: $white;
          }
        }
        &.head {
          color: $white;
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          margin-bottom: 14px;
        }
      }
    }
  }
}
