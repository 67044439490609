.scroll-container.custom-scroll {

  &#campaigns-scroller {
    display: flex;
    flex-direction: column;
    flex: 1;
    .scrollbar-container {
      &.y {
        position: absolute;
        right: 0;
        .scrollbar {
          //
        }
      }
    }
  }

  &#advertising-funnel-scroller {
    .scrollbar-container {
      &.y {
        top: 0px;
        height: calc(100vh - 260px);
      }
    }
  }

  &#metrics-list-scroller {
    .metrics-list {
      // height: calc(100vh - 270px);
    }
    .scrollbar-container {
      &.y {
        top: 0px;
        height: calc(100vh - 270px);
      }
    }
  }

  &#charts-content-scroller {
    .charts__content {
      height: calc(100vh - 250px);
    }
    .scrollbar-container {
      &.y {
        top: 0px;
        right: 10px;
        height: calc(100vh - 250px);
      }
    }
  }

  &#sheets-scroller {
    .scrollbar-container {
      &.y {
        height: 84px;
      }
    }
  }

  
}
