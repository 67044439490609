//  New filters revamp
.filters-container {
  position: relative;
  button.filters-button {
    font-size: 16px;
    font-weight: 500;
    background: url($assetUrl + 'icons/filters.svg') no-repeat 10px center;
    display: block;
    padding-left: 33px;
    position: relative;
    background-color: white;
    .active-button-overlay {
      position: absolute;
      z-index: +101;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 10px;
      background: $white;
      display: none;
    }
    &.active {
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      .active-button-overlay {
        display: block;
      }
    }
  }
  .filters-dropdown {
    position: absolute;
    top: 36px;
    left: 0;
    z-index: +100;
    width: 245px;
    background: $white;
    border-radius: 4px;
    max-height: calc(100vh - 260px);
    overflow: auto;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    .reset-link {
      position: sticky;
      bottom: 0;
      display: block;
      font-weight: 500;
      font-size: 14px;
      color: $darkGrey;
      line-height: 32px;
      text-align: center;
      background: $white;
      text-decoration: none;
      border-top: 1px solid $iceBlue;
      width: 100%;
      border: 0;
      cursor: pointer;
      font-weight: bold;
      // box-shadow: 0 0px 2px 2px rgba(0, 0, 0, 0.05);
      &:hover {
        background: $wellColor;
      }
    }
  }
}

.no-data-msg {
  text-align: center;
  padding: 16px;
  font-size: small;
}

.filters-section {
  position: relative;
  border-bottom: 1px solid $iceBlue;
  padding: 0 0 10px 0;
  background: $white;
  .title {
    cursor: pointer;
    padding: 15px 15px 5px 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    background: url($assetUrl + 'icons/visible.svg') no-repeat 200px 18px;
    background-size: 20px 20px;
  }
  &.collapsed-section {
    .title {
      background-image: url($assetUrl + 'icons/hidden.svg');
    }
  }
  .options {
    // opacity: 1;
    max-height: 170px;
    overflow: hidden auto;
    transition: 0.3s ease-in-out all;
    &.platform-options {
      max-height: 170px;
    }
    &.campaign-options {
      max-height: 170px;
    }
    &.age-options {
      max-height: 170px;
    }
    &.gender-options {
      max-height: 136px;
    }
    &.geography-options {
      max-height: 240px;
    }

    &.collapse {
      max-height: 0;
      // opacity: 0;
      overflow: hidden;
    }
  }
  .filter-option {
    padding: 0 15px;
    font-size: 15px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    display: flex;
    cursor: pointer;
    &:hover, &.active {
      background: $wellColor;
    }
    .custom-checkbox-container {
      margin-right: 12px;
    }
    .label {
      white-space: nowrap;
      text-overflow: ellipsis;
      // width: 160px;
      overflow: hidden;
    }
    &.has-options {
      position: relative;
      &::after {
        content: "";
        border-top: 5px solid transparent;
        border-left: 5px solid $darkGrey;
        border-right: 0;
        border-bottom: 5px solid transparent;
        position: absolute;
        right: 15px;
        top: 12px;
      }
    }
  }
  .filter-search-container {
    box-sizing: border-box;
    padding: 0 15px;
    margin: 10px auto;
    .filter-search {
      background: $iceBlue;
      border-radius: 4px;
      border: 0;
      padding: 0;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      padding: 10px;
      &:focus {
        outline: none;
      }
    }
  }
  .filters-loader {
    margin: 5px 15px;
  }

  .disabled-overlay {
    position: absolute;
    top: 0;
    width: 240px;
    height: 100%;
    background:rgba(255, 255, 255, 0.75);
    cursor: not-allowed;
  }

  .disabled-row {
    opacity: 0.5;
    pointer-events: none;
  }
}

.filters-options-overlay {
  max-height: 250px;
  position: absolute;
  top: 50px;
  left: 280px;
  z-index: +100;
  width: 245px;
  background: $white;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  .filters-section {
    .title {
      background: none;
    }
  }
  &#ad-filters {
    left: 530px;
  }
}

.filters-tooltip {
  margin-top: -50px;
  margin-left: -100px;
}

// scrollbar CSS only for filters UI
.filters-container, .filters-options-overlay {

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    // background: $iceBlue;
    // border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dddddd;
    &:hover {
      background: $primaryColor;
    }
    border-radius: 5px;
  }
}

// ===================
// below code should be removed after integration V2 filters
// ===================

.filter-container {
  .search-container {
    position: relative;
  }
  .chip-area {
    max-height: 7vh;
    overflow: auto;
  }
  color: $black;
  padding: 4px;
  width: 100%;
  border-radius: 4px;
  background: url($assetUrl + 'search.svg') no-repeat center right;
  background-color: $white;
  background-position: calc(100% - 7px) 7px;
  background-size: 27px 30px;
  input.search {
    border: none;
    box-shadow: none;
    background: $iceBlue;
    font-size: 14px;
    // padding: 5px;
    width: 300px;
    height: 30px;
    line-height: 30px;
    margin: 1px 0;
    &:focus {
      // outline: 2px solid $iceBlue;
      outline: none;
    }
  }

  .MuiChip-root {
    height: 30px;
    margin: 1px 5px 1px 2px;
  }

  .close-chip {
    @include closeChip;
    color: $black;
    &:hover {
      color: $black;
      background: rgba(0, 0, 0, 0.1);
    }
  }


  .light-text {
    color: #777777;
  }

  .light-bold-text {
    color: #777777;
    font-weight: bold;
  }

  .hightlight {
    background: #dddddd;
  }

  .filter-container-options {
    position: absolute;
    min-width: 300px;
    z-index: 1000;
    left: 0;
    top: 32px;
    background: $white;
    color: $black;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    display: none;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        cursor: pointer;
        font-size: 13px;
        padding: 5px 12px;
        border-radius: 4px;
        &:hover {
          background: $lightOrange;
        }
      }
    }
  }
}
.campaigns {
  input.search {
    background: $white;
  }
}

// filters in analytics
.level-5 {
  .sub-options {
    max-height: 300px;
    overflow-y: auto;
  }
}


.level-2 {
  ul.sub-options {
    max-height: 400px;
    overflow: auto;
  }
}
.filters {
  button {
    &.filter {
      &.even-pad {
        padding-right: 1rem;
      }
    }
  }
}

//overrides
.search-container {
  display: flex;

  form {
    height: 39px;
  }

  .search-bar {
    .search {
      min-width: 368px;
      border: 2px solid transparent;

      &:focus {
        border: 2px solid $primaryColor;
      }
    }

    .searchBarIcon {
      position: absolute;
      left: 500px;
    }
  }

  .search-bar-sub-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 2px #e9f0f3;
    padding: 10px 16px;
    padding-right: 5px;
    border-radius: 50px;

    button {
      background-color: rgba(0, 0, 0, 0.54);
    }
  }
}
