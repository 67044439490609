.notification {
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  .message {
    margin-top: 12px;
    font-size: 14px;
    font-weight: normal;
  }
  &.info {
    color: rgb(13, 60, 97);
    background-color: rgb(232, 244, 253);
  }
  &.warning {
    color: rgb(102, 60, 0);
    background-color: rgb(255, 244, 229);
  }
  &.success {
    color: rgb(30, 70, 32);
    background-color: rgb(237, 247, 237);
  }
  &.error {
    color: rgb(97, 26, 21);
    background-color: rgb(253, 236, 234);
  }
}

//overrides
.notification {
  &.notification-ads {
    padding: 5px;
    overflow: hidden;
    text-align: center;
    min-height: 35px;
  }
}

