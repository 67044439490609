.automation {
  position: relative;
  padding: 5px 10px;
  background: $white;
  height: 275px;
  max-width: 100%;
  max-height: 100%;

  .automation-title {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }

  .automation-desc {
    font-size: 12px;
    justify-content: start;
    text-align: left;
    padding: 5px;
  }

  .automation-metric-title {
    color: rgb(110, 108, 108);
    display: flex;
    font-size: 12px;
    align-items: left;
    justify-content: left;
    padding: 5px;
  }

  .grid-metric {
    margin: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
    background-color: $white;
    grid-gap: 10px;
    .add-account-filter {
      .close {
        color: unset !important;
        background-color: unset !important;
        top: 2px;
        font-size: 15px;
      }
      .sub-options {
        max-height: 112px;
        overflow: scroll;
      }
  }
  .filters {
    .dropdown.add-account-filter {
      width: 100%;
      margin-bottom: 5px;
      button {
        &::after {
          top: 15px;
        }
      }
    }
    button.add-account-filter.filter {
      font-size: 12px;
      width: fit-content;
      padding: 0 30px 0 10px;
      height: 25px;
      display: flex;
      align-items: center;
    }
      }
  }

  .h-125 .select-list {
    height: 125px;
    overflow: auto;
  }
}

button#audience-menu-btn {
  padding: 0 15px 0 15px !important;
}

.custom-audience-modal {
  .modal-overlay {
    top: 2rem;
  }
  .modal-content {
    max-width: 600px;
    width: 600px;
    border-radius: 10px;
    padding: 15px;
    .modal-header {
      display: flex;
      justify-content: space-between;
      h3 {
        margin: 0;
      }
      p {
        font-size: 14px;
        margin: 0;
      }
    }
    .modal-body {
      .modal-body-footer {
        display: flex;
        gap: 5px;
        justify-content: flex-end;
      }
    }
    .step-1 {
      .modal-body {
        .modal-body-content {
          padding: 25px;
          .source-label {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
    .step-2 {
      height: 75vh;
      input {
        border: 1px solid $veryLightGrey;
        border-radius: 5px;
        height: 35px;
        padding: 0 10px;
        font-size: 14px;
      }
      .modal-header {
        margin-bottom: 15px;
      }
      .modal-body {
        .modal-body-content {
          max-height: 65vh;
          overflow: auto;
          .operator-selector {
            font-size: 14px;
            margin: 0;
            display: flex;
            align-items: center;
            padding: 20px 0;
            .operator-custom-selector {
              margin: 0 5px;
            }
          }
          .rules-container {
            .exclusions-label {
              font-size: 14px;
              margin-bottom: 10px;
            }
            .rule {
              padding: 20px 15px;
              border: 1px solid $veryLightGrey;
              border-radius: 8px;
              margin-bottom: 10px;
              position: relative;
              .delete {
                position: absolute;
                top: 10px;
                right: 15px;
                cursor: pointer;
              }
              .or-and-label {
                font-size: 14px;
                font-weight: 700;
                margin: 0 0 10px 0;
                text-transform: capitalize;
              }
              .rule__label {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 5px;
              }
              .rule__field {
                margin-bottom: 15px;
                .dropdown .dropdown-button {
                  width: 100%;
                  font-size: 14px;
                  font-weight: 500;
                }
                input {
                  width: 30%;
                }
              }
              .url__filter {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 10px;
                .rule__label {
                  margin-bottom: 0;
                }
                .dropdown {
                  width: 150px;
                }
              }
              .percentile,
              .url {
                margin-top: 15px;
              }
            }
          }
          .filter-btn-container {
            display: flex;
            gap: 10px;
            margin: 20px 0;
          }
          .custom-audience__details {
            padding-top: 2px;
            border-top: 1px solid $veryLightGrey;
            .custom-audience__detail {
              display: flex;
              flex-direction: column;
              margin: 20px 0;
              label {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
              }
              .input-container {
                display: flex;
                align-items: center;
                position: relative;
                input {
                  width: 100%;
                  padding-right: 60px;
                }
                span {
                  position: absolute;
                  right: 10px;
                  font-size: 13px;
                  color: $darkGrey;
                }
              }
            }
          }
        }
      }
      .modal-body-footer {
        margin-top: 15px;
      }
    }
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
}

.locked-overlay-template {
  padding-right: 35px !important;
  position: relative;
  pointer-events: none;

  &::before {
    content: "";
    background-image: url(https://media.useblitz.com/assets/images/crown.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 10px;
    right: 10px;
    display:block;
    background-color: white;
    border-radius: 50%;
    border: 4px solid white;
    outline: 1px solid #C8C8C8;
  }
}

.locked-overlay-performance {
  padding-right: 35px !important;
  position: relative;
  pointer-events: none;

  &::before {
    content: "";
    background-image: url(https://media.useblitz.com/assets/images/crown.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 8px;
    right: 10px;
    display:block;
  }
}

.locked-overlay-duplicate-btn {
  padding-right: 35px !important;
  position: relative;
  pointer-events: none;

  &::before {
    content: "";
    background-image: url(https://media.useblitz.com/assets/images/crown.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 8px;
    right: 10px;
    display:block;
  }
}

.locked-overlay-audience {
  padding-right: 20px !important;
  position: relative;
  pointer-events: none;

  .dropdown-button::after {
    margin-right: 25px;
  }

  .dropdown-button {
    padding-right: 50px;
  }

  &::before {
    content: "";
    background-image: url(https://media.useblitz.com/assets/images/crown.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 8px;
    right: 37px;
    display:block;
    z-index: 1;
  }

  button {
    opacity: 0.3;
  }
}

.locked-overlay-export {
  padding-right: 35px !important;
  position: relative;
  pointer-events: none;

  &::before {
    content: "";
    background-image: url(https://media.useblitz.com/assets/images/crown.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 8px;
    right: 10px;
    display:block;
  }

  button {
    opacity: 0.3;
  }
}

.locked-overlay-add-duplicate {
  position: relative;
  pointer-events: none;

  &::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.4);
    background-image: url(https://media.useblitz.com/assets/images/crown.svg);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display:block;
    z-index: 1;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  button {
    opacity: 0.3;
  }
}

.locked-overlay-snapchat {
  padding-right: 35px !important;
  position: relative;
  pointer-events: none;

  &::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.8);
    background-image: url(https://media.useblitz.com/assets/images/crown.svg);
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: left 125px bottom 55% !important;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display:block;
    z-index: 1;
    border-radius: 12px;
  }

  button {
    opacity: 0.3;
  }
}

.locked-overlay-tiktok {
  padding-right: 35px !important;
  position: relative;
  pointer-events: none;

  &::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.8);
    background-image: url(https://media.useblitz.com/assets/images/crown.svg);
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: left 105px bottom 55% !important;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display:block;
    z-index: 1;
    border-radius: 12px;
  }

  button {
    opacity: 0.3;
  }
}

.sheet-icon {
  padding: 0 10px 0 30px;
  background-repeat: no-repeat !important;
  background-position: 4px center !important;
  background-size: 27px auto !important;
  background-image: url(https://media.useblitz.com/assets/images/sheet.svg) !important;
  &:disabled {
    background-position: 4px center !important;
    background-size: 24px auto !important;
    background-repeat: no-repeat !important;
    background-image: url(https://media.useblitz.com/assets/images/sheet-greyed.png) !important;
  }
}