.breadcrum {
  padding: 0;
  margin: 0 15px;
  li {
    padding: 15px;
    font-size: 16px;
    list-style: none;
    position: relative;
    display: inline-block;
    &::after {
      content: '>';
      position: absolute;
      top: 16px;
      right: -3px;
    }
    &:last-child::after {
      content: '';
    }
    &.active {
      font-weight: bold;
    }
    a {
      color: $black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }  
}
