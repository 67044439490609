.analytics {
  main {
    padding-top: 3.9rem;

    section.title-banner-grey {
      div.custom-form-control {
        width: 300px;
        .date-picker {
          font-weight: 700;
        }
        .arrow {
          top: 23px;
        }
      }
    }

    section.content-container {
      .overview {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        .overview__data {
          display: flex;
          background-color: $tableRowColorEven;
          width: 100%;
          overflow: hidden;
          .tab {
            min-width: 234px;
            height: 60px;
            padding: 10px 26px 7px 26px;
            border-right: solid 2px $iceBlue;
            border-bottom: solid 2px $iceBlue;
            background-color: $tableRowColorOdd;
            cursor: pointer;
            display: flex;
            align-items: center;
            .tab-label-big {
              font-size: 16px;
              font-weight: 500;
              color: #7f7f7f;
              padding-bottom: 0px;
              overflow: hidden;
              white-space: wrap;
              text-align: center;
            }
            .tab__label {
              font-size: 14px;
              font-weight: 500;
              color: #7f7f7f;
              padding-bottom: 5px;
            }
            .tab__values {
              display: flex;
              align-items: center;
              gap: 7px;
              line-height: 1.3;
              .amount {
                font-size: 20px;
                font-weight: bold;
              }

              .change {
                display: flex;
                align-items: center;
                gap: 4px;
                font-size: 16px;
                font-weight: normal;
                &.up {
                  color: $green;
                  &::before {
                    content: '';
                    border-bottom: 5px solid $green;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 0;
                  }
                }

                &.down {
                  color: $primaryColor;
                  &::before {
                    content: '';
                    border-top: 5px solid $primaryColor;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 0;
                  }
                }
              }
            }
          }
          .tab.active {
            background-color: #fff;
            cursor: default;
            border-right: 2px solid $iceBlue;
            border-left: 2px solid $iceBlue;
            border-bottom: 0;
            border-left: 0;
            position: relative;
            &::after {
              position: absolute;
              content: '';
              height: 2px;
              background: $white;
              width: 100%;
              bottom: -2px;
              left: 0;
            }
          }
          .navigation-icon {
            position: absolute;
            width: 45px;
            height: 45px;
            background: url($assetUrl + 'arrow-icon.svg') no-repeat center;
            background-size: contain;
            cursor: pointer;
            &.previous {
              top: 68px;
              left: 10px;
            }
            &.next {
              top: 63px;
              right: 10px;
              transform: rotate(180deg);
            }
          }
        }
        .overview__content {
          flex: 1;
          padding: 20px 26px;
          .top-creatives {
            display: flex;
            gap: 20px;
            .creative {
              display: flex;
              width: 20%;
            }
          }
          .tab-content {
            display: none;
            &.active {
              display: block;
            }
          }
        }
      }

      .charts {
        display: grid;
        flex: 1;
        grid-template-columns: 200px 1fr;
        grid-template-rows: 1fr;
        .left-sidebar {
          background-color: $tableRowColorOdd;
          border-right: 2px solid $iceBlue;
          h3 {
            margin: 10px;
            font-size: 16px;
            padding: 0 8px;
          }
          ul {
            list-style-type: none;
            padding: 8px 0;
            max-height: calc(100vh - 290px);
            li {
              margin: 0 10px;
              button {
                width: 100%;
                height: fit-content;
                text-align: left;
                padding: 6px 0 6px 21px;
                font-size: 14px;
                font-weight: 600;
                color: #7f7f7f;
                cursor: pointer;
              }
              button.active {
                border-radius: 10px;
                border: solid 2px $iceBlue;
                background-color: #fff;
                color: #ef4834;
              }
            }
          }
          button {
            font-size: 14px;
            font-weight: 500;
            background: none;
            color: #000;
            border: none;
            padding: 0 20px;
            outline: inherit;
            cursor: pointer;
          }
          .metrics-list {
            .name {
              word-break: break-all;
              width: 100px;
            }
            .user-metric {
              .hidden {
                display: none;
              }
              button {
                padding: 6px 6px 6px 21px;
              }
            }
            .user-metric:hover {
              button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 2px;
              }
              .hidden {
                display: block;
                width: 15px;
                height: 15px;
                background-size: 15px;
                background-repeat: no-repeat;
              }
            }
          }
        }
        .right-side {
          background-color: $tableRowColorEven;
          display: flex;
          flex-direction: column;
          width: calc(100vw - 296px);
          .charts__content {
            .charts-content-spacer {
              margin: 10px 18px;
              flex: 1;
              padding: 0;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 16px;
            }
            .chart,
            .chart__data {
              width: 100%;
              background-color: #fff;
              border-radius: 20px;
              padding: 20px 24px;
              h3 {
                margin: 0;
              }
            }
          }
        }
        .hidden.edit {
          background-image: url('https://media.useblitz.com/assets/images/icons/edit-icon-new.svg');
        }
        .hidden.delete {
          background-image: url('https://media.useblitz.com/assets/images/icons/delete-icon-new.svg');
        }
      }

      .advertising-funnel {
        display: flex;
        flex-direction: column;
        flex: 1;
        .advertising__content {
          flex: 1;
          background-color: $tableRowColorEven;
          padding: 10px 18px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          overflow: hidden;
          height: calc(100vh - 260px);
          .chart {
            width: 100%;
            background-color: #fff;
            border-radius: 20px;
            padding: 20px 24px;
            h3 {
              margin: 0;
            }
          }
        }
      }

      .filter-section {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        gap: 15px;
        background: $white;
        border-bottom: solid 2px $iceBlue;
        .dropdown-content {
          .filter-list {
            .has-options {
              white-space: nowrap;
              .searchable {
                max-height: 35vh;
              }
            }
          }
        }
      }
    }
  }

  .sheet-head {
    .sheet-cell {
      font-weight: 700 !important;
    }
  }
  .reports-media {
    text-align: center;
    height: 60px;
    cursor: pointer;
    
    video, img{
      width: 39px;
      height: 39px;
      border-radius: 3px;
      object-fit: cover;
    }
  }
}

.custom-metric-modal {
  .modal {
    max-width: 800px;
  }
  .modal-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 4px;
    input:focus {
      outline: none;
    }
    .input-fields {
      display: flex;
      flex-direction: column;
      gap: 6px;
      label {
        font-size: 18px;
        font-weight: 600;
        .optional-field {
          color: #7f7f7f;
        }
      }
      .input {
        display: flex;
        position: relative;
        input {
          width: 100%;
          padding: 8px 10px;
          padding-right: 70px;
          border: 1px solid rgba(183, 186, 188, 0.36);
          height: 40px;
          border-radius: 8px;
        }
        .character-count {
          position: absolute;
          right: 5px;
          top: 8px;
          font-size: 15px;
          color: $darkestGrey;
        }
      }
    }
    .formula-generator {
      .container {
        border: 1px solid rgba(183, 186, 188, 0.36);
        border-radius: 8px;
        .operation-btns {
          display: flex;
          padding-left: 0;
          margin-bottom: 0;
          gap: 1px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          overflow: hidden;
          .operation {
            height: 40px;
            width: 17%;
            background: $veryLightGrey;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            cursor: pointer;
          }
        }
        .input-container {
          display: flex;
          gap: 4px;
          height: 120px;
          overflow-y: scroll;
          padding: 10px;
          flex-wrap: wrap;
          .expression-blocks {
            padding: 5px 10px;
            background: $veryLightGrey;
            border-radius: 4px;
            height: fit-content;
          }
        }
        .generated-formula {
          padding: 10px 10px;
          span {
            font-weight: bold;
          }
        }
        .search-filter {
          flex: 1;
          position: relative;
          input.search-box {
            padding: 0;
            width: 90%;
            border: none;
            height: 45px;
            background: $white;
            &:hover {
              background: $white;
            }
          }
          ul {
            list-style: none;
            padding: 0 !important;
            border-radius: 0 !important;
            position: fixed;
            z-index: 11;
            max-height: 130px;
            width: fit-content;
            max-height: 148px;
            overflow: auto;
            li {
              width: auto;
              white-space: normal !important;
              word-break: break-word;
              font-size: 16px;
              cursor: pointer;
              position: relative;
              padding: 5px 10px;
              display: flex;
              align-items: flex-start;
              background: #eeeeee;
              &:hover {
                background: $primaryColor;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
.delete-modal {
  .modal-content {
    text-align: center;
    padding: 10px;
  }
}

.no-data-message {
  margin: 100px auto 0;
  width: fit-content;
}

.no-data-message-advertising {
  margin: 50px auto;
  width: fit-content;
}

.apply-btn {
  height: 36px;
  margin-bottom: 6px !important;
}

.overview .loader-overlay,
.advertising-funnel .loader-overlay,
.advertising__content .loader-overlay,
.charts .loader-overlay {
  z-index: 101 !important;
}

.edit {
  background-image: url('https://media.useblitz.com/assets/images/icons/edit-icon-new.svg');
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: 15px;
  background-repeat: no-repeat;
}

.reports.table-grid {
  width: 100%;
  table-layout: fixed;
  thead {
    th {
      padding: 16px;
    }
  }
  td.name {
    a {
      display: block;
      max-width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  #rename-input {
    max-width: 95%;
  }
}

.reports {
  table {
    tbody {
      td.name {
        text-decoration: underline;
      }
    }
  }
  .reports__content {
    .report__list {
      list-style: none;
      margin: 0;
      tbody tr td {
        cursor: pointer;
      }
    }
  }
  .back-btn {
    all: unset;
    cursor: pointer;
    display: flex;
    gap: 8px;
    font-weight: 700;
    font-size: 16px;
  }
  .filter-section {
    display: flex;
    justify-content: space-between;

    .filter__left {
      display: flex;
      align-items: center;
      gap: 18px;
      .report__name {
        font-size: 16px;
        font-weight: 700;
        color: #000;
      }
    }
    .filter__right {
      display: flex;
      gap: 18px;
      align-items: center;
      .dropdown {
        .column-views {
          max-height: 300px;
          overflow: auto;
          display: flex;
          flex-direction: column;
        }
      }
      .column-config-button {
        // margin-right: 10px;
        font-size: 14px;
        .dropdown-button {
          height: 36px;
          line-height: 36px;
          padding: 0 30px 0 10px;
          &::after {
            right: 12px;
            top: 16px;
          }
        }
        .dropdown-content {
          top: 30px;
        }
      }
      .dropdown button {
        margin-left: 0;
        justify-content: left;
      }
    }
  }
  .data-grid-container .data-grid .data-grid-body .data-grid-row .data-grid-cell {
    height: 46px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      box-orient: vertical;
      white-space: normal;
    }
  }
  .report {
    .cta-text.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
.seventy-five {
  width: 75%;
}
.twenty-five {
  width: 25%;
}

.analytics .custom-select .dropdown-content ul.select-list li span {
  word-break: keep-all;
}

.analytics .select-list li {
  white-space: nowrap;
}

.segment-selector {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  .segment {
    margin-right: 30px;
    button.dropdown-button {
      height: 36px;
      background-color: #fff;
      border: 1px solid #ef4834;
      color: #ef4834;
      // @include lineBorderButton;
      // padding-right: 25px;
      // border-color: #333;
      // color: #333;
      &::after {
        top: inherit !important;
        border-top: 5px solid #ef4834;
      }
    }
  }
}
.analytics .filters button.filter {
  margin-bottom: 0;
}

.filter-modal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px !important;
  max-height: 80vh;
  overflow: auto;
  button.filter {
    max-width: 300px;
    display: flex;
    span.text {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }
  .remove {
    button {
      display: flex;
      align-items: center;
    }
    margin-left: 10px;
  }
}

//overrides
.segment-selector .segment button.dropdown-button {
  &::after {
   top: 15px !important;
  }
}

.mediaName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report {
  .report-alert {
    position: absolute;
    margin-top: 80px;
  }
  .filter-section {
    .creatives-selected,
    .creatives-by {
      .dropdown-button {
        max-height: 43px;
        max-width: 200px;
        overflow: hidden;
      }
      .dropdown-content .select-list {
        overflow-y: scroll;
        max-height: 300px;
      }
    }
  }

  &.creatives-container {
    .report__content {
      &.headingWidth {
        .data-grid-container {
          height: calc(100vh - 311px) !important;
        }
      }
    }
    .data-grid-head-container {
      .data-grid-row .data-grid-cell {
        text-align: left !important;
      }
    }
    .data-grid-container {
      height: calc(100vh - 295px);
      .data-grid .data-grid-body {
        .data-grid-row > .data-grid-cell:first-child {
          text-align: center !important;
        }
        .data-grid-row .data-grid-cell {
        padding: 6px 5px 8px 5px;
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            box-orient: vertical;
            white-space: normal;
        }
      }
    }
    }
    .data-grid-footer-container {
      display: none;
    }
  }
}

.segmentation-container {
  display: flex;

  :first-child {
    #segmentation-btn {
      background: url("https://media.useblitz.com/assets/images/icons/segment-icon.svg") no-repeat 10px center;
    }
  }

  .segmentation-drop-body {
    padding: 10px;

    .custom-checkbox-container {
      justify-content: start;
      padding: 3px 0;
      font-weight: 400;

      .label {
        width: 100%;
      }
    }
  }

  .filters-dropdown {
    width: 200px !important;
  }

  .selected-segments-container {
    max-width: 800px;
    overflow-y: auto;
    display: flex;

    .selected-segment-card {
      display: flex;
      align-items: center;
      background-color: #fafafa;
      border: 1px solid #bfcad0;
      padding: 7px;
      margin-left: 10px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 500;

      .vertical-divider {
        width: 2px;
        height: 100%;
        margin-left: 2px;
        background-color: #d8d8d8;
        cursor: move;
      }

      label {
        margin-left: 12px;
        margin-right: 5px;
      }

      .delete-btn {
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
        border: 1px solid #c7c7c7;
        border-radius: 3px;
        color: #c7c7c7;
        background-color: #fafafa;
        font-size: 10px;
        cursor: pointer;
      }
    }
  }
}

.filter-status-container {
  display: flex;
  z-index: 3;

  & > div > .dropdown-button {
    height: 20px;
    width: 25px;
    background: url("https://media.useblitz.com/assets/images/icons/filters.svg") no-repeat 8px center !important;
    border: none;
    cursor: pointer;
    font-size: 0;
    padding: 3px !important;

    &::after {
      display: none;
    }
  }

  #status-filter-btn {
    height: 20px;
    width: 25px;
    background: url("https://media.useblitz.com/assets/images/icons/filters.svg") no-repeat 8px center;
    border: none;
    cursor: pointer;
  }

  .status-drop-body {
    padding: 10px;

    .custom-checkbox-container {
      justify-content: start;
      padding: 3px 0;

      .label {
        width: 100%;
        font-weight: 400;
      }
    }
  }

  .status-filters-dropdown {
    width: 160px !important;
  }
}

.down-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ACACAC;
  font-size: 0;
  line-height: 0;
  float: left;
}

.right-arrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #ACACAC;
  font-size: 0;
  line-height: 0;
  float: left;
}

.multi-level-collapser-btn {
  display: flex;
  flex: 1;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.export-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}