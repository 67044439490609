.small-container {
  // width: 800px;
  margin: 5px 60px 30px 60px;
  @include width800 {
    width: 100%;
  }
}
.container-no-pad {
  padding: 3.9rem 0 100px 0 !important;
}

.mh-3 {
  margin: 0 3rem;
}
.mv-3 {
  margin: 3rem 0;
}

.iceblue-bg {
  background: $iceBlue !important;
}

.panels {
  display: flex;
  .panel {
    background: $white;
    border-radius: 2px;
    height: max-content;
    &.left {
      width: 210px;
    }
    &.middle {
      margin-left: 18px;
      width: 900px;
    }
    &.right {
      width: 210px;
    }
  }
}

.flex {
  display: flex;
  &.extremes {
    justify-content: space-between;
  }
  &.aic, &.center-items {
    align-items: center;
  }
  &.jcc {
    justify-content: center;
  }
  &.end {
    justify-content: flex-end;
  }
  &.g-10 {
    gap: 10px;
  }
  &.g-15 {
    gap: 15px;
  }
  &.g-20 {
    gap: 20px;
  }
  &.full {
    flex: 1;
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lh-30 {
  line-height: 30px;
}

.inline-block {
  display: inline-block;
}

.title-banner {
  position: relative;
  background: $primaryColor;
  color: $white;
  padding: 10px 45px 45px;
  &.as-header {
    // padding: 12px 45px;
    h1 {
      margin: 1rem 0;
      text-align: center;
    }
  }
  // text-align: center;
  // font-size: 35px;
  // font-weight: 900;
  
  .top-left-options {
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    top: 14px;
    left: 53px;
  }
  &.analytics-banner {
    padding-top: 1px;
    background: $lightBlue;
  }
}

.title-banner-grey {
  display: flex;
  gap: 20px;
  padding: 0 18px;
  align-items: center;
  width: 97%;
  margin: 0 auto;
  h1 {
    color: #ef4834;
    font-size: 26px;
    margin: 1rem 0;
  }
}

.settings-title {
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 52px;

  color: #EF4834;
  margin-left: 40px;
  margin-top: 10px;
}

.account-panel-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 10px;

    .menu-container {
      width: 15vw;
      margin-right: 20px;
      margin-left: 40px;
    }
    
    .account-settings-container {
      width: 72vw;
      left: 55%;
      background-color: white;
      border-radius: 2px;
    }
}

// .title-banner-white {
//   padding: 0 50px;
//   border-top: 2px solid $iceBlue;
//   border-bottom: 2px solid $iceBlue;
//   display: flex;
//   h1 {
//     margin: 1rem;
//   }
//   p {
//     margin: auto;
//     margin-left: 40px;
//   }
// }

.or-box {
  margin: 15px 0;
  display: flex;
  color: $darkGrey;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  .dash {
    width: 100%;
    height: 2px;
    background: $iceBlue;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}
.break-all {
  * {
    word-break: break-all;
  }
}
.break-word {
  * {
    word-break: break-word;
  }
}
.all-mr-20 {
  *:not(.MuiButton-label) {
    margin-right: 20px;
  }
}
.text-center {
  text-align: center;
}
.contact-page {
  background: $primaryColor;
  height: 100vh;
}

.bottom-rule {
  border-bottom: 2px solid $iceBlue;
}

.fr {
  float: right;
}


//overrides
.container-no-pad {
  &.projects {
    padding-bottom: 0 !important;
  }
}

.subscription-container {
  padding: 20px 30px;
  overflow: auto;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;

    color: #000000;
  }

  .subscription-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-bottom: 20px;

    .plan-type {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      letter-spacing: 0.02em;

      color: #5D6473;
    }

    .plan-price-container {
      margin-top: 15px;
      display: flex;
      align-items: center;

      .price {
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 41px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #EF4834;
      }

      .month {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.02em;
        margin-left: 7px;
        color: #5D6473;
      }
    }

    .total-ai-used {
      margin-left: 75px;
      padding: 0 20px;
      border-left: 1px solid #E5E5E5;

      .ai-used-title {
        font-size: 14px;
        font-weight: 500;
        color: #5D6473;
      }
      
      .ai-used-count {
        color: black;
        font-size: 34px;
        margin-top: 15px;
        font-weight: 500;

        .muted-text {
          margin-left: 10px;
          font-size: 18px;
          color: #b5b5b5;
        }
      }

      .ai-used-stats {
        display: flex;
        flex-direction: row;
        gap: 50px;
        margin-top: 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
      }
      
      .orange-text {
        color: #EF4834 !important;
      }
    }

    .upgrade-to-pro-btn {
      .upgrade-btn {
        background: #EF4834 !important;
        border-radius: 4px !important;
        color: white !important;
        border: none !important;
        padding: 10px 20px !important;
        font-weight: 600 !important;
        cursor: pointer !important;
      }
      .cancel-btn {
        background: none;
        border: none;
        width: 100%;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
      }
      .buy-credits-btn {
        min-width: 200px;
        width: 100%;
        margin-top: 10px;
        font-weight: 600 !important;
      } 
    }
  }

  .detail-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .detail-container {
      width: 60%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 20px;
      column-gap: 10px;

      .green-circle-checkmark {
        position: relative;
        width: 15px;
        min-width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #28CC91;
        margin-top: 5px;

        &::before {
          content: "\2713";
          position: absolute;
          top: 49%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 7px;
          font-weight: bold;
        }
      }

      .detail {
        display: flex;
        flex-direction: row;
        // align-items: center;
        flex-basis: 45%;
        margin-top: 10px;

        span {
          margin-left: 5px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.02em;
          
          /* #5D6473 */
          
          color: #5D6473;
        }
      }

      .row {
        display: flex;
        flex-direction: row;
        gap: 50px;
        margin-top: 10px;
      }
    }

    .credit-info-container {
      background-color: #E9F0F3;
      border: 1px solid #c6ccce;
      padding: 14px;
      border-radius: 12px;
      height: fit-content;
      font-size: 14px;
    }
  }

  .divider {
    display: block; 
    height: 1px;
    border: 0; 
    border-top: 1px solid #dadada;
    margin: 1em 0; 
    padding: 0;
  }

  .payment-history-container {  
    margin-top: 20px;
    border-bottom: 1px solid #dadada;
    padding-bottom: 35px;
    .section-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      /* identical to box height, or 130% */


      color: #000000;
    }

    .table-wrap {
      margin-top: 20px;
      max-height: 500px;
      overflow: auto;
  
      table {
        border-collapse: separate;
        width: 100%;
      

        thead {
          position: sticky;
          top: 0;
          z-index: 1;
          background-color: white;
          text-align: center;

          th {
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #333333;
            background-color: #ffffff;
          }

          &::before {
            z-index: -1;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white; /* or any color that matches the background */
          }
        }
    
      
        th, td {
          padding: 15px;
          text-align: center;
          border-right: 1px solid #dadada;
        }
      
        td {
          font-size: 14px;
        }
      
        tr:first-child {
          border-top: 1px solid #dadada;
          border-bottom: 1px solid #dadada;
        }
      
        tr:nth-child(odd) {
          background-color: #fafafa;
        }
      
        tr:nth-child(even) {
          background-color: #f4f4f4;
        }
      
        th:last-child {
          border-right: none !important;
        }
      
        td:last-child {
          border-right: none !important;
        }
        
        .ellipsis-column {
          max-width: 200px;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
    
  }

  .credit-card-container {
    padding: 20px 0;

    .card-details-container {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .card-details {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;

        color: #333333;
        
        .card-nu {
          margin-top: 20px;
          padding-left: 30px;
        }

        .bank {
          display: flex;
          align-items: center;
        }
      }

      .edit-btn {
        cursor: pointer;
        background-color: #EF4834;
        border-radius: 4px;
        border: none;
        color: white;
        font-weight: 600;
        padding: 7px 35px;
      }
    }
  }

  .edit-card {
    width: 70%;

    .card-cont {
      border: none;
    }
  }

  .cancel-txt {
    font-size: 14px;
    color: #5a5a5a;
  }

  .modal-overlay {
    // margin-top: -10vh;
    // margin-left: -20vw;
  }

  .toaster-container {
    // margin-left: -20vw;
    // margin-top: -10vh;
  }
}
