.error-page {
  width: 460px;
  .error-page-container {
    .sigh-container {
      width: 110px;
      height: 101px;
      background: url($assetUrl + 'icons/oval-bg.svg') center no-repeat;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon.sigh {
        width: 65px;
        height: 65px;
        background: url($assetUrl + 'icons/sigh.svg') center no-repeat;
        background-size: contain;
      }
    }
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .error-page-head {
      margin: 20px auto;
      max-width: 300px;
    }
  }
}

.gradient.red {
  .modal-overlay {
    background: rgb(251,163,79);
    background: linear-gradient(135deg, rgba(251,163,79,1) 0%, rgba(246,46,52,1) 50%, rgba(201,53,97,1) 100%);
  }
}