.video-with-bg {
  video {
    border-radius: 6px;
  }
}

#projects-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  .data-grid-cell {
    font-size: 16px;
  }
  .cell-container {
    text-align: center;
  }
  .data-grid-container {
    flex: 1;
  }
  .data-grid-head-container {
    .data-grid {
      .data-grid-cell {
        font-weight: bold;
      }
    }
  }
}

.project-view {
  .modal-background {
    .modal-foreground {
      height: 100%;
      video {
        max-height: calc(100vh - 60px);
      }
    }
  }
  #campaign-list {
    max-height: 400px;
    overflow: auto;
  }
  #campaign-list.ellipse {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 50px;
  }
  .more-btn {
    all: unset;
    display: block;
    cursor: pointer;
    margin-top: 10px;
    color: $darkestGrey;
    font-weight: 400;
    font-size: 14px;
  }
}

.projects, .campaigns {
  .duplicate-icon {
    background-image: url($assetUrl + 'duplicate-black.svg') !important;
    background-repeat: no-repeat !important;
    padding: 0 30px 0 54px !important;
    background-position: 25px center !important;
    background-size: 20px auto !important;
    &:disabled {
      background-image: url($assetUrl + 'duplicate-grey.svg') !important;
      cursor: default;
    }
  }

  .duplicate {
    img {
      height: 15px;
      margin-right: 10px;
    }

    img.disabled {
      opacity: 0.3;
    }
  }

  .upload-progress {
    padding: 10px 0 2px 0;
    .upload-progress__text {
      text-align: right;
      color: #777;
      font-size: 13px;
      margin-bottom: 5px;
    }
  }

  .create-project {
    width: 300px;
  }
}

.projects-container {
  margin-top: 52px;
  thead {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        max-width: 412.6px;
      }
      div.tooltip {
        .tooltip-title {
          max-width: 400px;
          white-space: normal;
          height: auto;
          line-height: 1.35;
          padding: 5px 7px;
          bottom: unset;
          top: 30px;
        }
        div {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

//overrides

.inner-page .view-project-page {
  display: flex;

  .creatives-nav {
    display: flex;
    height: 54px;
    // min-width: 1260px;

    .search-bar-grey {
      display: flex;

      input {
        width: 368px;
        border: 2px solid transparent;
        padding: 8px 12px;
        transition: border 0.2s;
        border-radius: 100px;
        background-color: rgba(181, 203, 215, 0.3);
        outline: none;

        &:focus {
          border: 2px solid $primaryColor;
          background-color: $white;
        }
      }

      .search-btn {
        width: 36px;
        position: absolute;
        border: none;
        background-color: transparent;
        margin: 23px 327px;
        cursor: pointer;
        border-radius: 20px;
        justify-content: inherit;

        &:hover {
          background-color: rgba(181, 203, 215, 0.2);
        }
      }
    }

    .trash-button {
      background-color: rgb(239, 72, 52);
      color: $white;
      border: none;
      padding: 5px;
      border-radius: 3px;
      margin: 3px 20px 0 10px;
      cursor: pointer;
    }

    .creatives-nav-left {
      flex: 3;
    }
    .creatives-nav-right .custom-select {
      .dropdown-button {
         width: 200px;
         display: inline-block;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }
      .dropdown-content {
        min-width: 250px;
      }
    }
  }

  .break-word {
    h1 {
      min-width: 178px;
    }

    .library-sub-heading {
      color: #acacac;
      font-size: 1rem;
      margin-top: 1.6875rem;
      font-weight: bold;
    }
  }

  .white-tabs button {
    // background-color: $white;
    cursor: pointer;
    font-weight: bold;
    min-height: 48px;
    min-width: 160px;
  }
  .project-heading {
    max-width: 375px;
    max-height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.project-creatives-download {
  .compound-dropdown {
    width: 250px;
    .btn {
      width: 214px;
    }
    .dropdown.custom-select .dropdown-content {
      width: 250px;
    }
  }
}

.view-project-main {
  .heading3-alt {
    color: #acacac;
  }
  .modal-foreground video {
      margin: auto;
  }

  .notification {
    &.warning {
      font-size: 0.875rem;
      margin: 1rem;
      font-weight: 500;
      border-radius: 4px;
      max-width: 176px;
    }
  }
}

.tab {
  &.true {
    background-color: $white;
  }
}

.media-selector-container {
  flex-wrap: wrap;
  flex-direction: column;

  .toaster-container {
    top: 75px !important;

    .close {
      top: 10px !important;
      right: 10px !important;
    }
  }
}

.editable-project-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  input {
    border-radius: 4px;
    border: 1px solid #e9f0f3;
    padding: 10px;
    
    &:focus {
      outline: none;
    }
  }

  .edit-btn {
    cursor: pointer;
    width: 15px;
    height: 15px;
    background-size: 15px !important;
    background: url('https://media.useblitz.com/assets/images/icons/edit-icon-new.svg');
    background-repeat: no-repeat;
  }

  .save-btn {
    cursor: pointer;
    width: 15px;
    height: 15px;
    background-size: 15px !important;
    background-repeat: no-repeat;
    background: url('https://media.useblitz.com/assets/images/icons/edit-icon-new.svg');
  }

  .loader-container {
    width: 50px;
  }
}
.project-platforms {
  .ai-ad-preview {
    .dropdown-button {
      width: 240px !important;
    }
    width: 240px;
  }

}