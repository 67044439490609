.loader-image {
  background: url($assetBaseUrl + 'Loader-01.svg') center no-repeat;
  width: 120px;
  height: 120px;
}

.page-loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: +1;
  background: rgba(255, 255, 255, 0.2);

  .loader-overlay {
    display: flex;
    align-items: center;
  }
}

//overrides
.blitz-premium-page {
  .loader-overlay {
    background: none;
    .loader-image {
      margin-top: 50%;
    }
  }
}

.loader-overlay {
  &.ai-ads-render-image {
    position: absolute;
  }
}

.inline-loader {
  &.gray-loader {
    height: 8px;
    border-radius: 4px;
    background: $landingFormBorderColor;
    .inline-loader-value {
      background: $uploadTextColor;
    }
  }
}

.library-index-page {
  .loader-overlay {
    z-index: 99999;
  }
}
