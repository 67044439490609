.sortable-container {
  background: $tableBorderColor;
  display: block;
  .selected {
    font-size: 10px;
    font-weight: 700;
    .number {
      color: $primaryColor;
    }
  }
  .sortable {
    display: block;
    cursor: move;
    margin: 4px 3px;
    background: $white;
    height: 36px;
    line-height: 36px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: 1px solid $white;
    padding: 0 6px 0 30px;
    text-overflow: ellipsis;
    font-size: 16px;
    overflow: hidden;
    position: relative;
    &.immovable {
      color: #777777;
    }
    &:not(.immovable) {
      &:before {
        position: absolute;
        top: 13px;
        left: 7px;
        content: '';
        border-top: 2px solid $tableBorderColor;
        border-bottom: 2px solid $tableBorderColor;
        height: 3px;
        width: 15px;
      }
    }

    .close {
      position: absolute;
      top: -1px;
      right: -1px;
      color: $lightBlue;
      background: $white;
      font-size: 20px;
      display: none;
    }
    &:hover {
      .close {
        display: block;
      }
    }

    &.sortable-ghost {
      &:before {
        border-color: $darkGrey;
      }
      border: 1px dashed #cccccc;
      background: $lightOrange;
      box-shadow: 0 0 0 0 transparent;
    }
  }
}
