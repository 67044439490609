.cards {
  display: flex;
  .plan-card {
    width: 100%;
    border: 2px solid #bababa;
    border-radius: 10px;
    text-align: center;
    // cursor: pointer;
    .head {
      margin-top: 20px;
      color: $greyText;
      font-weight: bold;
      font-size: 16px;
      text-transform: capitalize;
    }
    .price {
      margin-top: 20px;
      font-size: 24px;
      font-weight: bold;
      color: $black;
    }
    .sub-head {
      margin-top: 10px;
      color: $greyText;
      font-size: 14px;
    }
    &:first-child {
      margin-right: 15px;
      height: 136px;
    }
    &.active {
      border-color: $primaryColor;
      .price {
        color: $primaryColor;
      }
    }
  }
}

.subscription-cards {
  display: flex;
  gap: 10px;
  .card {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 24px 0px;
    box-shadow: none;
    transition: border 0.3s;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 1rem;
    justify-content: center;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
  }
}

.card-update-footer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.card-msg-row {
  .summary-text {
    margin-top: 20px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #5D6473;
  }
}