.section-GetCreative,
.section-TechToScale {
  position: relative;
}

.video-with-bg {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon.play,
  .icon.pause {
    position: absolute;
    z-index: 10;
  }
}

.video-with-bg video:not(.bg-video) {
  max-height: 100%;
  max-width: 100%;
  // position: relative;
  z-index: 8;
  filter: brightness(0.6);
}

.video-with-bg.playing video:not(.bg-video) {
  max-height: 100%;
  position: relative;
  z-index: 8;
  filter: brightness(1);
}

.video-with-bg .bg-video {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  filter: blur(15px) brightness(0.5);
}

.video-with-bg {
  background-color: #592952;
  left: 0;
}

.video-with-bg.theme-white svg {
  width: 38px;
  height: 45px;
}

div.video-with-bg.theme-white-alt svg {
  width: 44px;
  height: 44px;
}

.video-with-bg.theme-white-alt svg .cls-1 {
  fill: #fff;
}

.video-with-bg.theme-white-alt svg .cls-2 {
  fill: #000;
}

.video-with-bg.theme-white.playing svg {
  width: 30px;
  height: 30px;
}

.video-with-bg.theme-white .MuiIconButton-root {
  width: auto;
  height: auto;
}

.modal {
  max-width: 500px;
  width: 95%;
  margin: auto;
}

.auth-footer {
  padding: 1rem 0 0;
  margin-top: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.auth-footer::before {
  content: ' ';
  display: block;
  width: calc(100% + 64px);
  height: 1px;
  background: rgba(181, 203, 215, 0.3);
  position: absolute;
  top: 0;
  left: -32px;
  z-index: 9;
}

.auth-footer p {
  float: left;
  margin: 0 1rem 0 0;
  font-weight: bold;
}

.auth-footer button {
  float: right;
}

.forgot-password-msg {
  text-align: center;
  padding: 0 30px;
}

div.form-control {
  margin-bottom: 1rem;
}

.form-control label {
  font-weight: bold;
}

.form-control input,
.form-control label {
  font-family: 'Libre Franklin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.section-Hero {
  padding: 7rem 0 7rem;
  position: relative;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 123px;
    margin-bottom: 75px;
    background-image: url('https://media.useblitz.com/static/blitz/img/bg-dot-tile-home.svg');
    z-index: -1;
  }

  h3 {
    color: #fff;
  }

  &.agency {
    padding-top: 6rem;
    padding-bottom: 0;

    &::before {
      background-image: none;
    }
  }

  .creative-hero {
    background: url($assetUrl + 'creative/hero-image-bg.svg') center no-repeat;
    width: 573.3px;
    height: 475.2px;
    background-size: contain;
    position: relative;

    .video-box {
      position: absolute;
      width: 239px;
      top: 229px;
      right: 20px;
      left: auto;
      height: auto;
    }
  }
}

.auth-modal {

  .contact-page-section h2.h1 {
    line-height: 33px;
    margin: 2rem 2rem 1rem;
    font-size: 28px !important;
  }

  h3 {
    margin: 2rem auto 1rem !important
  }

  input {
    height: 35px !important;
    color: $black  !important;
    padding: 0px !important;
    font-size: 15px;
  }

  textarea {
    height: 50px;
  }

  .disclaimer {
    display: none;
  }

  .contact-page-section {
    min-height: auto;
  }
}

.login-popup {
  h3 {
    margin: 2rem 1rem !important
  }

  input {
    background: #e9f0f3;
    height: 40px !important;
    padding: 9px !important;
    font-size: 16px;

    &::placeholder {
      color: $black;
    }
  }

  .auth-modal-close {
    svg {
      path {
        color: white;
      }
    }
  }
}

button.auth-modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
}

.section-ContactUs {
  background-color: #f88240;
  text-align: center;
  padding: 5rem 0;
}

.section-ContactUs label,
.section-ContactUs label.Mui-focused {
  color: #000;
}

.section-ContactUs .form-control {
  background-color: rgba(255, 255, 255, 0.2);
}

.section-ContactUs button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.studio-page .contact-us-form input,
.studio-page .contact-us-form textarea,
.home-page .contact-us-form input,
.home-page .contact-us-form textarea {
  color: #fff;
}

.section-GetCreative-text,
.section-TechToScale-text {
  justify-content: center;
}

.section-GetCreative-text>div,
.section-TechToScale-text>div {
  background-color: rgb(255, 255, 255);
  padding: 3rem 5rem 3rem 8rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 7px 12px;
}

.section-GetCreative-text.left-div>div,
.section-TechToScale-text.left-div>div {
  padding: 3rem 8rem 3rem 5rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.disclaimer {
  margin-top: 1rem;
  max-width: 400px;
  margin: auto;
  line-height: 1.2;
}

.disclaimer a {
  font-weight: bold;
}

.disclaimer.dark,
.disclaimer.dark a {
  color: rgba(255, 255, 255, 0.8);
}

.grecaptcha-badge {
  visibility: hidden;
}

.contact-us-form {
  text-align: left;
}

.contact-us-form label {
  display: inline-block;
  margin: 5px 0;
  color: #000;
  font-weight: bold;
}

.contact-us-form input,
.contact-us-form textarea {
  padding: 10px 4px;
  color: #000;
}

@media (max-width: 599px) {
  .auth-modal {
    z-index: 2150000000 !important;
  }

  .section-Hero {
    padding: 5rem 0 2rem;
  }

  .section-Hero img {
    max-width: 100%;
    height: auto;
  }

  .auth-footer {
    flex-direction: column;
  }
}

@media (max-width: 959px) {

  h1,
  .h1 {
    font-size: 35px;
    font-weight: 900;
  }

  .section-Hero {
    text-align: center;

    .video-box {
      width: 100%;

      .video-with-bg {
        // height: auto !important;
        width: 100% !important;

        video {
          width: 256px;
          left: 12%;

          @include width700 {
            width: 39%;
          }
        }

        button {
          left: 62%;
        }
      }
    }
  }

  .studio-page {
    .section-Hero {
      .section-Hero-text {
        order: 2;
        width: 90%;
        max-width: 90%;
        margin-bottom: 0;
      }

      .section-Hero-video {
        order: 1;

        svg {
          height: 350px !important;
        }
      }

      text-align: unset;

      h1 {
        font-size: 40px;
        font-weight: normal;

        strong {
          font-weight: 900;
        }

        div {
          margin-left: 90px;
        }
      }

      .sub-title {
        font-size: 18px;
        font-weight: bold;
      }

      button.no-shadow {
        width: 100%;
        border-radius: 24px;
      }

      .customers-2 {
        width: 90%;
        margin-bottom: 0;
      }

      &::before {
        display: none;
      }
    }

    .slope-hill-bg {
      display: block;
      margin: 0 auto 100px auto;

      .MuiContainer-maxWidthLg {
        padding: 0;
      }

      .slope-hill {
        .text {
          text-align: unset;
          font-weight: normal;

          strong {
            font-weight: 900;
          }
        }
      }

      .steps {
        padding: 0 24px;

        .step {
          margin: 40px 0;
        }
      }
    }

    .how-it-works {
      flex-direction: row;
      justify-content: space-evenly;

      img {
        width: unset !important;
      }

      h2 {
        font-size: 28px !important;
      }
    }

    .marketing-partners {
      img {
        display: none;
      }
    }

    .section-GetCreative,
    .section-GetCreative,
    .section-TechToScale {

      .section-GetCreative-text,
      .section-GetCreative-text,
      .section-TechToScale-text {
        div {
          .h1 {
            font-size: 23px;
          }
        }
      }
    }

    .section-TechToScale-last {
      .section-TechToScale-text {
        margin-bottom: 0;
      }
    }
  }

  .section-Hero .btn {
    margin-bottom: 2rem;
  }

  .section-Hero-text,
  div.section-Hero-img {
    justify-content: center;
  }


  .section-GetCreative .video-with-bg,
  .section-TechToScale .video-with-bg {
    height: 350px;
  }

  .video-with-bg .bg-video {
    min-height: 40vh;
    width: 100%;
  }

  div.section-Hero-text,
  div.section-GetCreative-text,
  div.section-TechToScale-text {
    margin-bottom: 4rem;
  }

  .section-GetCreative-text>div,
  .section-TechToScale-text>div,
  .section-GetCreative-text.left-div>div,
  .section-TechToScale-text.left-div>div {
    padding: 1rem;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .section-TechToScale>div>div {
    flex-direction: column-reverse;
  }

  .agency {
    .section-Hero-text {
      order: 2;
      display: block;
      // flex-direction: column;
      // align-items: flex-start;
      // justify-content: center;
      text-align: center;

      h1,
      p {
        margin-bottom: 30px;
      }

      .sub-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }

    .section-Hero-video {
      justify-content: center;
      order: 1;
    }
  }
}

@media (min-width: 600px) {
  .auth-modal h3 {
    margin: 2rem 0;
  }
}

@media (min-width: 960px) {

  .section-Hero-text,
  .section-GetCreative-text,
  .section-TechToScale-text {
    min-height: 450px;
  }

  .section-Hero-img {
    padding-right: 5rem;
  }

  .agency {
    .section-Hero-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      h1,
      p {
        margin-bottom: 30px;
      }

      .sub-title {
        margin-bottom: 20px;
      }
    }
  }
}

@media (min-width: 1100px) {
  .section-Hero {
    h1 {
      font-size: 50px;
      line-height: 56px;
      margin-bottom: 0;
      // letter-spacing: 1.43px;
    }

    .sub-title {
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;
    }

    p {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .studio-page {
    .section-Hero {
      h1 {
        font-size: 70px;
        font-weight: normal;
        margin-bottom: 25px;

        strong {
          font-weight: 900;
        }

        div {
          margin-left: 140px;
          margin-top: 10px;
        }
      }

      &::before {
        bottom: -40px;
      }

      margin-bottom: 130px;
    }

    .sub-title {
      font-size: 26px;
    }

    button.no-shadow {
      width: 225px;
      border-radius: 24px;
    }

    .customers {
      margin: 30px auto 30px auto;
    }

    .steps {
      .step {
        p {
          font-size: 18px;
        }
      }
    }

    .slope-hill-bg {
      margin-bottom: 154px;

      .slope-hill {
        .text {
          font-size: 48px;
          font-weight: normal;
          top: 135px;
          left: -20px;

          strong {
            font-weight: 900;
          }
        }
      }
    }

    .customers-2 {
      width: 86%;
    }

    .marketing-partners {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 50px;
      }
    }

    .section-GetCreative,
    .section-TechToScale,
    .section-GetCreative,
    .section-TechToScale {
      .video-box {
        width: 100%;
        height: 377px;
        min-height: 377px;
        max-height: 377px;
      }

      .section-GetCreative-text,
      .section-GetCreative-text,
      .section-TechToScale-text,
      .section-TechToScale-text {
        div {
          padding: 1rem 4rem 2rem 4rem;

          .h1 {
            font-size: 26px;
          }
        }
      }
    }
  }

  .section-ContactUs {
    h2 {
      font-size: 50px !important;
      line-height: 52px;
      margin-bottom: 70px;
    }
  }
}

.twin-cols {
  .row {
    .col {
      display: inline-block;
      width: 50%;

      &.full-width {
        width: 100%;
      }

      &:last-child {
        padding-right: 2rem;
      }

      &:first-child {
        padding-right: 2rem;
      }
    }
  }
}

.slope-hill-bg-mobile {
  display: none;
}

.slope-hill-bg {
  // display: flex;
  margin: -91px auto 50px auto;
  height: 393px;
  background: linear-gradient(90deg,
      rgba(181, 203, 215, 1) 0%,
      rgba(181, 203, 215, 1) 33%,
      transparent 33%,
      transparent 100%);

  @include width2700 {
    background: linear-gradient(90deg,
        rgba(181, 203, 215, 1) 0%,
        rgba(181, 203, 215, 1) 30%,
        transparent 30%,
        transparent 100%);
  }

  @include width2500 {
    background: linear-gradient(90deg,
        rgba(181, 203, 215, 1) 0%,
        rgba(181, 203, 215, 1) 28%,
        transparent 28%,
        transparent 100%);
  }

  @include width2300 {
    background: linear-gradient(90deg,
        rgba(181, 203, 215, 1) 0%,
        rgba(181, 203, 215, 1) 25%,
        transparent 25%,
        transparent 100%);
  }

  @include width1600 {
    background: linear-gradient(90deg,
        rgba(181, 203, 215, 1) 0%,
        rgba(181, 203, 215, 1) 15%,
        transparent 15%,
        transparent 100%);
  }

  @include width968 {
    background: linear-gradient(90deg,
        rgba(181, 203, 215, 1) 0%,
        rgba(181, 203, 215, 1) 30px,
        transparent 30px,
        transparent 100%);
  }

  @include width900 {
    background: transparent;
    height: auto;
  }

  .hill-items {
    display: flex;

    @include width900 {
      display: block;
    }
  }

  .slope-hill {
    display: flex;
    height: 393px;
    position: relative;

    @include width900 {
      justify-content: flex-start;
      align-items: center;
      height: 200px;
      background-image: url("https://media.useblitz.com/assets/images/slant-bg-shape.svg");
      background-size: 90% 100%;
      background-repeat: no-repeat;

      &::before {
        content: ' ';
        display: block;
        bottom: 530px;
        top: 100px;
        left: 0;
        width: 100%;
        position: absolute;
        height: 123px;
        margin-bottom: 75px;
        background-image: url('https://media.useblitz.com/static/blitz/img/bg-dot-tile-home.svg');
        z-index: -1;
      }
    }

    .rectangle {
      width: 315px;
      height: 100%;
      background: $lightBlue;
      border-radius: 0 100px 0 0;

      @include width1160 {
        width: 240px;
      }

      @include width900 {
        display: none;
      }
    }

    .right {
      @include width900 {
        display: none;
      }

      .triangle {
        border-top: 170px solid transparent;
        border-right: 170px solid transparent;
        border-bottom: 170px solid $lightBlue;
        border-left: 170px solid $lightBlue;
        margin-top: 24px;
        margin-left: -35px;
      }

      .cylinder {
        width: 305px;
        height: 40px;
        background: $lightBlue;
        border-radius: 0 27px 30px 0;
        margin-top: -11px;
      }
    }

    .text {
      position: absolute;
      left: 0px;
      top: 155px;
      color: $white;
      font-size: 35px;
      font-weight: 900;
      line-height: 45px;

      @include width1160 {
        font-size: 28px;
        line-height: 38px;
      }

      @include width900 {
        position: static;
        left: auto;
        top: auto;
        text-align: center;
        padding-left: 25px;
      }
    }
  }
}

.steps {
  z-index: +1;
  margin-top: 32px;

  @include width1000 {
    margin-top: 22px;
    margin-left: -20px;
  }

  .step {
    display: flex;
    margin-bottom: 35px;

    @include width1160 {
      margin-bottom: 25px;
    }

    &.one {
      margin-left: -330px
    }

    &.two {
      margin-left: -210px
    }

    &.three {
      margin-left: -90px
    }

    .icon {
      order: 1;
      width: 85px;
      height: 85px;
      padding: 20px;
      border: solid 3px $iceBlue;
      margin: auto 2rem auto 0;
      display: flex;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 45px;
      align-items: center;
      justify-content: center;
      background-color: $white;
    }

    p {
      margin: auto 0;
      order: 2;
      font-size: 16px;
      max-width: 500px;
    }
  }
}

.customers {
  background: url($assetUrl + 'brands-new.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 120px;
  background-position: center;
  background-size: contain;
  margin: 30px auto 100px auto;
}

.customers-2 {
  background: url($assetUrl + 'brands-2-new@3x.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 120px;
  background-position: center;
  background-size: contain;
  margin: auto;

  @include width700 {
    background: url($assetUrl + 'brands-2-mobile-new@3x.png') center no-repeat;
    background-size: contain;
    height: 150px;
    margin-bottom: 50px;
  }
}

.marketing-partners {
  padding: 20px;
  font-size: 23px;
  text-align: center;
  letter-spacing: 2px;
  color: $partnersBlack;
  border: 2px solid $iceBlue;
  background: $tableRowColorOdd;

  .fb-brand {
    font-weight: 700;
    color: $fbBrandGrey;
  }

  @include width460 {
    font-size: 17px;
  }
}

@include width900 {
  .steps {
    margin: 20px auto;

    .step {
      margin: 30px;

      &.one {
        margin-left: auto;
      }

      &.two {
        margin-left: auto;
      }

      &.three {
        margin-left: auto;
      }
    }
  }
}

.agency-schedule-cta {
  font-weight: 900 !important;
  border-radius: 30px !important;
  padding-left: 45px !important;
  padding-right: 45px !important;

  @include width460 {
    width: 100%;
  }

  @include width400 {
    width: 100%;
    font-size: 15px !important;
  }
}

.home-page {
  .contact-page-section {
    padding-top: 2rem !important;

    h2.h1 {
      font-size: 50px !important;

      @include width900 {
        font-size: 32px !important;
      }
    }
  }
}

@include width700 {
  .video-box {
    display: flex;
    align-items: center;
  }

}


// home overrides
.section-Hero {
  .section-hero-grid, .agency-section {
    justify-content: center;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
  }

  .section-Hero-text {
    max-width: 50%;
    margin-top: 20px;
    width: 616px;
  }

  .section-Hero-video {
    width: 616px;
    display: flex;
    flex-wrap: wrap;

    .svg-video {
      max-width: 100%;
      max-height: 100%;
    }

    .video-box {
      .video-with-bg {
        left: 250px;
        width: 257px;
      }
    }
  }
}

.agency {
  .customers-2 {
    max-width: 1232px;
  }
.agency-section {
  .section-Hero-video {
    width: 616px;
    display: flex;
    flex-wrap: wrap;

    .svg-video {
      max-width: 100%;
      max-height: 100%;
    }

    .video-box {
      .video-with-bg {
        left: 250px;
        width: 257px;
      }
    }
  }


}
}
.page {
  .background-image-box::before {
    top: 0;
    left: 50%;
    width: 525px;
    height: 100%;
    content: " ";
    display: block;
    position: absolute;
    max-width: 100%;
    transform: translateX(-50%);
    background-size: 218px;
    background-image: url(https://media.useblitz.com/static/blitz/img/bg-dot-tile-home.svg);  }
}

.slope-hill-bg {
  main {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.section-how-it-works {
  position: relative;
  box-shadow: inset 0 5px 7px 1px rgb(0 0 0 / 10%);
  padding-bottom: 4rem;
  background-color: #e9f0f3;
}

.overlapping-heading {
  width: 525px;
  border: solid 3px #e9f0f3;
  height: 103px;
  margin: 0 auto;
  display: flex;
  max-width: 100%;
  transform: translateY(-50%);
  box-shadow: 0 7px 12px 0 rgb(0 0 0 / 25%);
  align-items: center;
  border-radius: 20px;
  justify-content: space-around;
  background-color: #fff;
}

.section-GetCreative {
  main {
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;

    .section-GetCreative-grid {
      justify-content: center;
      align-items: center;
      display: flex;
      box-sizing: border-box;
      width: 100%;
    }
    .section-GetCreative-text>div {
      background-color: #fff;
      padding: 3rem 5rem 3rem 8rem;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      margin-top: 80px;
    }
    .h3 {
      font-size: 1.125rem;
      margin: 1rem 0 0.5rem;
      font-weight: 700;
    }
    .home-page-video-right {
      overflow: hidden;
      box-shadow: 0 7px 12px 0 rgb(0 0 0 / 50%);
      border-radius: 20px;
    }
    .video-with-bg {
      width: 100%;
    }
  }
}

.section-TechToScale {
  main {
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;

    .section-TechToScale-grid {
      justify-content: center;
      align-items: center;
      display: flex;
      box-sizing: border-box;
      width: 100%;
    }
    .section-TechToScale-text>div {
      background-color: #fff;
      padding: 3rem 5rem 3rem 8rem;
      margin-top: 80px
    }
    .h3 {
      font-size: 1.125rem;
      margin: 1rem 0 0.5rem;
      font-weight: 700;
    }
    .home-page-video-right {
      overflow: hidden;
      box-shadow: 0 7px 12px 0 rgb(0 0 0 / 50%);
      border-radius: 20px;
    }
    .video-with-bg {
      width: 100%;
    }
  }
}

@media (max-width: 959px) {
  .studio-page .section-Hero .section-Hero-video {
    width: 100%;
  }
  .slope-hill-bg 
  main {
    padding: 0;
    .slope-hill .text {
      font-size: 25px;
    }
  }
  .studio-page {
    width: 100%;
  }
  .section-Hero .section-Hero-video .video-box .video-with-bg {
  left: 0;
  }
  .section-Hero .video-box .video-with-bg button {
    left: 10%;
  }
  .studio-page .section-Hero {
    text-align: center;
  }
  .studio-page .how-it-works {
    width: 90%;
  }
  .section-GetCreative main .section-GetCreative-grid {
    display: block;
  }
  .section-TechToScale main .section-TechToScale-grid {
    flex-direction: column-reverse;
  }
  .section-GetCreative main .section-GetCreative-text > div,
  .section-TechToScale main .section-TechToScale-text > div {
    margin-top: 0;
    padding: 1rem;
    border-radius: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .agency-section {
    .section-Hero-video {
      width: 305px !important;
  
      .video-box .video-with-bg {
        left: 35px !important;
      }
    }
    .section-Hero-text {
        max-width: 100%;
        padding: 20px;
    }
  }
}


@media (min-width: 600px) {

  .section-GetCreative,
  .section-TechToScale {
    main {
      padding-left: 24px;
    }
  }

  .agency {
    .section-Hero-text {
      display: block;
      margin-top: 90px;
    }

    .agency-section .agency-schedule-cta {
      height: 60px;
      font-size: 15px;
    }
  }
}

@media (min-width: 960px) {
  .section-GetCreative-grid-video, .section-TechToScale-grid-video,
  .section-GetCreative-text, .section-TechToScale-text {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
  }
  .section-Hero .section-hero-grid {
    flex-wrap: wrap;
  }
}
@media (max-width: 700px) {
  .section-GetCreative main .home-page-video-right,
  .section-TechToScale main .home-page-video-right {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .section-GetCreative-grid-video .video-box, .section-GetCreative-text > div,
  .section-TechToScale-grid-video .video-box, .section-TechToScale-text > div {
    width: auto;
  }
}

@media (min-width: 1100px) {
    .section-GetCreative-text div .h1 {
      font-size: 26px;
    }
}

@media (min-width: 1280px) {
  .section-GetCreative, .section-TechToScale {
    main {
      max-width: 1280px;
    }
  }
  .agency-section .agency-schedule-cta {
    height: 48px;
    font-size: 1.125rem;
  }
}

//overrides
.page {
  &.terms-page, &.privacy-page {
    .terms-cont-main, .privacy-cont-main {
      padding: 40px;
    }
  }
}


.reset-pw-container {
  background-image: linear-gradient(to right bottom, #fea450, #f88751, #ee6b55, #de505c, #c93763);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  .card-container {
    .logo {
      background: url(https://media.useblitz.com/assets/images/icons/blitz-smart-orange-logo.svg) 50% no-repeat;
      background-size: contain;
      height: 60px;
    }

    h1 {
      margin-top: 40px;
      font-family: 'Libre Franklin';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      text-align: center; 
      color: #333333;
    }

    h3 {
      font-family: 'Libre Franklin';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #7F7F7F;
    }

    label {
      font-family: 'Libre Franklin';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #ACACAC;
    }

    input {
      background: #E9F0F3;
      border-radius: 2px;
      border: none;
      width: 100%;
      padding: 10px 15px;
    }

    button {
      border-radius: 8px;
      padding: 20px !important;
      margin-top: 25px;
      width: 100%;
    }

    min-height: 60vh;
    min-width: 400px;
    width: 30vw;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.23);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    padding: 40px 60px;
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .reset-pw-container {
    .card-container {
      min-height: 60vh;
      min-width: 300px;
      width: 80vw;
      padding: 40px 30px;
    }
  }
}