@mixin lineBorderButton {
  font-size: 16px;
  background: $white;
  color: $primaryColor;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid $primaryColor;

  &:focus {
    outline: none;
  }
  &:active {
    color: $darkRed;
    border: 1px solid $darkRed;
  }

  &.grey {
    color: $graphite;
    background: $tableRowColorOdd;
    border: 1px solid $buttonGreyBorder;
    &:active {
      border: 1px solid $black;
    }
  }
}
.btn-line-border {
  @include lineBorderButton;
  &.large {
    height: 48px;
  }
}
.btn-line-thick-border {
  vertical-align: middle;
  border: 2px solid rgba(183, 186, 188, 0.36);
  background: transparent;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: #f0f0f0;
  }
  &:active {
    border: 2px solid rgba(183, 186, 188, 1);
  }
}

.btn-round {
  border-radius: 30px !important;
}
.btn-large {
  padding: 12px 50px !important;
}
.btn-facebook {
  padding: 10px 30px !important;
  background-color: $facebookBlue !important;
  color: $white !important;
  width: 230px;
}
.btn-snapchat {
  padding: 10px 30px !important;
  background-color: $snapchatYellow !important;
  color: $black !important;
  width: 230px;
}
.btn-tiktok {
  padding: 10px 30px !important;
  background-color: $black !important;
  color: $white !important;
  width: 230px;
}

.btn-success {
  color: #ffffff !important;
  background: #559613 !important;
}

.small-options {
  line-height: 15px;
  height: 15px;
  font-size: 10px;
  margin: auto 10px auto 0;
  color: $black;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  text-decoration: none;
}
.medium {
  font-size: 16px !important;
}

button.no-pad {
  padding: 0;
}

.buttons-lh36 {
  button, a {
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    &.square {
      width: 36px;
    }
    &.mr {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .cancel-button {
    width: 36px;
  }
}

.thick-outline {
  border-width: 2px !important;
}


.project-link {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.btn-export-to-google-sheets {
  background: url($assetUrl + 'export-button@2x.png') no-repeat;
  width: 236px;
  height: 36px;
  border: 0;
  cursor: pointer;
  &:active {
    filter: brightness(0.8);
  }
}

.exporting {
  height: 36px;
  cursor: pointer;
  .MuiButton-label {
    font-size: 16px;
  }
  &:active {
    filter: brightness(0.8);
  }
}

.project-btn {
  height: 36px;
  font-size: 16px !important;
}


.toolbar-btn {
  font-size: 16px;
}
@mixin buttonShadow {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

@mixin button($bgColor, $activeBgColor, $textColor) {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: $textColor;
  padding: 0 1rem;
  font-weight: bold;
  background: $bgColor;
  height: 36px;
  line-height: 36px;
  @include buttonShadow;
  transition: all ease-in 0.2s;
  text-decoration: none;
  white-space: nowrap;

  // caret
  &::after {
    transition: all ease-in 0.2s;
    border-color: $primaryColor transparent transparent transparent !important;
  }

  &:hover {
    background-color: $darkRed;
    color: $textColor;
    &::after {
      border-color: $activeBgColor transparent transparent transparent !important;
    }
  }
  &:active {
    background-color: $activeBgColor;
    filter: brightness(1.2);
    transition: unset;
  }
  &:disabled {
    background: $buttonDisabled !important;
    filter: brightness(1);
    cursor: default !important;
  }
  &.no-shadow {
    box-shadow: 0;
  }
  &.strong {
    font-weight: bold !important;
  }

}

.btn {
  @include button($primaryColor, $darkRed, $white);
  &.solid {
    background: $primaryColor !important;
    color: $white;
    &:hover {
      background-color: $darkRed !important;
      color: $white;
    }
  }
  &.ghost {
    color: $primaryColor;
    background: transparent;
    border: 0;
    box-shadow: none;
    &:hover {
      background-color: $primaryColor;
      color: $white;
      @include buttonShadow;
    }
  }

  &.grey {
    @include button($lightWhite, $lightButtonActive, $black);
    &:hover {
      background-color: $dirtyWhite;
    }
    &.ghost {
      color: $uploadTextColor;
      background: transparent;
      border: 0;
      box-shadow: none;
      &:hover {
        background-color: $dirtyWhite;
        color: $black;
        @include buttonShadow;
      }
    }
  }

  &.outlined {
    background-color: transparent !important;
    color: $primaryColor;
    border: 1px solid $primaryColor;
    box-shadow: unset;
    &:hover:enabled {
      color: $darkRed;
      border-color: $darkRed;
      background-color: transparent;
    }
    &.active:enabled {
      background-color: transparent;
    }

    &.grey {
      border: 1px solid $darkGrey;
      color: $darkGrey;
      &::after {
        border-color: $darkGrey !important;
      }
      &:hover:enabled {
        color: $darkGrey;
        border-color: $darkGrey;
        background-color: transparent;
      }
    }

    &.black {
      border: 1px solid rgba(0, 0, 0, 0.23);
      color: $black;
      background-repeat: no-repeat;
      &:hover:enabled {
        color: $black;
        border-color: $black;
      }
      &:active:enabled {
        background-color: $lightButtonActive !important;
      }
      &:disabled {
        color: $darkGrey;
        border-color: $darkGrey;
      }
    }
    &.project-duplicate {
      background-image: url($assetUrl + 'duplicate-black.svg') !important;
      &:disabled {
        background-image: url($assetUrl + 'duplicate-grey.svg') !important;
      }
    }
  }
  &.small {
    height: 30px;
    line-height: 30px;
    font-size: 12px ;
  }

  &.large {
    font-weight: 600 !important;
    height: 46px !important;
    line-height: 46px !important;
    padding: 0 40px;
  }

  &.plain {
    background: transparent;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.87);
    font-weight: normal;
    &:hover:enabled {
      background-color: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.87);
    }
    &:active:enabled {
      background-color: rgba(0, 0, 0, 0.09);
      color: rgba(0, 0, 0, 0.87);
    }
  }
  &.link {
    background: none;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.87);
    text-decoration: underline;
  }
}

.tabbed-buttons {
  display: flex;
  button {
    display: inline-block;
    background: transparent;
    font-size: 16px;
    font-weight: bold;
    color: $primaryColor;
    border: 1px solid $primaryColor;
    box-shadow: unset;
    height: 36px;
    padding: 0 10px;
    cursor: pointer;
    margin-left: -1px;
    &:first-child {
      margin-left: 0px;
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
    &.active {
      color: $white;
      background: $primaryColor;
    }
  }
}

ul.top-nav-links {
  display: flex;
  align-items: center;
  li {
    button, a {
      font-size: 18px;
      font-weight: bold;
      color: $black;
      border: 0;
      background: transparent;
      cursor: pointer;
      padding: 0 8px;
      transition: all 0.5s;
      &:hover {
        color: $primaryColor;
      }
      &.active {
        color: $primaryColor;
      }
    }
  }
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 25px;
  color: $white !important;
  background: $lightBlue !important;
  &:hover {
    color: $white !important;
  }
  .anonymous-avatar-btn {
    background-image: url(https://media.useblitz.com/assets/images/user.svg);
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
  }
}



//overrides
.marketplace-toolbar .btn {
 max-width: 220px;
 font-size: 1.75;
 cursor: pointer;
}

.cross-btn {
  margin: 0px 5px;
  border-radius: 10px;
  font-weight: bold;
  color: $white;
  // height: 20px;
  line-height: normal;
  width: 20px;
  border: none;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
  background: $lightBlue;
  cursor: pointer;
  padding: 0;

  &.color-btn {
    position: absolute;
    margin-left: 85px;
    margin-top: -10px;
  }
}


.inner-page .view-project-page .break-word .upload-assets-btn {
  color: $white  !important;
  border: none !important;

  &:hover {
    background-color: #a73224 !important;
  }
}

.upload-assets-btn {
  padding: 20px 1rem;
}

.form-control {
  &.text-switch {
    display: flex;
    p {
      margin-left: 10px;
    }
  }
}

.primary {
  color: $primaryColor;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

@mixin disguisedButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: $black;
  padding: 0 1rem;
  font-weight: bold;
  background: transparent;
  height: 36px;
  line-height: 36px;
  transition: all ease-in 0.2s;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  &:active {
    box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.2),
      0px 1px 2px 0px rgba(0, 0, 0, 0.14),
      0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  }
}
.disguised {
  @include disguisedButton();
}

.btn {
  &.v2 {
    @include button($primaryColor, $darkRed, $white);
    height: 50px;
    line-height: 50px;
    border-radius: 35px;
    font-size: 18px;
    padding: 0 30px;
    font-weight: 700;
    border: 2px solid $btnV2Border;
    box-shadow: none;
    color: $white !important;
    &.bright {
      border: 2px solid rgba(255, 255, 255, 0.8);
    }
    &.large {
      height: 72px;
      padding: 0 60px;
      line-height: 72px;
      font-weight: 700;
      letter-spacing: 0.02em;
    }
    &.medium {
      height: 54px;
      padding: 0 30px;
      line-height: 28px;
      font-weight: 600;
      letter-spacing: 0.02em;
    }
    &.small {
      height: 36px;
      padding: 0 20px;
      line-height: 36px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.02em;
    }
  }
}

// button v3
.btn {
  &.v3 {
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 18px;
    height: 50px;
    color: $white !important;
    padding: 0 40px;
    border-radius: 50px;
    box-shadow: 0px 10px 20px rgba(239, 72, 52, 0.25);
    &.large {
      font-size: 18px;
      height: 70px !important;
      line-height: 70px !important;
    }
  }
}

.orange-link {
  color: $primaryColor;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.medium {
    font-weight: 500;
    font-size: 20px !important;
    line-height: 28px;
    letter-spacing: -0.2px;
  }
  &.arrow-top-right {
    padding-right: 30px;
    background: url($assetUrl + 'icons/top-right-arrow.svg') right center no-repeat;
  }
}
