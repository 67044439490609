.pexels-modal {
  max-width: 1006px;
  .modal-content {
    padding: 20px;
    // min-height: 500px;
    max-height: calc(100vh - 300px);
  }
  .modal-head {
    padding: 0;
  }
  .pexels-header {
    .columns {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      height: auto;
      .pexels-column {
        &:last-child {
          margin-right: 40px;
        }
      }
    }
  }

  .pexels-column {
    &.brand-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      a, img {
        height: 36px;
        line-height: 36px;
      }
      .pexels-title {
        height: 36px;
        line-height: 36px;
      }
    }
  }

  .pexels-search-container {
    position: relative;
    display: flex;
    padding: 5px 20px;
    border-top: 2px solid $iceBlue;
    border-bottom: 2px solid $iceBlue;
    .search {
      width: calc(100% - 40px);
      border: none;
      font-size: 16px;
      padding: 11px 20px;
      background-color: $iceBlue;
      border-radius: 20px;
      &:focus {
        outline: none;
      }
    }
    .search-icon {
      border: 0;
      cursor: pointer;
      background: transparent;
    }
    .cancel-icon {
      position: absolute;
      right: 60px;
      top: 5px;
      border: 0;
      background: transparent;
      color: $darkGrey;
      width: 40px;
      height: 40px;
      font-size: 30px;
      cursor: pointer;
    }
  }
  .close-icon {
    position: absolute;
    right: 60px;
    top: 5px;
    border: 0;
    background: transparent;
    color: $darkGrey;
    width: 40px;
    height: 40px;
    font-size: 30px;
    cursor: pointer;
  }
  .expanded-view {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin-top: 61px;
  }
  .video-preview {
    height: calc(100vh - 61px);
    @include flex-aic-jcc;
  }
  .custom-checkbox-container {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .selected {
    border: 3px solid $primaryColor;
  }
  .my-masonry-grid_column {
    img {
      border-radius: 5px;
    }
  }
  .toaster-container {
    top: 100px;
    .close {
      top: 12px !important;
      right: 10px !important;
      color: $white !important;
    }
  }
}

