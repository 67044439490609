$primaryColor: #ef4834;  /* orangey-red */
$primaryColorWithAlpha: rgba(239, 72, 52, 0.8);
$darkRed: #a73224;
$darkGrey: #acacac;
$mediumGrey: #747582;
$darkestGrey: #515354;
$veryLightGrey: #e0e0e0;
$black: #000000;
$iceBlue: #e9f0f3;
$iceBlueActive: #d1e1e8;
$veryPaleBlue: #e6f2ff;
$iceBlueBorder: #d1d8da;
$darkBlue: #c8cdcf;
$bluishGrey: #87b3c6;
$iceBlueText: $bluishGrey;
$iceBlueActiveText: #6699af;
$white: #ffffff;
$dirtyWhite: #FAFAFA;
$graphite: #333333;
$lightWhite: #eeeeee;
$buttonDisabled: #cccccc;
$chipColor: $veryLightGrey;
$blogGrey: #908d8d;

/** table related variables **/
$wellColor: #f4f4f4;
$tableBorderColor: #e5e5e5;
$tableRowColorOdd: #fafafa;
$tableRowColorEven: #f4f4f4;

$facebookBlue: #4267b2;
$snapchatYellow: #fffc00;

$buttonGreyBorder: #b7babc;
$greyBorder: #B7C0CC;
$greyAlphaBorder: rgba(183, 192, 204, 0.4);
$lightOrange: #f9e4e1;

$lightBlue: #b5cbd7;
$lightBlueHover: #c0d5e0;
$listItemActive: $lightBlue;
$listItemBorder: #e8eff3;
$formErrorColor: #dcccd2;
$greyText: #bababa;
$assetBaseUrl: 'https://media.useblitz.com/';
$assetUrl: $assetBaseUrl + 'assets/images/';
$skyBlue: #4392f1;
$fbBrandGrey: #677889;
$partnersBlack: #1c2b33;
$green: #24a346;
$lightButtonActive: rgba(0, 0, 0, 0.04);
$aiAdColumnBorder: rgba(181, 203, 215, 0.3);
$uploadTextColor: #7F7F7F;
$dataGridColor: #1c1e21;
$dataGridLightColor: rgba(0, 0, 0, .5);
$aiDropdownLabel: #A6A6A6;
$fbBlue: #3b5998;

// new home/landing page
$circleColor: #E2E8F0;
$btnV2Border: #292C4B;
$h1GreyColor: #282C4B;
$topLinkColor: $h1GreyColor;
$topFooterBg: $topLinkColor;
$carouselTextGrey: #5D6473;
$linkGrey: #5D6473;
$detailsGrey: #666666;
$sectionGrey: #FAF9FA;
$dirtyBlack: #1A1C31;
$lighterOrange: #FEADA3;
$landingFormBorderColor: #D9D9D9;
$peach: #FFF5F3;
$faqColor: #101D34;
$h3HeadBorder: #E9EFF3;
