.contact-page-section {
  min-height: 70vh;
  h2.h1 {
    font-size: 35px !important;
    line-height: 52px;
  }
  .contact-us-form {
    .row {
      .col {
        label {
          font-weight: normal;
        }

        .custom-select {
          button {
            margin: auto;
          }
        }

        .MuiFormControl-root {
          border-radius: 3px;
        }

        input[type="text"], input[type="email"] {
          color: $white;
          padding: 11px 10px;
          border-radius: 4px;
        }
      }
    }
  }

  .contact-success {
    background: url($assetUrl + 'circular-tick.svg') top center no-repeat;
    padding-top: 100px;
    font-size: 20px;
    line-height: 36px;
    margin-top: 150px;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
  }

  /** mobile compatibility starts here **/
  @include width600 {
    .row {
      .col {
        display: block;
        width: 100%;
        margin: auto 20px;
      }
    }

    .submit-button {
      margin: 20px;
      button  {
        margin: auto;
        width: 100% !important;
      }
    }
  }
}

//overrides

.contact-page-section {
  min-height: max-content;

  main {
    margin-left: auto;
    margin-right: auto;

    .form-control {
      border-radius: 4px;
      margin-top: 0;
    }

    .contact-us-form .row .col {
      .contact-input {
        background-color: transparent;
        border: 2px solid transparent;
        outline: none;
        padding: 10px 4px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);

        &:hover {
          border-bottom: 2px solid $black;
        }

        &:focus {
          border-bottom: 2px solid $primaryColor;
        }

        &.contact-message-input {
          min-height: 80px;
        }
      }

      .campaign-input-error {
        &:focus {
          background-color: rgba(255, 0, 0, 0.25);
          border: 2px solid transparent;
          border-bottom: 2px solid $primaryColor;
        }

        &.contact-message-error {
          height: auto;
          padding: 10px 4px 70px 4px !important;

          &:focus {
            background-color: rgba(255, 0, 0, 0.15);
          }
        }
      }
    }
  }
}

.connect-submit-btn {
  margin: auto;
  padding: 6px 16px !important;
  height: auto;
  background-color: $primaryColor  !important;
  color: $white  !important;

  &:hover {
    background-color: $darkRed  !important;
  }
}

.validation-error {
  font-weight: bold;
}

.submit-button .btn {
  margin: 1rem auto;
  width: 460px;
  padding: 22px 16px;
  font-size: 1.125rem;
}

@media (min-width: 1280px) {
  .contact-page-section main {
    max-width: 1280px;
  }
}

@media (min-width: 600px) {
  .contact-page-section main {
    padding-left: 24px;
    padding-right: 24px;
  }

  .premium-modal .contact-page-section main {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 1280px) {
  .contact-page-section main .contact-us-form .row .col {

    .campaign-input-error.contact-message-error,
    .contact-message-input {
      min-height: 50px !important;
      padding: 25px 5px !important;
    }
  }
}

