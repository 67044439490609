@mixin landingIcon {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &.creative {
    background-image: url($assetUrl + 'icons/ai-creative.svg');
  }
  &.manage-campaign {
    background-image: url($assetUrl + 'icons/campaign-management.svg');
  }
  &.analytics {
    background-image: url($assetUrl + 'icons/ai-insight.svg');
  }
}

.landing {
  .layout-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  font-family: 'Ubuntu', sans-serif;
  * {
    font-family: 'Ubuntu', sans-serif;
  }
  .logo {
    background: url($assetUrl + 'icons/blitz-smart-orange-logo.svg') center no-repeat;
    background-size: contain;
    width: 128px;
    height: 50px;
    &.white {
      background-image: url($assetUrl + 'icons/blitz-smart-logo-white.svg');
    }
    &.orange {
      background: $primaryColor;
      height: 60px;
      mask: url($assetUrl + 'icons/blitz-smart-logo-white.svg') no-repeat center;
    }

    &.orange-white {
      background-image: url($assetUrl + 'icons/blitz-smart-logo-orange-white.svg');
    }
  }

  .bg-content {
    background: url($assetUrl + 'landing-bg.svg') no-repeat top;
    background-size: cover;
    padding: 60px 0;
    .left, .right {
      position: relative;
    }
    .left {
      max-width: 600px;
    }
    .right {
      &.center-video {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
      }
      .video-with-bg {
        left: 30%;
      }
    }
    .caption {
      max-width: 555px;
    }
  }
  
  .caption {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $mediumGrey;
  }
  .caption-dark {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $h1GreyColor;
    strong {
    font-family: 'Poppins';
    font-weight: 700;
    }
  }
  .highlight {
    color: $primaryColor;
  }
  .unhighlight {
    color: $h1GreyColor;
  }

  .h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 76px;
    color: $h1GreyColor;
    &.white {
      color: $white;
    }
  }
  .h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;
    color: $h1GreyColor;
    &.white {
      color: $white;
    }
  }
  .h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: $primaryColor;
    &.white {
      color: $white;
    }
  }
  .h3-white {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: $white;
  }
  .thin-primary-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: $primaryColor;
  }
  .thin-primary-text-alt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $primaryColor;
    &.dark {
      color: $dirtyBlack;
    }
  }
  .poppins-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: $h1GreyColor;
    strong {
      font-family: 'Poppins';
      font-weight: 600;
    };
  }

  .brand {
    width: 200px;
    height: 50px;
    &.joyride {
      background-image: url($assetUrl + 'brands/joyride.svg');
    }
    &.gopuff {
      background-image: url($assetUrl + 'brands/gopuff.png');
    }
    &.buzzer {
      background-image: url($assetUrl + 'brands/buzzer.png');
    }
    &.pixelcut {
      background-image: url($assetUrl + 'brands/pixelcut.png');
    }
    &.atoms {
      background-image: url($assetUrl + 'brands/atoms.png');
    }
    &.real {
      background-image: url($assetUrl + 'brands/real.png');
    }
    &.cheetay {
      background-image: url($assetUrl + 'brands/cheetay.png');
    }
    &.talkspace {
      background-image: url($assetUrl + 'brands/talkspace.png');
    }
    &.elsa {
      background-image: url($assetUrl + 'brands/elsa.png');
    }
    &.mercari {
      background-image: url($assetUrl + 'brands/mercari.png');
    }
    &.matchday {
      background-image: url($assetUrl + 'brands/matchday.png');
    }
    &.sparkcamera {
      background-image: url($assetUrl + 'brands/sparkcamera.png');
    }
    &.dispo {
      background-image: url($assetUrl + 'brands/dispo.svg');
    }
    &.convo {
      background-image: url($assetUrl + 'brands/convo.png');
    }
    &.onward {
      background-image: url($assetUrl + 'brands/onward.png');
    }
    &.spadeco {
      background-image: url($assetUrl + 'brands/spadeco.png');
    }
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .brands {
    padding: 40px 0;
    .title {
      text-align: center;
    }
    ul.brands-list {
      margin: 30px auto;
      // display: grid;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // grid-template-columns: auto auto auto auto auto auto auto auto;
      gap: 50px;
      li {
        height: 70px;
        list-style: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &.brand {
          width: 125px;
        }
      }
    }
  }

  .quote {
    width: 32px;
    height: 27px;
    background: url($assetUrl + 'icons/quote.svg') center no-repeat;
  }

  .testimonials-container {
    background: $dirtyWhite;
    padding: 40px 0 0 0;
    .testimonials {
      display: flex;
      flex-direction: column;
      align-items: center;
      .h2 {
        margin-top: 0;
      }
    }
  }

  .carousel-container {
    position: relative;
  }
  .carousel-cards {
    display: flex;
    gap: 80px;
    padding: 45px 80px 60px;
    overflow: hidden;
    width: calc(100vw - 15px);
    .card {
      display: flex;
      font-size: 18px;
      font-weight: 400;
      min-width: 800px;
      line-height: 32px;
      padding: 35px 45px;
      background: $white;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      color: $carouselTextGrey;
      border: 1px solid rgba(183, 192, 204, 0.5);
      box-shadow: 0px 0px 40px rgb(0, 0, 0, 0.1);
      .content {
        padding: 30px 0;
      }
    }
    .controls {
      display: none;
      position: absolute;
      top: 50%;
      // background: url($assetUrl + 'icons/round-arrow-white.svg') no-repeat center;
      background: url($assetUrl + 'arrow-icon.svg') no-repeat center;
      background-color: transparent;
      box-shadow: none;
      background-size: contain;
      width: 46px;
      height: 46px;
      border-radius: 24px;
      // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      &:active {
        // box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      }
      &.next {
        right: 20px;
        top: calc(50% - 5px);
        transform: rotate(180deg);
      }
      &.previous {
        left: 20px;
        // box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.2);
        &:active {
          // box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        }
      }
    }
    &:hover {
      .controls {
        display: block;
      }
    }
  }

  .capabilities-container {
    background: $topFooterBg;
    padding: 60px 0;
    .capabilities {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .product-section-container {
    margin: 40px 40px 0 40px;
  }

  .case-study-container {
    background: $white;
    padding: 60px 0 80px 0;
    .case-study  {
      display: flex;
      align-items: center;
      .video-pane, .content-pane {
        flex: 1;
      }
      .video-pane {
        display: flex;
        justify-content: center;
      }
    }
  }

  .product-page {
    .section-container {
      margin: 80px auto;
    }
    .video-box.hero-video {
      width: 450px;
      height: 245px;
      position: relative;
      .video-with-bg {
        width: 100%;
      }
    }
  }

  .section-container {
    margin: 40px auto;
    .section {
      display: flex;
      padding: 60px 50px;
      gap: 200px;
      justify-content: space-between;
      .left-pane {
      }
      .right-pane {
        display: flex;
      }
      .section-hero-image {
        width: 210px;
        height: 210px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 20px 0px 50px rgba(121, 121, 121, 0.2), -40px 100px 70px rgba(121, 121, 121, 0.05);
        .hero-image {
          @include landingIcon;
          width: 115px;
          height: 115px;
        }
      }
      &.reverse {
        background: $sectionGrey;
        .left-pane {
          order: 2;
        }
        .right-pane {
          order: 1;
        }
      }
    }
  }

  .alt-section-container {
    margin: 60px auto;
    .layout-container {
      width: 1200px;
    }
    .alt-section {
      display: flex;
      // padding: 60px 50px;
      gap: 100px;
      justify-content: space-between;
      align-items: center;
      .left-pane {
        .left-pane-bg {
          background: $sectionGrey;
          padding: 30px;
        }
      }
      .right-pane {
        display: flex;
      }
      .section-hero-image {
        width: 210px;
        height: 210px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 20px 0px 50px rgba(121, 121, 121, 0.2), -40px 100px 70px rgba(121, 121, 121, 0.05);
        .hero-image {
          @include landingIcon;
          width: 115px;
          height: 115px;
        }
      }
      &.reverse {
        .left-pane {
          order: 2;
        }
        .right-pane {
          order: 1;
        }
      }
    }
  }

  .team-section-container {
    margin: 90px auto;
    .team-section {
      display: flex;
      gap: 90px;
      .left-pane {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: center;
        .team-india {
          width: 500px;
        }
        .team-us {
          margin-left: 200px;
          width: 500px;
        }
      }
      .right-pane {
        flex: 1;
      }
    }
  }
  .orange-section-container {
    background: $primaryColor;
    .orange-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 80px 0;
      .white-cards {
        margin-top: 70px;
        .card {
          height: auto;
        }
      }
      .head {
        font-family: 'Ubuntu';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 46px;
        letter-spacing: -2px;
        &.white {
          color: $white;
        }
        &.orange {
          color: $primaryColor;
        }
      }
    }
  }

  .team-members-container {
    position: relative;
    padding: 120px 0 60px 0;
    .teams-ring {
      z-index: 1;
      border: 2px dashed $lighterOrange;
      border-radius: 700px;
      left: 50%;
      opacity: 0.2;
      &.one {
        position: absolute;
        top: 60px;
        width: 1400px;
        height: 1400px;
        margin-left: -700px;
      }
      &.two {
        position: absolute;
        top: 185px;
        width: 1150px;
        height: 1150px;
        margin-left: -575px;
      }
    }
    .team-members {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      .members {
        display: grid;
        grid-template-columns: 218px 218px 218px 218px 218px;
        gap: 20px;
        margin-top: 50px;
        .member {
          width: 218px;
          height: 260px;
          position: relative;
          .name {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50px;
            color: $white;
            font-weight: 700;
            font-size: 16px;
            line-height: 50px;
            text-align: center;
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(20px);
          }
          &.deeksha {
            background-image: url($assetUrl + 'team/deeksha.jpg');
          }
          &.anudeep {
            background-image: url($assetUrl + 'team/anudeep.jpg');
          }
          &.shreyas {
            background-image: url($assetUrl + 'team/shreyas.jpg');
          }
          &.sriharsha {
            background-image: url($assetUrl + 'team/sriharsha.jpg');
          }
          &.mohit-sardana {
            background-image: url($assetUrl + 'team/mohit-sardana.jpg');
          }
          &.satya {
            background-image: url($assetUrl + 'team/satya.jpg');
          }
          &.ishan {
            background-image: url($assetUrl + 'team/ishan.jpg');
          }
          &.smarak {
            background-image: url($assetUrl + 'team/smarak.jpg');
          }
          &.abhinav {
            background-image: url($assetUrl + 'team/abhinav.jpg');
          }
          &.eshwar {
            background-image: url($assetUrl + 'team/eshwar.png');
          }
          &.chetan {
            background-image: url($assetUrl + 'team/chetan.jpg');
          }
          &.harshan {
            background-image: url($assetUrl + 'team/harshan.jpg');
          }
          &.anjali {
            background-image: url($assetUrl + 'team/anjali.jpg');
          }
          &.priyanka {
            background-image: url($assetUrl + 'team/priyanka.jpg');
          }
          &.girish {
            background-image: url($assetUrl + 'team/girish.jpg');
          }
          &.mayank {
            background-image: url($assetUrl + 'team/mayank.jpg');
          }
          &.varun {
            background-image: url($assetUrl + 'team/varun.jpg');
          }
          &.pooja {
            background-image: url($assetUrl + 'team/pooja.jpg');
          }
          &.parth {
            background-image: url($assetUrl + 'team/parth.jpg');
          }
          &.arturo {
            background-image: url($assetUrl + 'team/arturo.jpg');
          }
          &.mohit-wadhwani {
            background-image: url($assetUrl + 'team/mohit-wadhwani.jpg');
          }
          &.cordelia {
            background-image: url($assetUrl + 'team/cordelia.jpg');
          }
          &.kunal {
            background-image: url($assetUrl + 'team/kunal.jpg');
          }
          &.praveen {
            background-image: url($assetUrl + 'team/praveen.jpg');
          }
          &.okan {
            background-image: url($assetUrl + 'team/okan.jpeg');
          }
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .subscription-plans {
    padding-bottom: 60px;
    .head {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .video-controls {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: +1;
      width: 200px;
      height: 200px;
      background: url($assetUrl + 'icons/white-play.svg') no-repeat center;
      margin: -100px 0 0 -100px;
      background-size: cover;
      cursor: pointer;
    }
  }
}

ul.white-cards {
  display: grid;
  grid-template-columns: 370px 370px 370px;

  gap: 50px;
  .card {
    background: $white;
    list-style: none;
    padding: 20px;
    height: 320px;
    box-shadow: 0px 35px 30px rgba(92, 7, 7, 0.1);
    .icon {
      @include landingIcon;
    }
    .head {
      color: $h1GreyColor;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      margin: 25px 0 15px 0;
    }
    .details {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $detailsGrey;
    }
  }
}

.ornament-holder {
  width: fit-content;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  position: relative;
  .ornament-for {
    position: relative;
    z-index: 2;
  }
  .ornament {
    background: url($assetUrl + 'dot-grid.svg') center no-repeat;
    &.white {
      background: url($assetUrl + 'dot-grid-white.svg') center no-repeat;
    }
    background-size: contain;
    width: 116px;
    height: 116px;
    position: absolute;
    z-index: 1;
    &.top-left {
      top: -40px;
      left: -40px;
    }
    &.top-right {
      top: -40px;
      right: -40px;
    }
    &.bottom-left {
      bottom: -40px;
      left: -40px;
    }
    &.bottom-right {
      bottom: -40px;
      right: -40px;
    }
  }
}

.landing-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  .layout-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
  }
  ul.header-links {
    display: flex;
    gap: 60px;
    margin: 0;
    line-height: 50px;
    list-style: none;
    align-items: center;
    // justify-content: center;
    li {
      position: relative;
      &.active {
        a {
          color: $topLinkColor;
        }
        &::after {
          content: '';
          width: 33px;
          height: 5px;
          background: url($assetUrl + 'icons/top-link-active.svg') center no-repeat;
          position: absolute;
          left: 50%;
          top: 43px;
          margin-left: -17px;
        }
      }
      a {
        font-family: 'Ubuntu', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        color: $linkGrey;
        text-decoration: none;
      }
      .dropdown {
        button.dropdown-button {
          background: transparent;
          color: $linkGrey;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
        }
        .dropdown-content {
          top: 50px;
          width: 160px;
          border-radius: 10px;
          border: 1px solid $landingFormBorderColor;
          box-shadow: none;
          padding: 5px 20px;
          a {
            display: block;
            line-height: 40px;
          }
        }
      }
    }
  }
}

.after-header-container {
  background: url($assetUrl + 'landing-bg-small.svg') top center no-repeat;
  background-size: cover;
  .after-header {
    height: 168px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-pane {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
    }
    .content-pane {

    }
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: $primaryColor;
  }
  .description {
    margin-top: 10px;
    font-weight: 700;
    font-size: 40px;
    line-height: 46px;
    &.small {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: $h1GreyColor;
    }
  }
}

.landing-footer {
  .top {
    background: $topFooterBg;
    color: $white;
    position: relative;
    overflow: hidden;
    .layout-container {
      height: 225px;
      align-items: center;
      justify-content: space-between;
    }
    .title {
      max-width: 730px;
      font-size: 60px;
      font-weight: 700;
      line-height: 76px;
      letter-spacing: 0.02em;
    }
    button {
      margin-right: 135px;
      z-index: +1;
    }
    .circle {
      box-sizing: border-box;
      position: absolute;
      width: 700px;
      height: 700px;
      border-radius: 350px;
      opacity: 0.1;
      border: 7px solid $circleColor;
      &.one {
        left: 56%;
        top: -130px;
      }
      &.two {
        left: 75%;
        top: -280px;
      }
    }
  }
  .bottom {
    // background: $primaryColor;
    color: $white;
    .footer-separator {
      justify-content: space-between;
      padding: 35px 0 35px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      .left, .right {
        width: auto;
      }

      .copy-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
      }
      
      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
      
    }
  }
  ul.footer-links {
    display: flex;
    gap: 45px;
    li {
      list-style: none;
      a {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: $greyBorder;
        text-decoration: none;
      }
    }
  }

  .input-cta {
    display: flex;
    align-items: center;
    width: 410px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid $white;
    justify-content: space-between;
    input[type="text"] {
      background: 0;
      border: 0;
      color: $white;
      padding: 0 20px;
      width: 350px;
      line-height: 58px;
      &:focus {
        outline: none;
        border-radius: 30;
      }
      &::placeholder {
        color: $white;
      }
    }
    button.cta {
      background: url($assetUrl + 'icons/round-arrow-white.svg') no-repeat center;
      background-color: transparent;
      box-shadow: none;
      background-size: contain;
      width: 46px;
      height: 46px;
      margin-right: 8px;
    }
  }
  .text {
    line-height: 24px;
  }
}

.landing-modal {
  max-width: 600px;
}
.hamburger {
  display: none;
}

.blog-iframe {
  width: 100%;
  // height: 100vh;
}

.social-icons-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;
  
  .social-icon {
    object-fit: contain;
    width: 60px;
  }
}