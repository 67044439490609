.form-control {
  .MuiChip-root {
    height: 26px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.link-accounts-select {
  
  .dropdown-content {
    min-width: unset !important;
  }

  ul.select-list {
    max-height: 200px;
    // overflow: auto;
  }

  .dropdown-content {
    overflow: auto !important;
    max-height: 15vh;
  }
}

.link-accounts-scroll {
  .dropdown-content {
    max-height: 120px;
    overflow: auto;
  }
}

.title-banner.account {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 45px;
}

.selected-accounts {
  margin-top: 10px;
  opacity: 0.5;
}

.selected-accounts.active {
  opacity: 1;
}

.wb-break-all {
  word-break: break-all;
}

//overrides
.inner-page {
  .branding-assets {
    padding: 0px 40px;
    min-height: fit-content;

    .image-container {
      display: flex;
      margin-top: 2rem;

      .image-upload-preview-cont {
        margin-right: 1rem;

        .image-upload-preview {
          width: 92px;
          cursor: pointer;
          height: 92px;
          overflow: hidden;
          position: relative;
          border-radius: 6px;
          background-size: contain;
          background-color: #eee;
          background-repeat: no-repeat;
          background-position: center center;

          .image-upload-preview-hover {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: 40px;
            background-color: rgba(239, 89, 71, 0.9);
            background-image: url(https://media.useblitz.com/icon/ToolSvg/UploadWhite.svg);
            background-repeat: no-repeat;
            background-position: center center;
            &:hover {
              background-color: $darkRed;
              transition: all ease-in 0.2s;
            }
          }
        }
      }
    }

    .brand-colors .color-picker {
      display: flex;

      .color-picker-input {
        position: relative;

        &::before {
          left: 0;
          right: 0;
          bottom: 0;
          content: "\00a0";
          position: absolute;
          transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          border-bottom: 1px solid rgba(0, 0, 0, 0.42);

        }

        input {
          border: 2px solid transparent;
          &:hover {
            border-bottom: 2px solid $black;
          }
        }
      }
      .btn {
        margin-left: 12px;
        font-size: 1.125rem;
        padding: 1.7rem 1rem;
      }
    }
  }
}

//overrides
.brand-colors {
  .color-deletable {
    .color-pallete {
      height: 92px;
      width: 92px;
      border-radius: 6px;
    }
  }
}

.coming-soon {
  margin: 20px;
}

.link-acc-btn {
  border-radius: 2px;
  width: 215px;
  height: 40px; 
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .social-img-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 18px;
      height: 18px;
      border-radius: 2px;
      margin-right: 0px !important;
    }
  }

  .btn-text-container {
    flex: 4;
    display: flex;
    align-items: center;

    .btn-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
