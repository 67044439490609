@mixin cellBorder {
  border-right: 1px solid $tableBorderColor;
}
@mixin noBorder {
  border: none;
}

/**
 * Data grid CSS
 */

.data-grid-container {
  display: flex !important;
}

.data-grid-container, .data-grid-head-container, .data-grid-footer-container {
  display: grid;
  overflow: hidden;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  position: relative;
  height: calc(100vh - 349px);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  
  .data-grid {
    display: flex;
    flex: 1;
    flex-direction: column;
    .data-grid-cell {
      font-size: 14px;
      display: table-cell;
      padding: 0 5px;
      min-height: 50px;
      line-height: normal;
      white-space: normal;
      word-break: break-word;
      vertical-align: middle;
      @include cellBorder;
      &:last-child {
        @include cellBorder;
      }

      &.fixed-column {
        position: sticky;
        left: 0;
      }

      &.checked {
        background: $lightOrange !important;
        a.link {
          font-weight: bold;
        }
      }
      .show-hover-items {
        position: absolute;
        visibility: hidden;
        height: 16px;
      }
      &:hover .show-hover-items {
        visibility: visible;
      }
      .shadow-bg {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==');
        background-repeat: repeat-y;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 5px;
        top: 0;
      }
      // &[data-column="name"] {
      //   &::after {
      //     content: '';
      //     position: absolute;
      //     width: 5px;
      //     right: 5px;
      //     height: 100%;
      //     background: 0 0 url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==') repeat-y;
      //   }
      // }
    }
    
    // data grid head
    .data-grid-head {
      // position: sticky;
      // top: 0;
      // z-index: 1;
      // position: sticky;

      .data-grid-row {
        width: auto !important;
        border-bottom: 1px solid $tableBorderColor;
        .data-grid-cell {
          &[data-column="id"] {
            text-align: center;
          }
          font-size: 14px;
          padding: 5px;
          background: $tableRowColorOdd;
          font-weight: 600;
          @include cellBorder;
          position: relative;
          &.fixed-column {
            position: sticky;
            z-index: +1;
          }
          &:last-child {
            @include cellBorder();
          }
          .resizer {
            display: none;
            position: absolute;
            border-right: 5px #aaaaaa double;
            height: 100vh;
            right: 0px;
            top: 0;
            z-index: +1;
          }
          &:hover {
            .resizer {
              display: block;
              cursor: ew-resize;
            }
          }
        }
      }
    }

    // data grid body
    .data-grid-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      .data-grid-row {
        width: auto !important;
        &:hover {
          background: $tableRowColorEven;
        }
        &.row-even {
          .data-grid-cell {
            // background: $tableRowColorEven;
            background: $white;
          }
        }
        &.row-odd {
          .data-grid-cell {
            // background: $tableRowColorOdd;
            background: $white;
          }
        }
        .data-grid-cell {
          @include noBorder;
          @include cellBorder;
          height: 50px;
          color: $dataGridColor;
          .grid-light-text {
            color: $dataGridLightColor;
          }
        }
        .data-grid-cell:last-child {
          @include cellBorder;
        }
      }
      .data-grid-row:last-child {
        border-bottom: 1px solid $tableBorderColor;
      }
      .MuiCircularProgress-root {
        width: 40px !important;
      }
    }
    .sort-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &.active {
        color: $primaryColor;
      }
    }
  }
  /** anchors inside table grid **/
  a.link {
    color: $black;
    cursor: pointer;
    // font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}

.abs-skeleton-loader {
  position: absolute;
  top: 0px;
  &.top {
    top: 0px;
  }
  &.bottom {
    top: unset;
    bottom: 0px;
    // border-top: 1px solid $tableBorderColor;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1)
  }
}

.data-grid-body div:first-child {
  // width: 100% !important;
  .List {
    width: 100% !important;
  }
}

#ads-data-container {
  .data-grid-container {
    .data-grid-body {
      .data-grid-row {
        .data-grid-cell {
          height: 46px;
        }
      }
    }
  }
}

table.table-grid {
  border-collapse: collapse;
  @include noBorder;

  width: 100%;
  overflow: unset !important;
  position: relative;
  font-size: 15px;

  thead {
    tr, th {
      border-collapse: collapse !important;
    }
    tr {
      border-bottom: 2px solid $tableBorderColor;
      th {
        font-weight: 600;
        padding: 16px 32px;
        background: $white;
        // position: sticky;
        // top: 3.8rem;
        z-index: 1;
        white-space: nowrap;
        @include cellBorder;
      }
      th:first-child {
        padding-left: 3rem;
      }
    }
  }
  
  tbody {
    tr {
      &.row-even {
        background: $tableRowColorEven;
      }
      &.row-odd {
        background: $tableRowColorOdd;
      }
      td {
        @include noBorder;
        @include cellBorder;
        padding: 20px;
        line-height: 20px;
        vertical-align: middle;
        a.link {
          color: $black;
          font-weight: normal;
        }
      }
      td:first-child {
        padding-left: 3rem;
      }
      td:last-child {
        padding-right: 3rem;
      }
    }
  }
  /** anchors inside table grid **/
  a.link {
    color: $black;
    font-weight: bold;
  }
}

.data-grid-head-container {
  border-top: 1px solid $tableBorderColor;
}

.data-grid-head-container, .data-grid-footer-container {
  height: auto !important;
  overflow: hidden;
}

.data-grid-footer-container {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  // position: fixed;
  // bottom: 0;
  width: 100%;
  .data-grid-row, .data-grid-cell {
    height: 46px;
    span {
      font-size: 16px;
      font-weight: normal;
    }
  }
}

#projects-container{
  .data-grid {
    .data-grid-body {
      .data-grid-row {
        .data-grid-cell {    
          height: 46px !important;
        }
      }
    }
  }
}


//overrides for left alignment of headings
.data-grid-head-container {
  .data-grid-head .data-grid-row {
    .data-grid-cell .sort-label {
      text-align: left !important;
    }
    .data-grid-cell {
      text-align: left !important;
    }
  }
}

.data-grid-footer-container {
  .data-grid-head .data-grid-row {
    .data-grid-cell {
      div > span {
        font-weight: 600 !important;
      }
    }
  }
}

.campaign-alerts-msg {
  margin-top: 40px;
}

