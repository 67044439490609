.blog {
  margin: 30px auto;
  // width: 1170px;
  .blog-hero-image {
    width: 1080px;
    // width: 100%;
    margin: auto;
    img {
      width: 100%;
      border-radius: 25px;
    }
  }

  i {
    font-style: italic;
  }

  ul {
    li {
      margin-top: 10px;
    }
  }
  .sub-head {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: $primaryColor;
    margin-bottom: 0.5rem;
  }
  a {
    color: $primaryColor !important;
  }
  .back-link {
    display: block;
    margin-bottom: 15px;
    color: $primaryColor; 
    &:visited {
      color: $primaryColor;
    }
  }
  .blog-content {
    width: 840px;
    margin: 40px auto;
    font-family: Poppins;
    color: $graphite;
    * {
      font-family: Poppins;
      color: $graphite;
    }
  }

  .posts {
    display: flex;
    width: 1170px;
    margin: auto;
    gap: 30px;
    flex-wrap: wrap;
    .post {
      cursor: pointer;
      width: 370px;
      .post-image {
        width: 100%;
        height: 370px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 25px;
        margin-bottom : 30px;
      }
      .sub-head {
        margin: 12px auto;
      }
      .title {
        margin: 20px auto;
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }
      .blog-details {
        margin: 10px auto;
      }
    }
  }
}
.blog-details {
  .author {
    color: $mediumGrey;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 10px;
    .author-image {
      width: 40px;
      height: 40px;
      min-height: 40px;
      min-width: 40px;
      border-radius: 20px;
      background-size: cover;
    }
  }
}

.landing-v2 {
  .blog-post {
    h2 {
      font-size: 46px;
      line-height: 1.1em;
      font-weight: 600;
      margin-bottom: 2rem;
    }
    h3 {
      font-size: 30px;
      line-height: 1.1em;
      font-weight: 600;
      margin: 30px auto;
    }
  
    h4 {
      font-size: 25px;
      line-height: 1.1em;
      font-weight: 600;
      margin: 10px auto;
    }
  
    h5 {
      font-size: 20px;
      font-weight: 600;
      margin: 10px auto;
    }
  
    p {
      font-size: 15px;
      margin-bottom: 30px;
      text-align: justify;
    }
  }
}

.blog-navigation {
  margin-top: 40px;
  display: flex;
  gap: 30px;
  .icon {
    width: 12px;
    height: 23px;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    &.previous {
      // background-image: url($assetUrl + 'icons/grey-left-arrow.svg');
      background-image: url($assetUrl + 'icons/black-right-arrow.svg');
      transform: rotate(180deg);
    }
    &.next {
      background-image: url($assetUrl + 'icons/black-right-arrow.svg');
    }
  }
}

@media only screen and (max-width: 900px) {
  .blog {
    width: auto;
    .blog-hero-image {
      text-align: center;
      width: calc(100% - 20px);
      img {
        width: calc(100% - 20px);
      }
    }
    .blog-content {
      width: calc(100% - 20px);
    }
    .posts {
      flex-direction: column;
      align-items: center;
    }

    iframe {
      width: calc(100% - 20px);
    }
  }
}

