.custom-checkbox-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .custom-checkbox {
    border: 0;
    // border-radius: 6px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // display: inline-block;
    // vertical-align: middle;
    &.grey {
      border-color: $darkGrey;
    }
    .checked {
      width: 22px;
      height: 22px;
      &.hide {
        background: url($assetUrl + 'icons/checkbox-round.svg');
        display: block;
      }
      // width: 16px;
      // height: 15px;
      // border-radius: 2px;
      // background-repeat: no-repeat;
      // background: url($assetUrl + 'tick.svg');
      &.show {
        background: url($assetUrl + 'icons/checkbox-round-checked.svg');
      }
    }
    &.disabled {
      opacity: 0.3 !important;
      filter: unset !important;
      // filter: grayscale(1) brightness(1.6);
    }
  }

  .label {
    font-size: 16px;
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
  }
}
