.horizontal-slider {
  height: 10px;
  border-radius: 5px;
  .track {
    height: 10px;
    cursor: pointer;
    border-radius: 5px;
    &.track-0, &.track-2 {
      background: rgb(221, 221, 221);
    }
    &.track-1 {
      background: $primaryColor;
    }
  }
  .thumb {
    font-size: 12px;
    background: $black;
    color: $white;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    margin: auto;
    // margin-left: -15px;
    outline: none;
    text-align: center;
    top: -10px;
    cursor: pointer;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    &:active {
      box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
    }
  }
  .mark {
    width: 4px;
    height: 4px;
    background: #e9adad;
    top: 3px;
    margin-left: 15px;
    border-radius: 3px;
    cursor: pointer;
  }
}
