.rounded-main-container, .market-list-container {
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 12%);
  margin-top: 21px;
  border-radius: 20px;
  margin-bottom: 30px;
  background-color: $white;
  display: flex;

  .logo {
    width: 16%;
  }

  .details {
    width: 84%;
    h4 {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      margin-right: 1rem;
    }
    .typography {
      color: $darkGrey;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.75;
    }
    .market-listing {
      display: flex;
      margin-right: 20px;
      padding-bottom: 10px;
      justify-content: space-between;
      border-bottom: 2px solid $iceBlue;
    }
    .half-width {
      width: 50%;
    }
    .quarter-width {
      width: 25%;
    }
  }
  .sample-item {
    display: inline-flex;
    gap: 15px;
    width: 114px;
    height: 114px;
    display: flex;
    overflow: hidden;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    background-color: #f4f4f4;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

}
.market-list-container {
  box-shadow: none;
  .details {
    .typography {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .logo img {
    margin: 0px 1rem !important;
  }
}

//overrides
.has-tooltip button {
  font-size: 13px;
}
.rounded-main-container, .market-list-container {
  .logo img {
    width: 124px;
    height: 124px;
    border-radius: 20px;
    justify-content: center;
    background-color: #f4f4f4;
    margin: 1rem 0px;
  }
}

.creator-tooltip {
  .tooltip {
    .tooltip-title {
      white-space: normal;
      max-width: 200px;
      width: 200px;
      height: auto;
      line-height: 1.35;
      padding: 5px 7px;
      &::after {
        top: 10px;
      }
    }
  }
}
