.introjs-tooltip-header {
  display: none;
}
.intro-fue {
  color: #ffffff;
  padding: 0 !important;
  min-width: 220px !important;
  border-radius: 5px !important;
  background-color: $primaryColor !important;
  .introjs-arrow {
    border-right-color: $primaryColor !important;
  }
  .introjs-tooltipbuttons {
    border: solid 0px $black;
    text-align: left;
    border-radius: 0 0 5px 5px;
    background-color: $darkRed;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .introjs-tooltiptext {
    padding: 0;
    .close-icon {
      top: 0;
      right: 0;
      cursor: pointer;
      padding: 15px;
      position: absolute;
    }
    .title {
      width: 160px;
      margin: 14px;
      font-size: 18px;
      font-weight: bold;
    }
    .sub-title {
      margin: 14px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.17;
      margin-bottom: 18px;
    }

    .rectangle-parent {
      display: inline-block;
      height: 20px;
      .rectangle {
        width: 4px;
        height: 4px;
        margin: 1px 3px;
        display: inline-block;
        border-radius: 1px;
        background-color: $white;
      }
      .rectangle-selected {
        width: 6px;
        height: 6px;
        display: inline-block;
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 1px;
        background-color: $white;
      }
    }
    .introjs-donebutton {
      position: static;
      color: $white;
      border: none;
      margin: 0;
      font-size: 12px;
      background: none;
      font-family: Libre Franklin;
      font-weight: 500;
      text-shadow: none;
      border-radius: 0;
      width: auto;
      height: auto;
      line-height: normal;
    }
  }
}