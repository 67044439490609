.creatives,
.assets {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  .creative,
  .asset {
    .creative__image,
    .asset__image {
      background: #e9f0f3;
      border: solid 1px #acacac;
      background-size: contain;
      background-position: center center;
      height: 144px;
      background-repeat: no-repeat;
      border-radius: 6px;
      cursor: pointer;
    }
    .creative__video-container,
    .asset__video-container {
      cursor: pointer;
      .creative__video,
      .asset__video {
        border: solid 1px #acacac;
        border-radius: 6px;
        height: 144px;
        width: 144px;
      }
    }
    .creative__name,
    .asset__name {
      overflow: hidden;
      display: block;
      margin-top: 1rem;
      word-break: break-all;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.4;
    }
  }
}
