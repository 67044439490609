.lite-campaigns {
  .form-control {
    // padding: 5px;
    
    label {
      margin-bottom: 15px;
    }
    .form-input {
      height: 46px;
      line-height: 46px;
      font-size: 100%;
      font-weight: normal;
    }

    &.rpos {
      background-color: rgba(181, 203, 215, 0.3);
      position: relative;
  
      .dollar {
        position: absolute;
        top: 15px;
        left: 10px;
        z-index: +1;
      }

      input {
        text-indent: 15px;
      }
    }

    .error-msg {
      background-color: white;
      padding-top: 10px;
      color: #EF3842;
      font-size: 14px;
    }

    .info-msg {
      background-color: #e9f0f3;
      padding: 15px;
      line-height: 20px;
      font-size: 14px;
      border-radius: 4px;
    }
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
}
.root-class {
  .form-root.lite-campaigns {
    .form-control .custom-select .dropdown-button {
      border: 0;
      padding: 14px;
      font-weight: 400;
    }
  }
}

.floated-navigation {
  .previous {
    position: fixed;
    top: 50%;
    left: 10%;
  }
  .next {
    position: fixed;
    top: 50%;
    right: 10%;
  }
}
